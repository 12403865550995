import {EntrySummary} from "common/interfaces/entrySummary";
import ImportalCard from "@/shared-components/ImportalCard";
import {
    CardContent,
    CircularProgress,
    Divider,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useAPI} from "@/api/APIContext";

interface Props {
    entrySummary: EntrySummary,
    onEntrySummaryUpdated: (arg0: EntrySummary) => void
}


enum ViewMode {
    REGULAR = "Regular",
    JSON = "JSON",
    PDF = "PDF"
}
export default function EntrySummaryCard({entrySummary, onEntrySummaryUpdated}: Props) {
    const [viewingMode, setViewingMode] = useState(ViewMode.REGULAR);

    const handleChange = (event: React.MouseEvent<HTMLElement>, newViewingMode: ViewMode) => {
        setViewingMode(newViewingMode);
    };

    return <>
        <ImportalCard
            title={entrySummary.entryNumber}
                      topRightActionButton={
                          <ToggleButtonGroup
                              color="primary"
                              value={viewingMode}
                              exclusive
                              onChange={handleChange}
                              aria-label="Platform"
                          >
                              <ToggleButton sx={{textTransform: 'none', color: '#525256', width: '80px'}}
                                            value={ViewMode.REGULAR}>Regular</ToggleButton>

                              <ToggleButton sx={{textTransform: 'none', color: '#525256', width: '80px'}}
                                            value={ViewMode.JSON}>JSON</ToggleButton>

                              <ToggleButton sx={{textTransform: 'none', color: '#525256', width: '80px'}}
                                            value={ViewMode.PDF}>PDF</ToggleButton>
                          </ToggleButtonGroup>
                      }>


            { viewingMode === ViewMode.REGULAR &&
                <RegularViewMode entrySummary={entrySummary}/>
            }

            { viewingMode === ViewMode.JSON &&
                <JSONViewMode entrySummary={entrySummary} onEntrySummaryChanged={(entrySummary) => {

                }}/>
            }

            { viewingMode === ViewMode.PDF &&
                <PDFViewMode entrySummary={entrySummary}/>
            }

        </ImportalCard>
    </>
}


function RegularViewMode({entrySummary}: {entrySummary: EntrySummary}){

    return <>
        <CardContent>

            <h3>Totals</h3>
            <Divider/>
            <div style={{display: "grid", gridTemplateColumns: '1fr 1fr'}}>

                <div style={{gridColumn: '1'}}>
                    <h3>Detail</h3>
                    <div><strong>Total Entered Value:</strong> {entrySummary?.totals?.totalEnteredValue || "None"}</div>
                    <div><strong>Country Of Origin:</strong> {entrySummary?.countryOfOrigin || "?"}</div>
                </div>

                <div style={{gridColumn: '2'}}>
                    <h3>Fees</h3>
                    <div> <strong>Harbor Maintenance Fee:</strong> {entrySummary?.totals?.harborMaintenanceFee || "None"}</div>
                    <div> <strong>Merchandise Processing Fee:</strong> {entrySummary?.totals?.merchandiseProcessingFee || "None"}</div>
                    { entrySummary.totals?.antiDumpingFee &&
                        <div> <strong>Anti Dumping Fee:</strong> {entrySummary?.totals?.antiDumpingFee || "None"}</div>
                    }
                    { entrySummary.totals?.counterVailingFee &&
                        <div> <strong>Counter Vailing Fee:</strong> {entrySummary?.totals?.counterVailingFee || "None"}</div>
                    }
                    { entrySummary.totals?.cottonFee &&
                        <div> <strong>Cotton Fee:</strong> {entrySummary.totals?.cottonFee || "None"}</div>
                    }
                    <div> <strong>Total Fees:</strong> {entrySummary?.totals?.totalOtherFees || "None"}</div>
                </div>

                <div style={{gridColumn: '2'}}>
                    <h3>Duties</h3>
                    <div> <strong>Total Duties:</strong> {entrySummary?.totals?.totalDuties || "None"}</div>
                </div>

                <div style={{gridColumn: '2'}}>
                    <h3>Total Duties & Fees</h3>
                    <div> <strong>Total Duties & Fees:</strong> {entrySummary?.totals?.total || "None"}</div>
                </div>
            </div>

            <br/>
            <br/>
            <br/>

            <h3>Line Items</h3>
            <Divider/>
            {entrySummary.lineItems &&
                entrySummary.lineItems.map((lineItem, index) => {
                    return <>
                        <h3>{index + 1}</h3>

                        <div style={{display: 'flex', gap: '16px'}}>
                            <div><strong>HTSNo:</strong> {lineItem.htsno || "?"}</div>
                            <div><strong>Description:</strong> {lineItem.descriptionOfMerchandise || "?"}</div>
                        </div>

                        <div style={{display: 'grid', gap: '8px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}>

                            <div><strong>Entered Value:</strong> {lineItem.enteredValue || "?"}</div>
                            {lineItem.antiDumpingno &&
                                <div><strong>ADD:</strong> {lineItem.antiDumpingDuties || "?"}</div>}
                            {lineItem.counterVailingDuties &&
                                <div><strong>CVD:</strong> {lineItem.counterVailingDuties || "?"}</div>}
                            {lineItem.cottonFeeDuties &&
                                <div><strong>Cotton Fee:</strong> {lineItem.cottonFeeDuties || "?"}</div>}

                            <div><strong>MPF:</strong> {lineItem.merchandiseProcessingFee || "?"}</div>
                            <div><strong>HMF:</strong> {lineItem.harborMaintenanceFee || "?"}</div>



                            <div><strong>General Duty Rate:</strong> {lineItem.generalDutyHTSUSRate || "?"}</div>
                            <div><strong>General Duties:</strong> {lineItem.generalDuties || "?"}</div>

                        </div>

                        {(lineItem.section301DescriptionOfMerchandise || lineItem.section301Code || lineItem.section301DutyHTSUSRate || lineItem.section301Duties) &&
                            <>
                                <h4>{index + 1} Section 301 Info</h4>
                                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px'}}>
                                    <div> <strong>Section 301 Description:</strong> {lineItem.section301DescriptionOfMerchandise || "?"}</div>
                                    <div> <strong>Section 301 Code:</strong> {lineItem.section301Code || "?"}</div>
                                    <div> <strong>Section 301 Rate:</strong> {lineItem.section301DutyHTSUSRate || "?"}</div>
                                    <div> <strong>Section 301 Duties:</strong> {lineItem.section301Duties || "?"}</div>
                                </div>
                            </>
                        }
                    </>

                })
            }

        </CardContent>
    </>
}


interface JSONViewModeProps {
    entrySummary: EntrySummary,
    onEntrySummaryChanged: (EntrySummary) => void
}

function JSONViewMode({entrySummary, onEntrySummaryChanged}: JSONViewModeProps) {

    const api = useAPI();

    const [localEntrySummary, setLocalEntrySummary] = useState<EntrySummary>(entrySummary)
    const [jsonStringEntrySummary, setJSONStringEntrySummary] = useState<string>(JSON.stringify(entrySummary, null, 2))

    const [entrySummaryJSONParseError, setEntrySummaryJSONParseError] = useState<string>()
    const [saveEntrySummaryLoading, setSaveEntrySummaryLoading] = useState(false)

    const onClickSave = () => {
        setSaveEntrySummaryLoading(true)
        api.updateEntrySummary(entrySummary._id.toString(), localEntrySummary)
            .then(({data}) => {
                onEntrySummaryChanged(data)
            })
            .catch(err => {
                console.error('error updating entry summary')
                console.error(err)
            })
            .finally(() => {
                setSaveEntrySummaryLoading(false)
            })
    }

    return <>
        <h3>Entry Summary</h3>
        <TextField
            fullWidth
            multiline={true}
            rows={12}
            value={jsonStringEntrySummary}
            onChange={(event) => {
                setJSONStringEntrySummary(event.target.value)
                try {
                    setEntrySummaryJSONParseError(undefined)
                    const queryAsObject = JSON.parse(event.target.value)
                    if (queryAsObject) {
                        setLocalEntrySummary(queryAsObject);
                    }
                } catch (err) {
                    console.error('error parsing query string')
                    console.error(err)
                    setEntrySummaryJSONParseError('invalid json')
                }
            }}
        />
        {entrySummaryJSONParseError &&
            <ul style={{color: 'red'}}>
                <li>{entrySummaryJSONParseError}</li>
            </ul>
        }
        <br/>
        <div style={{display: "flex", justifyContent: "right"}}>
            <Button
                variant="contained"
                color="primary"
                disabled={!!entrySummaryJSONParseError}
                onClick={onClickSave}
            >
                {saveEntrySummaryLoading ? <CircularProgress color={'inherit'} size={18}/> : 'Save'}

            </Button>
        </div>
    </>
}


interface PDFViewModeProps {
    entrySummary: EntrySummary
}
function PDFViewMode({entrySummary}: PDFViewModeProps) {
    const api = useAPI();
    const [pdfSrc, setPdfSrc] = useState<string>();

    useEffect(() => {
        api.getDocumentData(entrySummary.document.toString()).then((response: any) => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            setPdfSrc(url);
        }).catch((err: any) => {
            console.error(err);
        });
    }, [entrySummary.document]);

    return (
        <iframe
            src={pdfSrc}
            // type="application/pdf"
            style={{width: "100%", height: "1000px"}}
            title="PDF View Mode"
        />
    );
}

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VesselSnackbar from '@/components/call-to-action-snackbar/VesselSnackbar';
import ManifestModal from '@/components/manifest-modal/ManifestModal';
import { planIsExhaustedForFeature } from '@/utils/activePlanUtils';
import { FeatureName } from 'common/interfaces/plan';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';

export interface NavItem {
  icon: React.JSX.Element;
  displayName: string;
  routerPath: string;
  element?: React.JSX.Element;
  children?: NavItem[];
  onClick?: () => void;
  betaFeature?: string;
  toolUsage?: boolean;
}

export type DashboardProps = {
  navItems: NavItem[];
  selectedNavItem?: string;
  handleOpenPlansModal?: () => void;
  open: boolean;
  isMenuOpen?: boolean;
  anchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;

  onNavItemSelected: (navItem: NavItem) => void;
  setShowSideMenu?: (val: boolean) => void;
  isBrokerApp?: boolean;
};

export default function NavMenu({
  navItems,
  handleOpenPlansModal,
  onNavItemSelected,
  selectedNavItem,
  isBrokerApp,
  isMenuOpen,
  open,
}: DashboardProps) {
  const [vesselSnackbarOpen, setVesselSnackbarOpen] = useState(true);
  const [manifestModalOpen, setManifestModalOpen] = useState(false);

  const activePlan = useActivePlan();
  const activeBusiness = useActiveBusiness();

  const navItemsWithChildren = navItems.reduce((acc, item) => {
    if (item.children) {
      acc[item.displayName] = false;
    }
    return acc;
  }, {});
  const [
    navItemsWithChildrenDropdownState,
    setNavItemsWithChildrenDropDownState,
  ] = useState(navItemsWithChildren);

  useEffect(() => {
    if (!activeBusiness.business) {
      setVesselSnackbarOpen(false);
    }
  }, [activeBusiness.business]);

  const handleManifestModalOpen = () => {
    setManifestModalOpen(true);
  };

  const handleManifestModalClose = () => {
    setManifestModalOpen(false);
  };

  const handleVesselSnackbarClose = () => {
    setVesselSnackbarOpen(false);
  };

  const renderVMCRSnackbar = () => {
    if (!isBrokerApp) {
      return (
        <div className='vessel-snackbar-container'>
          {vesselSnackbarOpen ? (
            <VesselSnackbar
              handleManifestModalOpen={handleManifestModalOpen}
              handleClose={handleVesselSnackbarClose}
            />
          ) : (
            <></>
          )}
          <ManifestModal
            open={manifestModalOpen}
            onClose={handleManifestModalClose}
          />
        </div>
      );
    }
  };

  type Callback = () => void;
  const getOnClick = (navItem: NavItem): Callback => {
    if (navItem.children) {
      return () => {
        const dropDownState =
          navItemsWithChildrenDropdownState[navItem.displayName];
        const copy = { ...navItemsWithChildrenDropdownState };
        copy[navItem.displayName] = !dropDownState;
        setNavItemsWithChildrenDropDownState(copy);
      };
    } else if (navItem.onClick && !navItem.element) {
      return navItem.onClick;
    } else if (navItem.toolUsage) {
      if (
        planIsExhaustedForFeature(FeatureName.SEARCHES, activePlan.metadata)
      ) {
        return () => {
          handleOpenPlansModal?.();
        };
      }
    }

    return () => {
      onNavItemSelected(navItem);
    };
  };

  const shouldShowChildren = (navItem: NavItem) => {
    return (
      navItem.children && navItemsWithChildrenDropdownState[navItem.displayName]
    );
  };

  const renderNavItem = (navItem: NavItem, depth: number) => {
    return (
      <React.Fragment key={navItem.displayName}>
        <div
          style={{ paddingLeft: 20 * depth + 'px' }}
          className={`customList ${
            selectedNavItem === navItem.displayName ? 'active' : ' '
          }`}
          key={navItem.displayName}
        >
          <Button
            variant='text'
            sx={{ textTransform: 'none', minWidth: '0' }}
            startIcon={navItem.icon}
            endIcon={
              navItem.children ? (
                navItemsWithChildrenDropdownState[navItem.displayName] ? (
                  <ArrowDropDownIcon />
                ) : (
                  <ArrowLeftIcon />
                )
              ) : null
            }
            onClick={getOnClick(navItem)}
          >
            {isMenuOpen ? (
              <div className='menu-button-text-container'>
                {navItem.displayName}
              </div>
            ) : (
              <></>
            )}
          </Button>
        </div>
        {shouldShowChildren(navItem)
          ? navItem.children!.map((child) => {
              return renderNavItem(child, depth + 1);
            })
          : null}
      </React.Fragment>
    );
  };

  return (
    <>
      <div className='profile-main-content'>
        <div
          className='menu-item-container'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {navItems.map((navItem) => renderNavItem(navItem, 0))}
        </div>
      </div>
      {renderVMCRSnackbar()}
    </>
  );
}

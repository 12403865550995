import {useNavigate} from "react-router-dom";
import React, {useState, useEffect} from "react";
import {useBetaFeatures} from "./BetaFeatureContext";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface BetaFeatureRouteProps {
    children: React.ReactNode,
    betaFeature: string,
    fallback: React.ReactElement
}

const BetaFeatureRoute: React.FC<BetaFeatureRouteProps> = ({children, betaFeature, fallback}) => {
    const betaFeatures = useBetaFeatures();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 750);
        // Clean up on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        loading ?
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box> :
        betaFeatures.includes(betaFeature) ?
            <>{children}</>
            : <> {fallback} </>
    );
};

export default BetaFeatureRoute
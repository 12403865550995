import React, {createContext, useEffect, useState} from 'react'
import {Box, Typography, useTheme} from "@mui/material";
import ImportalTabs from "@/shared-components/ImportalTabs/ImportalTabs";
import CreateNewReport from "@/pages/reports/CreateNewReport";
import Summary from "@/pages/reports/Summary";
import GeneratedReports from "@/pages/reports/GeneratedReports";
import CustomReports from "@/pages/reports/CustomReports";
import {useAPI} from "@/api/APIContext";
import {Report} from 'common/interfaces/reports'


export interface IReportsPageContext {
    reports: Report[],
    refreshReports: () => void
}

export const ReportsPageContext = createContext<IReportsPageContext>({
    reports: [],
    refreshReports: () => {
    },
});

const {Provider} = ReportsPageContext;


export default function ReportsPage() {

    const api = useAPI();
    const [reports, setReports] = useState<Report[]>([])

    const refreshReports = () => {

        api.getReportsForUser()
            .then(({data}) => {
                setReports(data)
            })
            .catch(err => {
                console.error(err)
                console.error('error getting reports')
            })
    }

    useEffect(() => {
        refreshReports()
    }, []);

    const theme = useTheme();

    return (
        <div className="main-page-header">
            <div>
                <Typography sx={{color: theme.palette.primary.main, fontSize: '26px'}}>
                    Compliance Reports
                </Typography>
            </div>
            <div className="universal-subheader">
                numbers and data about your entries
            </div>

            <Box>
                <Provider value={{
                    reports,
                    refreshReports,
                }}>
                    <ImportalTabs tabsConfig={[
                        {title: "Summary", element: <Summary/>},
                        {title: "Generated Reports", element: <GeneratedReports/>},
                        {title: "Custom Reports", element: <CustomReports/>},
                        {title: "New Report", element: <CreateNewReport/>},
                    ]}/>
                </Provider>
            </Box>
        </div>
    );
}
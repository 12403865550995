import {Container} from "@mui/material";
import {useEffect, useState} from "react";
import {EntrySummary} from "common/interfaces/entrySummary";
import {useAPI} from "@/api/APIContext";
import EntrySummaryCard from "@/broker-app/pages/shipments/EntrySummaryCard";


interface Props {

    shipmentId: string,

}

// shipment contents shows the results of
// document parsing (commercial invoice or entry summary)
// the idea is to have a place to see What Product were in the shipment
// broken out by individual invoice OR by individual entry
export default function ShipmentContents({shipmentId}: Props) {
    // for starters, a shipment is a logical container of 1 or more invoices


    const api = useAPI();


    const [entrySummaries, setEntrySummaries] = useState<EntrySummary[]>([])

    useEffect(() => {
        api.getEntrySummariesForShipment(shipmentId)
            .then(({data}) => {
                setEntrySummaries(data)
            })
            .catch(err => {
                console.error('error getting entry summaries for shipment')
                console.error(err)
            })

    }, []);


    return <Container maxWidth={'xl'}>

        {entrySummaries.map(entrySummary => {
            return <>
                <EntrySummaryCard
                    entrySummary={entrySummary}
                    onEntrySummaryUpdated={(entrySummary: EntrySummary) => {
                    }
                    }/>
                <br/>
            </>
        })

        }


    </Container>


}
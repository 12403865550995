import {Button, Card, CardContent, ToggleButton, ToggleButtonGroup, Typography, useTheme} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState,} from "react";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import HtsClassification from "../../../components/hts-classification/HtsClassification";

import "./HomeComponent.css";
import DashboardContext from "@/pages/home/DashboardProvider";
import FullOnBoardingModal from "../../../components/full-onboarding-modal/FullOnBoardingModal";
import ClassificationsTable from "@/broker-app/pages/hts-classifications/BrokerClassificationsTable";
import {useAPI} from "@/api/APIContext";
import {useActiveUser} from "@/custom-hooks/user/UserProvider";
import ShipmentsTable from "../shipments/ShipmentsTable";
import CreateShipmentModal from "../shipments/CreateShipmentModal";
import ImportalCard from "@/shared-components/ImportalCard";

export default function HomeComponent() {

    const activeUser = useActiveUser();
    const {setSelectedModule, setSelectedSubModule} =
        useContext(DashboardContext);
    const [open, setOpen] = useState(false);
    const [openHtsClassifications, setOpenHtsClassifications] = useState(false);
    const [shipments, setShipments] = useState<any>([]);
    const [classifications, setClassifications] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [latestReferenceNumber, setLatestReferenceNumber] = useState("");
    const [viewClassificationsFilter, setViewClassificationsFilter] = useState<string>("ASSIGNED")
    const api = useAPI();


    const handleLoading = (value) => {
        setIsLoading(value);
    };

    const getShipments = useCallback(() => {
        handleLoading(true);
        api.getShipmentsForBroker().then(response => {
            setShipments(response.data.userShipments);
            handleLoading(false);
        })
    }, []);


    useEffect(() => {
        handleLoading(true);

        let apiCall;
        if (viewClassificationsFilter === 'ALL') {
            apiCall = api.getAllClassifications();
        } else if (viewClassificationsFilter === 'ASSIGNED') {
            apiCall = api.getAssignedClassifications();
        } else {
            return
        }

        apiCall
            .then(({data}) => {
                setClassifications(data);
            })
            .catch(error => {
                console.error('error getting classifications from broker ui');
                console.error(error);
            })
            .finally(() => {
                handleLoading(false);
            });

    }, [viewClassificationsFilter]);


    useEffect(() => {
        getShipments();
    }, [getShipments]);

    const handleComplianceOneClick = () => {
        setSelectedModule("all-in-one");
    }

    const handleDutyCalculatorClick = () => {
        setSelectedModule("duty-calculator");
    };

    const handleHtsClassificationsClose = () => {
        setOpenHtsClassifications(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const theme = useTheme();


    const handleCustomsClearanceClick = () => {
        api.getLatestShipmentReferenceNumber().then((response: any) => {
            setLatestReferenceNumber(response.data.nextReferenceNumber as string);
            setOpen(true);
        })
    }

    const handleHtsClassificationsClick = (e) => {
        setOpenHtsClassifications(true);
    };

    return (
        <div>
            <div className="main-page-header">
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontSize: "26px",
                    }}
                >
                    Dashboard
                </Typography>
            </div>
            <div className="universal-subheader">
                Hi {activeUser.user?.firstName}, welcome back! Here's your summary of all
                things in-transit.
            </div>
            <div className="broker-call-to-action-container">
                <div>
                    <Card>
                        <CardContent>
                            <div className="my-shipments-header">Import Services</div>
                            <div className="my-shipments-subheader">
                                Let's get the shipment from Point A to Point B.
                            </div>
                            <div className="import-services-container">
                                <div>
                                    <div>
                                        <div>
                                            <Button
                                                startIcon={<TaskRoundedIcon/>}
                                                onClick={handleCustomsClearanceClick}
                                                variant="text"
                                                sx={{
                                                    textTransform: "none",
                                                    backgroundColor: "#388E3C",
                                                    color: '#FDFDFD'
                                                }}
                                                className="consultation-button"
                                            >
                                                Create Shipment
                                            </Button>
                                        </div>
                                        {open && (
                                            <CreateShipmentModal
                                                open={open}
                                                onClose={handleClose}
                                                latestReferenceNumber={latestReferenceNumber}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <Button
                                                    startIcon={<LibraryBooksRoundedIcon/>}
                                                    onClick={handleHtsClassificationsClick}
                                                    variant="text"
                                                    sx={{
                                                        textTransform: "none",
                                                        backgroundColor: "#388E3C",
                                                        color: '#FDFDFD'
                                                    }}
                                                    className="consultation-button"
                                                >
                                                    HTS Classification
                                                </Button>
                                            </div>
                                        </div>

                                        {openHtsClassifications && (
                                            <HtsClassification
                                                open={openHtsClassifications}
                                                onClose={handleHtsClassificationsClose}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div className="main-container">
                <div className="my-shipments-container">
                    <div>
                        <Card className="my-shipments-card">
                            <CardContent sx={{boxShadow: "none"}}>
                                <div className="my-shipments-header">My Shipments</div>
                                <div className="my-shipments-subheader">At a glance</div>
                                <ShipmentsTable rows={shipments} isLoading={isLoading}></ShipmentsTable>
                            </CardContent>
                        </Card>
                    </div>
                    <ImportalCard
                        title={'My HTS Classifications'}
                        subtitle={'At a glance'}
                        topRightActionButton={<div>
                            <ToggleButtonGroup
                                color="primary"
                                value={viewClassificationsFilter}
                                exclusive
                                onChange={(event: React.MouseEvent<HTMLElement>, newViewingMode: string) => {
                                    setViewClassificationsFilter(newViewingMode)
                                }}
                            >

                                <ToggleButton sx={{textTransform: 'none', color: '#525256', width: '80px'}}
                                              value={'ASSIGNED'}>Assigned</ToggleButton>
                                <ToggleButton sx={{textTransform: 'none', color: '#525256', width: '80px'}}
                                              value={'ALL'}>All</ToggleButton>

                            </ToggleButtonGroup>
                        </div>}
                    >
                        <ClassificationsTable rows={classifications} isLoading={isLoading}/>
                    </ImportalCard>
                    {openModal && (
                        <FullOnBoardingModal
                            open={openModal}
                            handleClose={handleCloseModal}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

import React, {useEffect, useState} from 'react';
import {ChartConfig} from "common/interfaces/reports";
import {useChartDimensions} from "@/custom-hooks/reporting/useChartDimensions";
import './ImportalPieChart.css'

// inspiration: https://heyoka.medium.com/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72


export interface Props {
    chartConfig: ChartConfig,
    data: any[]
}

interface PieElement {
    value: number,
    name: string
}

const chartSettings = {
    "marginLeft": 1
}
export default function ImportalPieChart({chartConfig, data}: Props) {

    const [pieElements, setPieElements] = useState<PieElement[]>([])


    useEffect(() => {

        setPieElements([])

    }, [chartConfig, data]);

    const [ref, dms] = useChartDimensions(chartSettings)

    return (
        <div
            className="Chart__wrapper"
            ref={ref}
            style={{height: "600px"}}>

            <figure>
                <svg width={dms.width} height={dms.height}>

                    <g transform={`translate(${[
                        dms.marginLeft,
                        dms.marginTop
                    ].join(",")})`}>

                        <rect
                            width={dms.boundedWidth}
                            height={dms.boundedHeight}
                            fill="lavender"
                        />

                        <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut"
                             aria-labelledby="beers-title beers-desc" role="img">
                            <title id="beers-title">Beers in My Cellar</title>
                            <desc id="beers-desc">Donut chart showing 10 total beers. Two beers are Imperial India Pale
                                Ales, four beers are Belgian Quadrupels, and three are Russian Imperial Stouts. The last
                                remaining beer is unlabeled.
                            </desc>
                            <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"
                                    role="presentation"></circle>
                            <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                    stroke="#d2d3d4" stroke-width="3" role="presentation"></circle>

                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                    stroke="#ce4b99" stroke-width="3" stroke-dasharray="40 60" stroke-dashoffset="25"
                                    aria-labelledby="donut-segment-1-title donut-segment-1-desc">
                                <title id="donut-segment-1-title">Belgian Quadrupels</title>
                                <desc id="donut-segment-1-desc">Pink chart segment spanning 40% of the whole, which is 4
                                    Belgian Quadrupels out of 10 total.
                                </desc>
                            </circle>
                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                    stroke="#b1c94e" strokeWidth="3" stroke-dasharray="20 80" stroke-dashoffset="85">
                                <title id="donut-segment-2-title">Imperial India Pale Ales</title>
                                <desc id="donut-segment-2-desc">Green chart segment spanning 20% of the whole, which is
                                    2
                                    Imperial India Pale Ales out of 10 total.
                                </desc>
                            </circle>
                            <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent"
                                    stroke="#377bbc" stroke-width="3" stroke-dasharray="30 70" stroke-dashoffset="65">
                                <title id="donut-segment-3-title">Russian Imperial Stouts</title>
                                <desc id="donut-segment-3-desc">Blue chart segment spanning 3% of the whole, which is 3
                                    Russian Imperial Stouts out of 10 total.
                                </desc>
                            </circle>

                            <g className="chart-text">
                                <text x="50%" y="50%" className="chart-number">
                                    10
                                </text>
                                <text x="50%" y="50%" className="chart-label">
                                    Beers
                                </text>
                            </g>
                        </svg>
                        <g className="figure-key">
                            <p className="sr-only">Donut chart showing 10 total beers. Two beers are Imperial India Pale
                                Ales, four beers are Belgian Quadrupels, and three are Russian Imperial Stouts. The last
                                remaining beer is unlabeled.</p>

                            <ul className="figure-key-list" aria-hidden="true" role="presentation">
                                <li>
                                    <span className="shape-circle shape-fuschia"></span> Belgian Quadrupels (4)
                                </li>
                                <li>
                                    <span className="shape-circle shape-lemon-lime"></span> Imperial India Pale Ales (2)
                                </li>
                                <li>
                                    <span className="shape-circle shape-blue"></span> Russian Imperial Stouts (3)
                                </li>
                            </ul>
                        </g>


                        <g transform={`translate(${[
                            0,
                            dms.boundedHeight,
                        ].join(",")})`}>

                        </g>
                    </g>
                </svg>


                <figcaption className="figure-key">
                    <p className="sr-only">Donut chart showing 10 total beers. Two beers are Imperial India Pale Ales,
                        four beers are Belgian Quadrupels, and three are Russian Imperial Stouts. The last remaining
                        beer is unlabeled.</p>

                    <ul className="figure-key-list" aria-hidden="true" role="presentation">
                        <li>
                            <span className="shape-circle shape-fuschia"></span> Belgian Quadrupels (4)
                        </li>
                        <li>
                            <span className="shape-circle shape-lemon-lime"></span> Imperial India Pale Ales (2)
                        </li>
                        <li>
                            <span className="shape-circle shape-blue"></span> Russian Imperial Stouts (3)
                        </li>
                    </ul>
                </figcaption>


            </figure>


        </div>
    )
}
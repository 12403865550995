import {
  alpha,
  OutlinedInputProps,
  styled,
  TextField,
  TextFieldProps,
} from "@mui/material";

export const RoundedTextfield = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
  },
}));

import React, { useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import "./ComplianceDutyMitigation.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ComplianceSummaryContext } from "common/interfaces/complianceSummary";
import { renderDMSubtext } from "@/utils/complianceSummaryRendering";


export interface Props {
    complianceSummary: ComplianceSummaryContext,
    setComplianceSummary: (ComplianceSummaryContext) => void,
    onNext: () => void,
    onBack: () => void
}

export default function ComplianceDutyMitigation({ complianceSummary, setComplianceSummary, onNext, onBack }: Props) {

    useEffect(() => {
        // To-do: Revamp this once we improve section 301 exclusions flow
        // if (complianceSummary.dutyMitigationOutput?.section301Exclusions) {
        //     setComplianceSummary({
        //         ...complianceSummary,
        //         chinaPenaltyExclusion: true
        //     })
        // } else {
        //     setComplianceSummary({
        //         ...complianceSummary,
        //         chinaPenaltyExclusion: false
        //     })
        // }

        setComplianceSummary({
            ...complianceSummary,
            chinaPenaltyExclusion: false
        })

    }, [complianceSummary])


    const renderDutyMitigationContent = (currentSummary): any => {
        let contentToReturn: any = [];
        // to-do: revamp this once we improve section 301 exclusions flow
        // if (currentSummary.dutyMitigationOutput.section301Exclusions) {
        //     if (currentSummary.dutyMitigationOutput.section301Exclusions.length > 1) {
        //         contentToReturn.push(
        //             <div style={{ marginTop: '24px' }}>
        //                 <div>Does your product fall under any of the following descriptions?</div>
        //                 <div className="exclusion-list-container">
        //                     {renderExclusionSubheaders(currentSummary)}
        //                 </div>
        //             </div>
        //         );
        //     }
        // }
        return contentToReturn;
    }

    const renderExclusionSubheaders = (currentSummary) => {
        let exclusionsToReturn: any = [];
        currentSummary.dutyMitigationOutput.section301Exclusions.forEach(exclusion => {
            exclusionsToReturn.push(
                <div className="animate-oga">
                    <div className="exclusion-content">{exclusion}</div>
                </div>
            )
        })
        return exclusionsToReturn;
    }


    const handleExclusionResponse = (value) => {
        setComplianceSummary({
            ...complianceSummary,
            chinaPenaltyExclusion: value
        })
        onNext();
    }


    const renderBottomButtons = (currentComplianceOneSummary) => {
        if (currentComplianceOneSummary.dutyMitigationOutput.section301Exclusions) {
            if (currentComplianceOneSummary.dutyMitigationOutput.section301Exclusions.length > 1) {
                return (
                    <div className="next-button-container">
                        <Button
                            type="submit"
                            onClick={() => handleExclusionResponse(false)}
                            fullWidth
                            variant="text"
                            className="back-button-text"
                        >
                            No
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => handleExclusionResponse(true)}
                            fullWidth
                            variant="contained"
                            className="next-button-green"
                        >
                            Yes
                        </Button>
                    </div>
                )
            } else {
                return (
                    <div className="next-button-container">
                        <Button
                            fullWidth
                            className="back-button-text"
                            startIcon={<KeyboardArrowLeftIcon />}
                            variant="text"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => handleExclusionResponse(true)}
                            fullWidth
                            variant="contained"
                            className="next-button-green"
                            endIcon={<KeyboardArrowRightIcon />}
                        >
                            Next
                        </Button>
                    </div>
                )
            }
        } else {
            return (
                <div className="next-button-container">
                    <Button
                        fullWidth
                        className="back-button-text"
                        startIcon={<KeyboardArrowLeftIcon />}
                        variant="text"
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        type="submit"
                        onClick={onNext}
                        fullWidth
                        variant="contained"
                        className="next-button-green"
                        endIcon={<KeyboardArrowRightIcon />}
                    >
                        Next
                    </Button>
                </div>
            )
        }
    }

    return (
        <div className="card-container">
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <div className="header-question">
                        <Typography>
                            Duty Mitigation
                        </Typography>
                    </div>
                    <div className="unlading-descriptive-text">
                        Duty Mitigation is the step where our experts go through and try to find exclusions and other
                        unique addendums to reduce or eliminate the duties owed by your product.
                    </div>
                    <div className="pga-circle-container">
                        {renderDMSubtext(complianceSummary.dutyMitigationOutput)}
                    </div>
                    <div className="duty-mitigation-content-container">
                        {renderDutyMitigationContent(complianceSummary)}
                    </div>
                    <div>
                        <div className="next-button-container">
                            <Button
                                fullWidth
                                className="back-button-text"
                                startIcon={<KeyboardArrowLeftIcon />}
                                variant="text"
                                onClick={onBack}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                onClick={onNext}
                                fullWidth
                                variant="contained"
                                className="next-button-green"
                                endIcon={<KeyboardArrowRightIcon />}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
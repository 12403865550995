import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material";
import SignupForm from "@/pages/signup/SignupForm";
import { useNavigate } from "react-router";
import './SignUp.css';

export default function SignUp() {
    const navigate = useNavigate();
    const handleExistingAccount = () => {
        navigate("/login");
    };


    const theme = useTheme();

    return (
        <div className="signup-full-container">
            <div className="link-logo-container">
                <div>
                    <img src="importal.png" height="100" width="160" alt="logo" />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography>
                        <Link
                            className="existing-account-link"
                            onClick={handleExistingAccount}
                            variant="body2"
                        >
                            Already have an account? Sign in
                        </Link>
                    </Typography>
                </div>
            </div>
            <div className="signup-container">
                <div className='guess-less-container'>
                    <div className="title-container">
                        <div>
                            <div>
                                <Typography variant="h1" style={{ fontWeight: "bold", font: "Inter", fontSize: "42px" }}>
                                    Guess Less, Import More
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ font: "Inter", fontSize: "20px", marginTop: '12px' }}>
                                    The ultimate platform for seamless trade compliance. Unlock everything your team needs for a friction-less, error-free importing experience.
                                </Typography>
                            </div>
                        </div>
                        <div className="laptop-image-container">
                            <img src="ImportalApp.png" width={600}></img>
                        </div>
                    </div>
                </div>
                <div className="signup-form-container">
                    <SignupForm />
                </div>
            </div>
        </div>
    )
        ;
}

import React, {useEffect, useState} from 'react';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {Add, Check, Close, Delete as DeleteIcon, Edit as EditIcon} from '@mui/icons-material';
import {Mode} from 'common/interfaces/business';
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal';

export interface HeaderConfig {
    header: string;
    viewComponent: (item: any) => React.JSX.Element,
    addComponent?: (item: any, setItem: (arg0: any) => void) => React.JSX.Element,
    editComponent: (item: any, setItem: (arg0: any) => void) => React.JSX.Element,
}

const alwaysUnique = (item) => {
    return Math.random() * 10000
}

interface ManageItemsProps<T> {

    manageItemsConfig: HeaderConfig[];
    items: T[];
    getDefaultItem: () => T;

    itemKeyCheck?: (item: T) => any, // item key determines uniqueness

    // methods to get data out and up
    onItemSaved?: (item: T, index: number) => void, // corner case -- user adds 2 rows, leaves one in edit and then saves the last
    onItemDeleted?: (item: T, index: number) => void,
    onItemsUpdated?: (items: T[]) => void,


    // cosmetic properties
    itemName: string;
    parentName?: string;
    fontSize?: string;
    useDeleteConfirmationModal?: boolean;
    editable?: boolean;


}

export default function ManageItems<T>({
                                           manageItemsConfig,
                                           items = [],
                                           getDefaultItem,

                                           itemKeyCheck = alwaysUnique,

                                           onItemSaved,
                                           onItemDeleted,
                                           onItemsUpdated,

                                           parentName,
                                           itemName,
                                           fontSize,
                                           useDeleteConfirmationModal,
                                           editable = true
                                       }: ManageItemsProps<T>) {


    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(0);

    const [localItems, setLocalItems] = useState<T[]>([]);
    const [itemModes, setItemModes] = useState<Mode[]>([])

    const syncItemModes = (updatedItems: T[], updatedModes: Mode[]) => {
        if (updatedItems.length !== updatedModes.length) {
            const newModes = [...updatedModes];
            while (newModes.length < updatedItems.length) {
                newModes.push(Mode.ADD);
            }
            setItemModes(newModes);
        } else {
            setItemModes(updatedModes);
        }
    }

    useEffect(() => {
        setLocalItems(items)
        setItemModes(items.map(item => Mode.VIEW))
    }, [editable]);

    useEffect(() => {
        // Initialize a map to track items by their unique key
        const itemMap: Record<any, { item: T, mode: Mode }> = {};

        // Populate the map with items from localItems
        localItems.forEach(item => {
            const key = itemKeyCheck(item);

            itemMap[key] = {item, mode: itemModes[localItems.indexOf(item)]}
        });

        // Update or add items from the items prop to the map
        items.forEach(item => {
            const key = itemKeyCheck(item);
            if (itemMap.hasOwnProperty(key)) {
                // Replace the item but keep its mode if it's not in VIEW mode
                const existing = itemMap[key];
                if (existing.mode !== Mode.VIEW) {
                    itemMap[key] = {item, mode: existing.mode};
                } else {
                    itemMap[key] = {item, mode: Mode.VIEW};
                }
            } else {
                // New items from items prop should be in VIEW mode by default
                itemMap[key] = {item, mode: Mode.VIEW};
            }
        });

        // Convert the map back to arrays for localItems and itemModes
        const newItems: T[] = [];
        const newModes: Mode[] = [];

        for (const {item, mode} of Object.values(itemMap)) {
            newItems.push(item);
            newModes.push(mode);
        }

        setLocalItems(newItems);
        setItemModes(newModes);
    }, [items]);

    const handleDeleteConfirmClose = (confirm: boolean) => {
        if (confirm) {
            handleDeleteItemRow(indexToDelete);
        }
        setDeleteConfirmOpen(false);
    }

    const setItemAtIndex = (item: any, index: number) => {
        const updatedItems = [...localItems]
        updatedItems[index] = item
        setLocalItems(updatedItems);
    };

    const handleAddItemRow = () => {
        const updatedItems = [...localItems, getDefaultItem()];
        setLocalItems(updatedItems);
        syncItemModes(updatedItems, [...itemModes, Mode.ADD]);
    };

    const handleCancelEditItemRow = (indexToUpdate: number) => {
        const updatedItemModes = [...itemModes];
        updatedItemModes[indexToUpdate] = Mode.VIEW;
        setItemModes(updatedItemModes);
    };

    const handleEditItemRow = (indexToUpdate: number) => {
        const updatedItemModes = [...itemModes];
        updatedItemModes[indexToUpdate] = Mode.EDIT;
        setItemModes(updatedItemModes);
    };

    const handleSaveItemRow = (indexToUpdate: number) => {
        const currentMode = itemModes[indexToUpdate];
        const itemToSave: T = localItems[indexToUpdate];
        let updatedItems: T[] = [...items];

        if (currentMode === Mode.EDIT) {
            // If the item is in EDIT mode, update the item at the given index
            updatedItems[indexToUpdate] = itemToSave;
        } else {
            // Implicitly Mode.ADD
            if (indexToUpdate > items.length - 1) {
                // If the index is beyond the bounds of items, add it to the end
                updatedItems.push(itemToSave);
            } else {
                // If the index is within bounds, check itemKeyCheck for conflict
                const itemKeyInItems = itemKeyCheck(items[indexToUpdate]);
                const itemKeyInLocalItems = itemKeyCheck(itemToSave);

                if (itemKeyInItems === itemKeyInLocalItems) {
                    // Conflict: replace the item at the index with the itemToSave
                    updatedItems[indexToUpdate] = itemToSave;
                } else {
                    // No conflict: insert the itemToSave at the current index
                    updatedItems.splice(indexToUpdate, 0, itemToSave);
                }
            }
        }

        // Update the itemModes to set the mode of the saved item to VIEW
        const updatedItemModes = [...itemModes];
        updatedItemModes[indexToUpdate] = Mode.VIEW;
        setItemModes(updatedItemModes);

        // Call onItemSaved callback
        onItemSaved?.(itemToSave, indexToUpdate);

        // Call onItemsUpdated with the updated items array
        onItemsUpdated?.(updatedItems);
    };

    const handleOpenDeleteConfirmModal = (index) => {
        setIndexToDelete(index);
        if (useDeleteConfirmationModal) {
            setDeleteConfirmOpen(true);
        } else {
            handleDeleteItemRow(index);
        }
    }

    const handleDeleteItemRow = (index: number) => {
        const currentMode = itemModes[index];

        // If the item is in ADD mode, simply remove it from localItems
        if (currentMode === Mode.ADD) {
            const updatedItems = localItems.filter((_, i) => i !== index);
            const updatedModes = itemModes.filter((_, i) => i !== index);

            setLocalItems(updatedItems);
            syncItemModes(updatedItems, updatedModes);
        } else if (currentMode === Mode.VIEW) {
            // For VIEW mode, find the corresponding index in the items array
            const itemToDelete = localItems[index];

            // Filter out the item from the items array
            const updatedItems = items.filter(item => itemKeyCheck(item) !== itemKeyCheck(itemToDelete));

            // Update localItems and itemModes
            const updatedLocalItems = localItems.filter((_, i) => i !== index);
            const updatedLocalModes = itemModes.filter((_, i) => i !== index);

            setLocalItems(updatedLocalItems);
            syncItemModes(updatedLocalItems, updatedLocalModes);

            // Notify parent component
            onItemDeleted?.(itemToDelete, index);
            onItemsUpdated?.(updatedItems);
        }
    };

    function capitalizeString(str) {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const renderItemRow = (item: any, manageItemConfigs: HeaderConfig[], index: number, mode: Mode) => {

        return (
            <TableRow className="items-table-row" key={`${mode}-${index}`}>

                {manageItemConfigs.map((manageItemConfig => {
                    return <TableCell key={`${manageItemConfig.header}-${index}-${mode}`}>
                        {mode === Mode.VIEW &&
                            manageItemConfig.viewComponent(item)
                        }

                        {(mode === Mode.ADD) &&
                            (manageItemConfig.addComponent?.(item, (item) => {
                                setItemAtIndex(item, index)
                            }) || manageItemConfig.editComponent(item, (item) => {
                                setItemAtIndex(item, index)
                            }))
                        }

                        {mode === Mode.EDIT &&
                            manageItemConfig.editComponent(item, () => {
                                setItemAtIndex(item, index)
                            })
                        }
                    </TableCell>
                }))
                }

                {editable && (
                    <TableCell>
                        {mode === Mode.VIEW &&
                            <>
                                <IconButton onClick={() => handleEditItemRow(index)}>
                                    <EditIcon/>
                                </IconButton>
                                <IconButton onClick={() => handleOpenDeleteConfirmModal(index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </>
                        }
                        {mode === Mode.EDIT &&
                            <>
                                <IconButton onClick={() => handleCancelEditItemRow(index)}>
                                    <Close/>
                                </IconButton>
                                <IconButton onClick={() => handleSaveItemRow(index)}>
                                    <Check/>
                                </IconButton>
                            </>
                        }
                        {mode === Mode.ADD &&
                            <>
                                <IconButton onClick={() => handleDeleteItemRow(index)}>
                                    <Close/>
                                </IconButton>
                                <IconButton onClick={() => handleSaveItemRow(index)}>
                                    <Check/>
                                </IconButton>
                            </>
                        }
                    </TableCell>
                )}

            </TableRow>
        );
    };

    return (
        <>
            <div className="entry-card-header-container">
                <div style={{fontSize: fontSize}} className="my-shipments-header">{capitalizeString(itemName)}</div>
                {editable && <IconButton onClick={handleAddItemRow}><Add/></IconButton>}
            </div>
            {localItems && localItems.length > 0 ? (
                <div className="items-table-container">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {manageItemsConfig.map(({header}, idx) => (
                                        <TableCell key={idx} sx={{fontWeight: '700', color: '#525256'}}>
                                            {header}
                                        </TableCell>
                                    ))}
                                    {editable && <TableCell></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody className="user-row">
                                {localItems
                                    .map((item, index) => (
                                        renderItemRow(item, manageItemsConfig, index, itemModes[index])
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ) : (
                <div className='no-results-container'>No {itemName} saved yet for this {parentName}.</div>
            )}
            <DeleteConfirmationModal open={deleteConfirmOpen} title={"Delete Item"} onClose={handleDeleteConfirmClose}/>
        </>
    );
}

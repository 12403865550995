import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";

export default function HtsConfirmation({ handleEvent }) {
    const theme = useTheme();
    return (
        <Box>
        <div className="header-question">
          <Typography
            sx={{ color: theme.palette.primary.main, fontSize: "36px" }}
          >
            Classification time!
          </Typography>
        </div>
        <div className="unlading-descriptive-text">
          Thanks for submitting your product classification, our experts will be in touch shortly.
        </div>
        <div className="next-button-container">
          <Button
            fullWidth
            className="next-button-green"
            onClick={() => handleEvent()}
          >
            Close
          </Button>
        </div>
      </Box>
    )
}
import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Link, Paper } from "@mui/material";
import "./BusinessesTable.css"
import { Business, BusinessWithDocuments } from "common/interfaces/business";
import ImportalTable from "@/shared-components/ImportalTable/ImportalTable";
import { getStepDescription } from "common/interfaces/customsOnboarding";
import { useLocation, useNavigate } from "react-router";
import { Add } from "@mui/icons-material";
import ImportalPrimaryButton from "@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton";


interface RowProps {
    row: any,
    onClickEditBusiness: (business: BusinessWithDocuments) => void
    onClickEditCustomsOnboarding: () => void
}

const Row = ({ row, onClickEditBusiness, onClickEditCustomsOnboarding }: RowProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const headerCellStyle = {
        whiteSpace: 'nowrap',
        '&:hover': { fontWeight: '600!important' }
    };

    const handleBusinessNameClick = (id: string) => {
        let url = "";
        url = `${location.pathname}/${id}`;
        navigate(url);
        // window.open(url, '_blank');
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell />
                <TableCell>
                    <Link sx={{ textDecoration: 'none' }} component="button" onClick={() => {
                        handleBusinessNameClick(row._id)
                    }}>
                        <Typography sx={headerCellStyle}> {row.name} </Typography>
                    </Link>
                </TableCell>
                <TableCell sx={headerCellStyle}>
                    <Typography>{row.businessDomains ? row.businessDomains.toString() : 0}</Typography>
                </TableCell>
                <TableCell sx={headerCellStyle}>
                    <Typography>{getStepDescription(row.customsOnboardingStep)}</Typography>
                </TableCell>
                <TableCell />
            </TableRow>
        </React.Fragment>
    );
}

const businessesColumnConfig = [
    {
        displayName: 'Business Name',
        name: 'name',
        dataType: 'string',
        sortable: true,
        filterable: true
    },
    {
        displayName: 'Business Domains',
        name: 'businessDomains',
        dataType: 'string',
        sortable: false,
        filterable: true
    },
    {
        displayName: 'Customs Onboarded Status',
        name: 'customsOnboardingStep',
        dataType: 'string',
        sortable: false,
        filterable: true,
        categorizer: getStepDescription
    },
];


interface Props {
    rows: BusinessWithDocuments[],
    isLoading: boolean,
    onClickEditBusiness: (business: BusinessWithDocuments) => void,
    onClickEditCustomsOnboarding: () => void,
    onClickCreateBusiness: () => void
}

const BusinessesTable = ({ rows, isLoading, onClickEditBusiness, onClickEditCustomsOnboarding, onClickCreateBusiness }: Props) => {

    return (
        <div className="broker-businesses-table-container">
            <ImportalTable data={rows}
                loading={isLoading}
                rowGenerator={(row: any, index: number) => <Row key={`row-${index}`}
                    onClickEditBusiness={onClickEditBusiness}
                    onClickEditCustomsOnboarding={onClickEditCustomsOnboarding}
                    row={row} />}
                topRightElement={<></>}
                columnConfig={businessesColumnConfig}
                topRightButton={<ImportalPrimaryButton
                    style={{ width: '180px', borderRadius: '16px', fontSize: '14px' }}
                    onClick={onClickCreateBusiness}
                    text="Create Business"
                    endIcon={<Add />}
                />}
            />
        </div>
    );
}
export default BusinessesTable

import React, { createContext, useContext, ReactNode } from 'react';
import { ImportalAPI } from '.';

interface APIContextProps {
  children: ReactNode;
  apiInstance: ImportalAPI;
}

const APIContext = createContext<ImportalAPI | undefined>(undefined);

export const APIProvider: React.FC<APIContextProps> = ({ children, apiInstance }) => (
  <APIContext.Provider value={apiInstance}>{children}</APIContext.Provider>
);

export const useAPI = (): ImportalAPI => {
  const api = useContext(APIContext);
  if (!api) {
    throw new Error('useAPI must be used within an APIProvider');
  }
  return api;
};

import React, { createContext, useEffect, useState } from "react";
import { Button, Typography, useTheme } from "@mui/material";
import './Shipments.css';
import { Route, Routes, useNavigate, } from "react-router-dom";
import { useAPI } from "@/api/APIContext";
import { Add } from "@mui/icons-material";
import DataImportLoader, { LoadingStatus } from "@/components/data-import-loader/DataImportLoader";
import ShipmentsTable from "./ShipmentsTable";
import ShipmentPage from "./ShipmentPage";
import CreateShipmentModal from "./CreateShipmentModal";
import InfoAlert from "@/components/info-alert/InfoAlert";
import moment from "moment";
import { ActionType } from "common/interfaces/action";
import ImportalPrimaryButton from "@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton";

export interface IShipmentsContext {
    refreshShipments: () => void;
    loadingStatus?: LoadingStatus;
    loadingText?: string;
    successText?: string;
    errorText?: string;
    setLoadingStatus: (status: LoadingStatus) => void;
    setLoadingText: (text: string) => void;
    setSuccessText: (text: string) => void;
    setErrorText: (text: string) => void;
}

export const ShipmentsContext = createContext<IShipmentsContext>({
    refreshShipments: () => { },
    loadingStatus: LoadingStatus.NOT_LOADING,
    loadingText: '',
    successText: '',
    errorText: '',
    setLoadingStatus: () => { },
    setLoadingText: () => { },
    setSuccessText: () => { },
    setErrorText: () => { },
});

export default function Shipments() {
    const [shipments, setShipments] = useState<any>([]);
    const [createShipmentModalOpen, setCreateShipmentModalOpen] = useState(false);
    const [latestReferenceNumber, setLatestReferenceNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const api = useAPI();
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
    const [loadingText, setLoadingText] = useState('');
    const [successText, setSuccessText] = useState('');
    const [errorText, setErrorText] = useState('');
    const currentMonth = moment().format("MMMM");
    const [monthlyReferenceUsageCount, setMonthlyReferenceUsageCount] = useState(0);
    const [showReferenceAlert, setShowReferenceAlert] = useState(true);

    const handleLoading = (value: boolean) => {
        setIsLoading(value);
    };

    const handleCreateShipmentModalClose = () => {
        setCreateShipmentModalOpen(false);
    }

    const handleCreateShipmentModalOpen = () => {
        api.getLatestShipmentReferenceNumber().then((response: any) => {
            setLatestReferenceNumber(response.data.nextReferenceNumber as string);
            setCreateShipmentModalOpen(true);
        })
    }



    const getVizionReferenceUsage = () => {
        setLoadingStatus(LoadingStatus.LOADING);
        api.getMonthlyReferencesCreated()
            .then(response => {
                setLoadingStatus(LoadingStatus.SUCCESS);
                setSuccessText("Successfully retrieved Vizion information");
                setMonthlyReferenceUsageCount(response.data.length);
            })
            .catch(error => {
                setLoadingStatus(LoadingStatus.ERROR);
                setErrorText("Failed to retrieve Vizion information");
            });
    }


    const refreshShipments = () => {
        setLoadingStatus(LoadingStatus.LOADING);
        setLoadingText("Retrieving shipments...");
        api.getShipmentsForBroker()
            .then(function (response) {
                setLoadingStatus(LoadingStatus.SUCCESS);
                setSuccessText("Successfully retrieved shipments");
                setShipments(response.data.userShipments);
            })
            .catch(function (error) {
                setLoadingStatus(LoadingStatus.ERROR);
                console.error('error getting shipments for broker')
                console.error(error); // Consider more advanced error handling or user notifications
            });
    };


    useEffect(() => {
        refreshShipments();
        getVizionReferenceUsage();
    }, []);

    return (
        <ShipmentsContext.Provider value={{ refreshShipments, setLoadingStatus, setLoadingText, setErrorText, setSuccessText }}>
            <Routes>
                <Route index element={
                    <>
                        <div className='product-library-header'>
                            <div className="business-header-text">
                                <Typography sx={{ color: theme.palette.primary.main, fontSize: "26px" }}>
                                    Shipments
                                </Typography>
                                <div className="universal-subheader">
                                    Keep track of the customer's products path every step of the way.
                                </div>
                            </div>
                        </div>
                        {showReferenceAlert &&
                            <InfoAlert
                                message={`Heads up! We've used ${monthlyReferenceUsageCount} of our 300 monthly Vizion references for the month of ${currentMonth}.`}
                                onClose={() => setShowReferenceAlert(false)}
                            />}
                        <div className="shipments-table-container">
                            <ShipmentsTable rows={shipments} isLoading={isLoading} createShipmentButton={<ImportalPrimaryButton
                                style={{ width: '190px', borderRadius: '16px', fontSize: '14px' }}
                                onClick={handleCreateShipmentModalOpen}
                                text="Create Shipment"
                                endIcon={<Add />}
                            />} ></ShipmentsTable>
                        </div>
                        <CreateShipmentModal
                            open={createShipmentModalOpen}
                            onClose={handleCreateShipmentModalClose}
                            latestReferenceNumber={latestReferenceNumber}
                        />
                        <DataImportLoader
                            loadingState={{ loadingStatus }}
                            loadingText={loadingText}
                            successText={successText}
                            errorText={errorText}
                        />
                    </>
                } />
                <Route path='/*' element={<ShipmentPage />} />
            </Routes>
        </ShipmentsContext.Provider>
    );
}


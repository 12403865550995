import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ReviewOrder from './sections/ReviewOrder/ReviewOrder';
import PaymentSuccess from './sections/PaymentSuccess/PaymentSuccess';

import './PlanDetails.css';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { useEffect, useState } from 'react';
import { OfferedPurchaseAny } from 'common/interfaces/plan';
import { useAPI } from '@/api/APIContext';
import CardPlan from '@/shared-components/OfferedPurchaseCard/CardPlan';

interface PlanDetailsProps {
  openModal: boolean;
  closeHandler: () => void;
  setOpenModal: (open: boolean) => void;
  onLoaded?: () => void;
}

const PlanDetails: React.FC<PlanDetailsProps> = ({ openModal, closeHandler, setOpenModal, onLoaded }) => {
  const api = useAPI();
  const activePlan = useActivePlan();
  const [activeStep, setActiveStep] = React.useState<String>('planDetails');
  const [isLoading, setIsLoading] = useState(true);

  const [availablePurchases, setAvailablePurchases] = useState<OfferedPurchaseAny[]>([]);
  const [userSelectedPurchase, setUserSelectedPurchase] = useState<OfferedPurchaseAny | undefined>();

  useEffect(() => {
    if (openModal) {
      setIsLoading(true);
      api
        .getOfferedPlans()
        .then(({ data: offeredPlans }) => {
          setAvailablePurchases(offeredPlans);
          setIsLoading(false);
          if (onLoaded) onLoaded();
        })
        .catch((err) => {
          console.error('error getting plans for purchase');
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [openModal]);

  const preparePlanDetailsContent = React.useMemo(
    () => (
      <div>
        <div className='my-shipments-header'>{activePlan.isNormalProPlan ? 'Change my plan' : 'Purchase a Plan'}</div>
        <div className='my-shipments-subheader'>We'll get you a plan that fits your import and compliance needs</div>
        <Paper id='modal-modal-description' sx={{ mt: 3, boxShadow: 'none' }}>
          <div style={{display: 'flex', gap: '8px'}}>
            {availablePurchases.map((planItem) => (
              <CardPlan
                key={`card-plan-${planItem.key}`}
                offeredPurchase={planItem}
                onSelected={() => {
                  if (planItem.key === 'Enterprise') {
                    const calendlyUrl = 'https://app.apollo.io/#/meet/GravesCowsar/30-min';
                    window.open(calendlyUrl, '_blank');
                  } else {
                    setUserSelectedPurchase(planItem);
                    setActiveStep('reviewOrder');
                  }
                }}
              />
            ))}
          </div>
        </Paper>
        <div className='plan-details-link'>
          <a href='https://www.importal.com' style={{ textDecoration: 'none' }} target='_blank' rel='noreferrer'>
            <div
              style={{
                cursor: 'pointer',
                fontSize: '16px',
                marginTop: '8px',
                color: '#525256',
              }}
            ></div>
          </a>
        </div>
        <IconButton
          onClick={closeHandler}
          aria-label='close'
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
      </div>
    ),
    [activePlan.plan, availablePurchases]
  );

  const prepareConsulation = React.useMemo(
    () => (
      <Box>
        <iframe
          title='canlendly'
          className='frame-container'
          height='500'
          width='760'
          src='https://calendly.com/importal-demo/30min'
        />
      </Box>
    ),
    []
  );

  if (!openModal || isLoading) return null;

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={closeHandler}
        maxWidth="lg"
      >
        <>
          {activeStep === 'planDetails' && preparePlanDetailsContent}
          {activeStep === 'reviewOrder' && (
            <ReviewOrder
              setOpen={setOpenModal}
              open={openModal}
              purchase={userSelectedPurchase}
              setActiveStep={setActiveStep}
              hasActiveSubscription={activePlan.isNormalProPlan || !activePlan.isFreePlan}
            />
          )}
          {activeStep === 'enterpricePlan' && prepareConsulation}
          {activeStep === 'success' && <PaymentSuccess setActiveStep={setActiveStep} closeHandler={closeHandler} />}
        </>
      </Dialog>
    </div>
  );
};

export default PlanDetails;

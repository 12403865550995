import React, { useRef, useImperativeHandle } from "react";

const StripeInput = (props: any) => {
  const {
    component: Component,
    inputRef,
    handleElementChange,
    ...other
  } = props;
  const elementRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current?.focus,
  }));

  return (
    <Component
      onReady={(element: any) => (elementRef.current = element)}
      {...other}
      onChange={handleElementChange}
    />
  );
};

export default StripeInput;

import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {useAPI} from "@/api/APIContext";
import * as ls from 'local-storage';
import {datadogRum} from '@datadog/browser-rum';
import {Business, BusinessWithDocuments} from 'common/interfaces/business';
import {useActiveUser} from "@/custom-hooks/user/UserProvider";
import {AxiosError} from "axios";


interface ActiveBusinessContextProps {
    children: ReactNode;
}


export type IActiveBusinessContext = {
    business?: BusinessWithDocuments,
    refreshBusiness: () => void,
}
const ActiveBusinessContext = createContext<IActiveBusinessContext>({
    business: undefined,
    refreshBusiness: () => {
    }
});

export const ActiveBusinessProvider: React.FC<ActiveBusinessContextProps> = ({children}) => {

    const activeUser = useActiveUser();
    const api = useAPI();

    const [activeBusiness, setActiveBusiness] = useState<BusinessWithDocuments | undefined>(undefined)


    const getBusiness = useCallback(() => {
        api.getActiveBusiness()
            .then(({data: businesses}) => {

                // TODO fix this
                // TODO: remove active business on login & logout?
                datadogRum.setUserProperty('business', businesses[0])
                setActiveBusiness(businesses[0])
                ls.set('active-business', businesses[0])
            })
            .catch((err: AxiosError) => {

                console.error('error getting business for user', err)
                if (err.response?.data && (err.response.data as any).error) {
                    const error = (err.response.data as any).error
                    if (error.message && error.message.endsWith('no longer exists.')) {
                        // Clear the caches if error message ends with 'no longer exists'
                        datadogRum.setUserProperty('business', null)
                        setActiveBusiness(undefined)
                        ls.remove('active-business')
                    }
                }

            })
    }, [api]);

    useEffect(() => {

        setActiveBusiness(ls.get('active-business'))

        if (activeUser.user && activeUser.user?.business) {
            getBusiness()
        } else {

            setActiveBusiness(undefined);
            ls.remove('active-business');

        }

    }, [activeUser.user, getBusiness]);

    const refreshBusiness = useCallback(() => {
        getBusiness();
    }, [getBusiness]);


    return <ActiveBusinessContext.Provider
        value={{
            business: activeBusiness,
            refreshBusiness
        }}>{children}</ActiveBusinessContext.Provider>
};


export const useActiveBusiness = (): IActiveBusinessContext => {
    const activeBusiness = useContext(ActiveBusinessContext);
    if (!activeBusiness) {
        throw new Error('useActiveBusiness must be used within an ActiveBusinessProvider');
    }
    return activeBusiness;
};
import React, {useContext, useState} from "react";
import {Button, Card, CardContent, Dialog, TextField, Typography, useTheme} from "@mui/material";
import LightTooltip from "@/components/tooltip-component/TooltipComponent";
import InfoIcon from "@mui/icons-material/Info";
import ProductCodeAutocomplete from "@/components/product-code-autocomplete/ProductCodeAutocomplete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Code} from "common/interfaces/code";
import {AppContext} from "@/App";

export interface Props {
    htsCode?: Code,
    setHTSCode: (Code) => void
    onNext: () => void
}

export default function HTSCode({htsCode, setHTSCode, onNext}: Props) {

    const theme = useTheme();
    const {setOpenContactUs} = useContext(AppContext);

    const handleAutocompleteEvent = (code: Code | null) => {
        setHTSCode(code);
    };

    return (
        <div className="card-container">
            <Card sx={{minWidth: 275}}>
                <CardContent>
                    <div className="header-question">
                        <Typography>
                            What's your product's HTS code?
                        </Typography>
                    </div>
                    <div className="unlading-descriptive-text">
                        <LightTooltip
                            title="A HTS Code is U.S. Customs and Border Protection's way to categorize your product.">
                            <b className="tooltip-hover-text">HTS codes</b>
                        </LightTooltip>
                        are used to classify products being imported into the United States.
                        These codes are either eight or ten digits.
                        <LightTooltip
                            title="Your code will tell you which duty rates and other special addendums apply.">
                            <div className="tooltip-container">
                                <InfoIcon sx={{fontSize: "16px"}}/>
                            </div>
                        </LightTooltip>
                    </div>
                    <div className="hts-example-title">Anatomy of a HTS Code</div>
                    <div className="hts-example">
                        <div className="parent-classification">
                            <div className="numbers">
                                <div>2</div>
                                <div>0</div>
                            </div>
                            <div className="bottom-description">Chapter</div>
                        </div>
                        <div className="parent-classification">
                            <div className="numbers">
                                <div>1</div>
                                <div>0</div>
                            </div>
                            <div className="bottom-description">Heading</div>
                        </div>
                        <div className="parent-classification">
                            <div className="numbers">
                                <div>1</div>
                                <div>0</div>
                                <div>1</div>
                                <div>0</div>
                            </div>
                            <div className="bottom-description">Subheading</div>
                        </div>
                        <div className="parent-classification">
                            <div className="numbers">
                                <div>2</div>
                                <div>3</div>
                            </div>
                            <div className="bottom-description">Suffix</div>
                        </div>
                    </div>
                    <div className="hts-code-text-field">
                        <ProductCodeAutocomplete selectedCode={htsCode} onCodeSelected={handleAutocompleteEvent}/>
                    </div>
                    <div className="dont-know-code">
                        <Button
                            variant="text"
                            onClick={() => setOpenContactUs(true)}
                            sx={{
                                mt: 2,
                                mb: 1,
                                width: "220px",
                                color: theme.palette.primary.main,
                                maxWidth: "400px",
                            }}
                        >
                            Don't know your code?
                        </Button>
                    </div>
                    <div className="next-button-container">
                        <Button
                            type="submit"
                            fullWidth
                            className="next-button-green"
                            onClick={onNext}
                            endIcon={<KeyboardArrowRightIcon/>}
                            variant="contained"
                            disabled={!htsCode}
                        >
                            Next
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
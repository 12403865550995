import {Types} from 'mongoose';
import React, {useEffect, useState} from 'react';
import {useAPI} from '@/api/APIContext';
import {Box, Dialog, Modal, Typography} from '@mui/material';
import {LoadingStatus} from "@/components/data-import-loader/DataImportLoader";

interface Props {
    documentIds: Types.ObjectId[];
}


export function ViewPhotos({documentIds}: Props) {
    const api = useAPI();

    const [fileURLs, setFileURLs] = useState<string[]>([]);
    const [loadingStates, setLoadingStates] = useState<LoadingStatus[]>(documentIds.map(() => LoadingStatus.NOT_LOADING));
    const [indexForViewInModal, setIndexForViewInModal] = useState<number | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const urls = await Promise.all(
                documentIds.map(async (documentId, index) => {

                    return api.getDocumentData(documentId.toString())
                        .then((documentDataResponse) => {
                            const url = URL.createObjectURL(new Blob([documentDataResponse.data]));
                            setLoadingStates(prevStates => {
                                const newStates = [...prevStates];
                                newStates[index] = LoadingStatus.SUCCESS;
                                return newStates;
                            });
                            return url;
                        })
                        .catch(err => {
                            console.error('error getting data for photo')
                            console.error(err)
                            setLoadingStates(prevStates => {
                                const newStates = [...prevStates];
                                newStates[index] = LoadingStatus.ERROR;
                                return newStates;
                            });
                            return ""
                        })

                })
            );

            setFileURLs(urls);
        };

        fetchData();
    }, [documentIds, api]);

    const handleThumbnailClick = (index: number) => {
        setIndexForViewInModal(index);
    };

    const handleCloseModal = () => {
        setIndexForViewInModal(null);
    };

    return (
        <div style={{display: "flex", gap: '8px'}}>
            {fileURLs.map((url, index) => (
                <div key={index} >
                    {([LoadingStatus.LOADING, LoadingStatus.NOT_LOADING].includes(loadingStates[index])) &&
                        <Box
                            sx={{
                                width: '160px',
                                height: '160px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '4%'
                            }}
                        >
                            <Typography variant="body2" color="textSecondary">
                                Loading...
                            </Typography>
                        </Box>
                    }

                    {(loadingStates[index] === LoadingStatus.SUCCESS) &&
                        <img
                            src={url}
                            style={{borderRadius: '4%', maxHeight: '160px', maxWidth: '160px'}}
                            alt="Preview"
                            loading='lazy'
                            onClick={() => handleThumbnailClick(index)}
                        />
                    }

                    {(loadingStates[index] === LoadingStatus.ERROR) &&
                        <Box
                            sx={{
                                width: '160px',
                                height: '160px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '4%'
                            }}
                        >
                            <Typography variant="body2" color="textSecondary">
                                Error
                            </Typography>
                        </Box>
                    }


                </div>
            ))}

            <Dialog maxWidth="xl" open={indexForViewInModal !== null} onClose={handleCloseModal}>
                    {indexForViewInModal !== null && (
                        <img
                            src={fileURLs[indexForViewInModal]}
                            alt="Full View"
                        />
                    )}
            </Dialog>
        </div>
    );
}

import { CMQLine, WR1CMQLine, WR4CMQLine, WR5CMQLine, WSDCMQLine } from "common/interfaces/aceDataSource";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import moment from "moment-timezone";
import './CMQRendering.css'

interface CargoManifestQueryResultsProps {
    parsedCargoManifest: CMQLine[],
    rawCargoManifest: string,
    billType: string
}

function MultilineText({ text }) {
    const lines = text.split('\n');
    return (
        <div>
            {lines.map((line, index) => (
                <div key={index}>{line}</div>
            ))}
        </div>
    );
}

export function CargoManifestResults({ parsedCargoManifest, rawCargoManifest, billType }: CargoManifestQueryResultsProps) {

    const [alignment, setAlignment] = React.useState('parsed');

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };

    const resultsToReturn: any = [
        <div className="cmq-button-group">
            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
            >
                <ToggleButton sx={{ textTransform: 'none', color: '#525256', width: '80px' }}
                    value="raw">Raw</ToggleButton>
                <ToggleButton sx={{ textTransform: 'none', color: '#525256', width: '80px' }}
                    value="parsed">Parsed</ToggleButton>
            </ToggleButtonGroup>
        </div>
    ]
    if (alignment === "raw") {
        resultsToReturn.push(
            <div className="raw-cmq-container">
                <MultilineText text={rawCargoManifest} />
            </div>
        )
    } else if (alignment === "parsed") {
        if (billType === "OceanTruckRail") {
            resultsToReturn.push(renderOceanTruckRailBill(parsedCargoManifest))
        } else if (billType === "Airway") {
            resultsToReturn.push(renderAirwayBill(parsedCargoManifest))
        }
    }
    return resultsToReturn;
}



function parseDateAndTime(dateStr: string, timeStr: string, timezone: string = 'America/New_York'): any {
    // Format: MMDDYYHHmm (24-hour format for hours)
    const format = 'MMDDYYHHmm';
    const dateTimeStr = dateStr + timeStr;
    // Parse the datetime in the given timezone, using 24-hour format
    const dateTime = moment.tz(dateTimeStr, format, timezone);
    const easternTimeFormatted = dateTime.format('MM/DD/YY h:mm A');
    return easternTimeFormatted;
}

function parseDateAndTimev2(dateStr: string, timeStr: string = '', timezone: string = 'America/New_York'): any {
    // Determine format based on whether a time string is provided
    let format = timeStr ? 'MMDDYYHHmm' : 'MMDDYY';
    const dateTimeStr = dateStr + timeStr;

    // Parse the datetime in the given timezone
    const dateTime = moment.tz(dateTimeStr, format, timezone);
    const formattedOutput = dateTime.format('MM/DD/YY' + (timeStr ? ' h:mm A' : ''));

    return formattedOutput;
}

const renderOceanTruckRailBill = (parsedCargoManifest: CMQLine[]) => {
    return (
        <div className="parsed-cmq-container">
            {parsedCargoManifest.map((line, index) => (
                <>
                    {(line.lineType === "WR1") ? renderWR1CMQLine(line as WR1CMQLine) : <></>}
                    {(line.lineType === "WR4") &&
                        <div className="cargo-manifest-results-container">
                            <div className="cmq-text-header">{renderBillOfLadingType(line as WR4CMQLine)}</div>
                            <div className="cmq-text-subheader">{renderBillOfLadingNumber(line as WR4CMQLine)}</div>
                            <div className="cmq-line-item-container">
                                <div className="shipment-page-breakdown-container-header"
                                    key="headre-duty-title-pga-one">
                                    <div style={{minWidth: '150px'}}>Date / Time</div>
                                    <div style={{minWidth: '60px'}}>Code</div>
                                    <div>Description</div>
                                </div>

                                {((line as WR4CMQLine).wr5Lines) &&
                                    (line as WR4CMQLine).wr5Lines.map((wr5CMQLine: WR5CMQLine, index) => {
                                        return <div className="shipment-breakdown-container-row" key={index}>
                                            <div style={{minWidth: '150px'}}>
                                                {parseDateAndTime(wr5CMQLine.dispositionActionDate, wr5CMQLine.dispositionActionTime)}
                                            </div>
                                            <div
                                                style={{minWidth: '60px'}}>{wr5CMQLine.dispositionActionCode}</div>
                                            <div className="duty-calculation-amount">
                                                {wr5CMQLine.narrativeMessage}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }

                </>
            ))}
        </div>
    )
}

const renderBillOfLadingType = (line: WR4CMQLine) => {
    switch (line.billOfLadingType) {
        case "M":
            return "Master Bill"
        case "H":
            return "House Bill"
        default:
            return "";
    }
}

const renderBillOfLadingNumber = (line: WR4CMQLine) => {
    if (line.billOfLadingType === "M") {
        return `${line.issuerCodeOfMasterBillNumber}${line.masterBillNumber}`;
    } else if (line.billOfLadingType === "H") {
        return `${line.issuerCodeOfHouseBillNumber}${line.houseBillNumber}`;
    } else {
        return "";
    }
}

const renderAirwayBill = (parsedCargoManifest: CMQLine[]) => {
    return (
        <div className="parsed-cmq-container">
            {parsedCargoManifest.map((line, index) => (
                <>
                    <div className="cargo-manifest-results-container">
                        <div className="cmq-line-item-container">
                            <div>

                                {(line.lineType === "WR1") &&
                                    renderWR1CMQLineAirway(line as WR1CMQLine)
                                }
                                {(line.lineType === "WSD") &&
                                    renderWSDCMQLineAirway(line as WSDCMQLine)
                                }
                            </div>
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
}

const renderWR1CMQLine = (wr1CMQLine: WR1CMQLine) => {
    return <div className="wr1-header">
        <div>
            <div>
                <div className="wr1-header-prefix">Date of Arrival</div>
                <div className="wr1-header-value">{parseDateAndTimev2(wr1CMQLine.dateOfArrival)}</div>
            </div>
            <div>
                <div className="wr1-header-prefix">Port of Entry</div>
                <div className="wr1-header-value">{wr1CMQLine.districtPortOfEntry}</div>
            </div>
            <div>
                <div className="wr1-header-prefix">Carrier Code</div>
                <div className="wr1-header-value">{wr1CMQLine.carrierCode}</div>
            </div>
            <div>
                <div className="wr1-header-prefix">Voyage / Flight Number</div>
                <div className="wr1-header-value">{wr1CMQLine.voyageFlightTripManifestNumber}</div>
            </div>
        </div>
    </div>
}

const renderWR1CMQLineAirway = (wr1CMQLine: WR1CMQLine) => {
    return <>
        <div className="wr1-header">
            <div>
                <div className="wr1-header-prefix">Date of Arrival:</div>
                <div
                    className="wr1-header-value">{parseDateAndTimev2(wr1CMQLine.dateOfArrival)}</div>
            </div>
            <div>
                <div className="wr1-header-prefix">Port of Entry:</div>
                <div className="wr1-header-value">{wr1CMQLine.districtPortOfEntry}</div>
            </div>
            <div>
                <div className="wr1-header-prefix">Carrier Code:</div>
                <div className="wr1-header-value">{wr1CMQLine.carrierCode}</div>
            </div>
            <div>
                <div className="wr1-header-prefix">Voyage / Flight Number:</div>
                <div
                    className="wr1-header-value">{wr1CMQLine.voyageFlightTripManifestNumber}</div>
            </div>
        </div>
        <div className="shipment-page-breakdown-container-header"
            key="headre-duty-title-pga-one">
            <div style={{minWidth: '150px'}}>Date / Time</div>
            <div style={{minWidth: '60px'}}>Code</div>
            <div>Description</div>
        </div>
    </>
}

const renderWSDCMQLineAirway = (wsdCMQLineAirway: WSDCMQLine) => {
    return <div className="shipment-breakdown-container-row">
        <div style={{minWidth: '150px'}}>
            {parseDateAndTime(wsdCMQLineAirway.dispositionActionDate, wsdCMQLineAirway.dispositionActionTime)}
        </div>
        <div style={{minWidth: '60px'}}>{wsdCMQLineAirway.dispositionCode}</div>
        <div>
            {wsdCMQLineAirway.narrativeMessage}
        </div>
    </div>

}

import { useMatch } from "react-router";
import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useAPI } from "@/api/APIContext";
import ImportalCard from "@/shared-components/ImportalCard";
import ImportalPage from "@/shared-components/ImportalPage/ImportalPage";
import { User } from "common/interfaces/user";
import { ViewPhotos } from "@/shared-components/ViewPhotos/ViewPhotos";
import { LoadingStatus } from "@/components/data-import-loader/DataImportLoader";
import { Classification, ClassificationMessage, ClassificationPopulated, ClassificationStatus, ClassificationStatusColor, ClassificationStatusToShortText, convertPopulatedToClassification } from "common/interfaces/classification";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemText,
    TextField,
} from "@mui/material";
import { Document } from "common/interfaces/document";
import ProductCodeAutocomplete from "@/components/product-code-autocomplete/ProductCodeAutocomplete";
import { Code } from "common/interfaces/code";
import ImportalPrimaryButton from "@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton";
import { renderClassificationStatus } from "@/utils/classificationUtils";


export function BrokerClassificationPage() {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
    const [loadingText, setLoadingText] = useState('');
    const [successText, setSuccessText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [htsCode, setHTSCode] = useState<Code | undefined>();
    const api = useAPI();
    const match = useMatch({
        path: "/broker/dashboard/hts-classifications/:classificationId",
    });

    const styles = {
        classificationContainer: {
            display: 'flex',
            marginTop: '12px',
            gap: '32px',
            marginBottom: '24px',
            '& .MuiInputBase-root': {
                backgroundColor: 'white!important'
            },
        },
        classificationHeader: {
            fontWeight: '700',
            color: '#3B3E41',
            marginBottom: '10px',
            whiteSpace: 'nowrap',
        },
        classificationText: {
            color: '#3B3E41',
            fontWeight: '400',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center'
        },
    }


    const [classification, setClassification] = useState<ClassificationPopulated | undefined>();

    const [loading, setLoading] = useState(false)

    const [brokerUsers, setBrokerUsers] = useState<User[]>([])
    const [brokerUsersLoading, setBrokerUsersLoading] = useState(false)

    const validateClassification = (classification): boolean => {
        return true
    }

    const loadBrokerUsers = () => {
        setBrokerUsersLoading(true)
        api.getAllUsersWithBrokerPermissions()
            .then(({ data }) => {
                setBrokerUsers(data);
            })
            .catch(err => {
                console.error('error getting all users with broker permissions')
                console.error(err)
            })
            .finally(() => {
                setBrokerUsersLoading(false)
            })
    }

    useEffect(() => {
        loadBrokerUsers();
    }, [])

    useEffect(() => {
        if (htsCode) {
            setClassification({
                ...classification as ClassificationPopulated,
                finalHTSCode: htsCode.htsno
            })
        }
    }, [htsCode])

    const getClassification = async (classificationId: string) => {
        const classificationResponse = await api.getClassificationById(classificationId);
        const classification: ClassificationPopulated = classificationResponse.data;

        document.title = `Importal - ${classification.referenceNumber}`;
        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText('Successfully retrieved classification details');
        setClassification(classification);

    }

    const updateClassificationRequest = () => {
        setLoading(true)
        const completedClassification: ClassificationPopulated = {
            ...classification as ClassificationPopulated,
            status: ClassificationStatus.COMPLETED,
        };
        const classificationToUpdate: Classification = convertPopulatedToClassification(completedClassification);
        api.updateClassificationWithId(classification?._id.toString() as string, classificationToUpdate!)
            .then(({ data }) => {
                setClassification(data)
            })
            .catch((err) => {
                console.error('error updating classification in broker UI')
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updateClassificationField = (fieldName: string, newValue: any) => {
        const updatedClassification = { ...classification }
        updatedClassification[fieldName] = newValue
        setClassification(updatedClassification as ClassificationPopulated)
    }


    const updateMessageField = (newValue: string) => {
        setClassification({
            ...classification as ClassificationPopulated,
            messages: [...classification!.messages, {
                user: 'Broker:',
                message: newValue,
                timestamp: new Date().toISOString()
            }]
        })
    }

    useEffect(() => {
        const classificationId = match?.params.classificationId
        if (!classificationId) return

        getClassification(classificationId);
        return () => {
            document.title = 'Importal';
        };


    }, [])

    const ClassificationHeader = ({ classification: ClassificationPopulated }) => {
        return (
            <div style={{ display: 'flex' }}>
                <a style={{ color: 'inherit' }} target='_blank'
                    href={`/broker/dashboard/businesses/${classification?.business._id}`}>{classification?.business.name}</a>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <KeyboardArrowRightIcon />
                </div>
                {classification?.referenceNumber}
            </div>
        )

    }


    const handleAutocompleteEvent = (code: Code | null) => {
        setHTSCode(code as Code);
    };

    interface PhotoCardSubtitleProps {
        documents: Document[];
    }

    const PhotoCardSubtitle: React.FC<PhotoCardSubtitleProps> = ({ documents }) => {
        return (
            <>
                {documents.length > 0
                    ? `${classification?.user.firstName} from ${classification?.business.name} uploaded ${documents.length} photos`
                    : "No photos have been uploaded yet."}
            </>
        );
    };

    interface MessageCardSubtitleProps {
        messages: ClassificationMessage[];
    }

    const MessageCardSubtitle: React.FC<MessageCardSubtitleProps> = ({ messages }) => {
        return (
            <>
                {messages.length > 0
                    ? `Messages from ${messages.length} user${messages.length > 1 ? 's' : ''}:`
                    : "No messages have been sent yet."}
                <ul>
                    {messages.map((msg, index) => (
                        <li key={index}>
                            <strong>{msg.user}:</strong> {msg.message}
                        </li>
                    ))}
                </ul>
            </>
        );
    };


    return (
        <>
            {classification &&
                <>
                    <ImportalPage
                        header={<ClassificationHeader classification={classification} />}
                        subheader="View classification request summary and details"
                        loadingStatus={loadingStatus}
                        loadingText={loadingText}
                        successText={successText}
                        errorText={errorText}
                    >
                        <div>
                            <ImportalCard title='Summary'>
                                <>
                                    {loading &&
                                        <Box style={{
                                            height: '400px',
                                            flexGrow: '1',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <CircularProgress size={60} color='primary' />
                                        </Box>
                                    }
                                    {(!loading && classification) &&
                                        <>
                                            <div style={styles.classificationContainer}>
                                                <div style={{ flex: '1' }}>
                                                    <div style={styles.classificationHeader}>Description</div>
                                                    <div style={styles.classificationText}>{classification.description || 'No information provided.'}</div>
                                                </div>
                                                <div style={{ flex: '1' }}>
                                                    <div style={styles.classificationHeader}>Material Composition</div>
                                                    <div style={styles.classificationText}>{classification.materialComposition || 'No information provided.'}</div>
                                                </div>

                                                <div style={{ flex: '1' }}>
                                                    <div style={styles.classificationHeader}>Intended Use</div>
                                                    <div style={styles.classificationText}>{classification.intendedUse || 'No information provided.'}</div>
                                                </div>

                                                <div style={{ flex: '1' }}>
                                                    <div style={styles.classificationHeader}>Submitted By</div>
                                                    <div style={styles.classificationText}>{classification.user.firstName} {classification.user.lastName}</div>
                                                </div>
                                                <div style={{ flex: '1' }}>
                                                    <div style={styles.classificationHeader}>Status</div>
                                                    <div style={styles.classificationText}>{renderClassificationStatus(classification.status)}</div>
                                                </div>
                                                <div style={{ flex: '4' }}>
                                                    <div style={styles.classificationHeader}>HTS Classification</div>
                                                    {classification.status === ClassificationStatus.COMPLETED ? (
                                                        <div style={styles.classificationText}>
                                                            {classification.finalHTSCode}
                                                        </div>
                                                    ) : (
                                                        <ProductCodeAutocomplete size="small" selectedCode={htsCode} onCodeSelected={handleAutocompleteEvent} />
                                                    )}
                                                </div>
                                            </div>
                                            {classification.status !== ClassificationStatus.COMPLETED && <div style={{ display: 'flex', justifyContent: 'right', marginTop: '32px' }}>
                                                <ImportalPrimaryButton
                                                    disabled={!validateClassification(classification)}
                                                    onClick={updateClassificationRequest}
                                                    text="Save"
                                                />
                                            </div>}

                                        </>
                                    }


                                </>
                            </ImportalCard>
                        </div>
                        <div style={{ display: 'flex', gap: '4px', width: '100%', marginTop: '4px', alignItems: 'stretch' }}>
                            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <ImportalCard
                                    title="Photos"
                                    subtitle={<PhotoCardSubtitle documents={classification.documents} />}
                                    style={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '300px' }}
                                >
                                    {(classification.documents && classification.documents?.length > 0) &&
                                        <div style={{ display: 'flex', marginTop: '6px', height: '100%' }}>
                                            <ViewPhotos documentIds={classification.documents.map(doc => doc._id)} />
                                        </div>
                                    }
                                </ImportalCard>
                            </div>
                            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <ImportalCard
                                    title="Messages"
                                    subtitle={<MessageCardSubtitle messages={classification.messages} />}
                                    style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '300px' }}
                                >
                                    <div className="no-results-container" style={{ flex: 1 }}>
                                        <i>This feature is coming soon...</i>
                                    </div>
                                </ImportalCard>
                            </div>
                        </div>
                    </ImportalPage>
                </>

            }
        </>
    )

}
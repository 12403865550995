import React, { CSSProperties, useMemo } from 'react';
import { Product } from 'common/interfaces/product';
import { ProductField } from 'common/interfaces/productfields';
import { ColumnConfig } from 'common/interfaces/importalTable';
import { TableCell, TableRow } from '@mui/material';
import ImportalTable from '@/shared-components/ImportalTable/ImportalTable';

interface ProductPreviewTableProps {
    products: Product[];
    productFields?: ProductField[],
    retryMappingButton?: React.JSX.Element
}

export default function UploadProductsPreview({
    products,
    productFields = [],
    retryMappingButton
}: ProductPreviewTableProps) {

    // Map product fields to column config
    const columnConfig: ColumnConfig[] = useMemo(() => {
        return productFields.map(field => ({
            name: field.name,
            displayName: field.name,
            sortable: true,
            filterable: true,
            dataType: 'string',
        }));
    }, [productFields]);

    // Row generator function to map product data to table rows
    const rowGenerator = (product: Product, index: number) => {
        return (
            <TableRow key={index}>
                {columnConfig.map((column) => (
                    <TableCell key={column.name}>
                        {typeof product.properties[column.name] === 'string'
                            ? product.properties[column.name]
                            : JSON.stringify(product.properties[column.name])}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    return (
        <ImportalTable
            data={products}
            rowGenerator={rowGenerator}
            columnConfig={columnConfig}
            useSearch={false}
            topRightButton={retryMappingButton}
            hasRowExpand={false}
        />
    );
}



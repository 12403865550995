import React from "react";
import {Button, TextField, Typography,} from "@mui/material";
import "./WhatAreYouImporting.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
    description?: string,
    onDescriptionChanged: (string) => void,
    materialComposition?: string,
    onMaterialCompositionChanged: (string) => void,
    onNextClicked: () => void
}

export default function WhatAreYouImporting({
                                                description,
                                                onDescriptionChanged,
                                                materialComposition,
                                                onMaterialCompositionChanged,
                                                onNextClicked
                                            }: Props) {

    return (
        <div className="card-container">
            <div>
                <div className="importing-card-container">
                    <div className="importing-header-question">
                        <Typography>
                            What are you importing?
                        </Typography>
                    </div>
                    <div className="unlading-descriptive-text">
                        Give us a detailed description of what you're importing as well as the material composition.
                        We'll store this product information for your future use.
                    </div>
                    <div className="wayi-text-field">
                        <TextField
                            required
                            multiline
                            rows={3}
                            fullWidth
                            id="cargo-value"
                            label="Description"
                            value={description || ""}
                            onChange={(e) => {
                                onDescriptionChanged(e.target.value);
                            }}
                            name="cargo-value"
                        />
                    </div>
                    <div className="wayi-text-field">
                        <TextField
                            required
                            multiline
                            rows={3}
                            fullWidth
                            id="cargo-value"
                            label="Material Composition"
                            value={materialComposition || ""}
                            onChange={(e) => {
                                onMaterialCompositionChanged(e.target.value)
                            }}
                            name="cargo-value"
                        />
                    </div>
                    <div className="next-button-container">
                        <Button
                            type="submit"
                            fullWidth
                            className="next-button-green"
                            endIcon={<KeyboardArrowRightIcon/>}
                            onClick={onNextClicked}
                            variant="contained"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const FloatingTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    placement="bottom-end"
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "charcoal",
    fontSize: "13px",
    maxWidth: "375px",
  },
  [`& .${tooltipClasses.popper}`]: {
    zIndex: theme.zIndex.tooltip,
    position: "absolute",
  },
}));

export default FloatingTooltip;

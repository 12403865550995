import React, { useEffect, useMemo, useState } from 'react';
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Popover, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { ColumnConfig, ColumnFilterConfig } from "common/interfaces/importalTable";
import './TableColumnFilterConfigButtonAndPopover.css';


interface Props<T> {
    fieldConfig: ColumnConfig,
    data: T[],
    columnFilterConfig: ColumnFilterConfig,
    onFilterConfigChanged: (columnFiterConfig: ColumnFilterConfig) => void,
    triggerElement: React.JSX.Element
}

type Facets = Record<string, number>

export default function TableColumnFilterConfigButtonAndPopover<T>({
    fieldConfig,
    data,
    columnFilterConfig,
    onFilterConfigChanged,
    triggerElement
}: Props<T>) {

    const [filterText, setFilterText] = useState<string>('');

    const [facets, setFacets] = useState<Facets>({});

    const calculateFacets = () => {

        const newFacets: Facets = {};
        let transform = (val: any) => val
        if (fieldConfig.categorizer) {
            transform = fieldConfig.categorizer
        }

        data.forEach((datum) => {
            const fieldValue = transform(datum[fieldConfig.name]);
            if (fieldValue in newFacets) {
                newFacets[fieldValue]++;
            } else {
                newFacets[fieldValue] = 1;
            }
        });
        return newFacets;
    };

    const facetsMemo = useMemo(() => calculateFacets(), [data, fieldConfig]);
    useEffect(() => {
        setFacets(facetsMemo);
    }, [facetsMemo]);

    const onFacetSelectionChange = (key: string) => {

        const currentAllowedValues = columnFilterConfig?.allowedValues || []
        const newAllowedValues = [...currentAllowedValues];
        if (newAllowedValues.includes(key)) {
            const index = newAllowedValues.indexOf(key);
            newAllowedValues.splice(index, 1);
        } else {
            newAllowedValues.push(key);
        }
        onFilterConfigChanged({
            columnName: fieldConfig.name,
            allowedValues: newAllowedValues,
            categorizer: fieldConfig.categorizer
        });
    }

    const onClickResetFiltersButton = () => {

        setFilterText("")
        onFilterConfigChanged({
            columnName: fieldConfig.name,
            allowedValues: [],
            categorizer: fieldConfig.categorizer
        });
    }

    const filterFunc = (filterText: string) => {
        const filterTextLower = filterText.toLowerCase();
        return (word: string): boolean => {
            return word.toLowerCase().includes(filterTextLower);
        };
    };

    return <PopupState variant="popover" popupId="demoPopover" disableAutoFocus={false} parentPopupState={undefined}>
        {(popupState) => (
            <React.Fragment>

                {React.cloneElement(triggerElement, bindTrigger(popupState))}

                <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >

                    <div style={{border: '1px solid lightgrey', borderRadius: '16px'}}>
                        <div style={{ padding: '10px', margin: '4px', display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                size={"small"}
                                value={filterText || ""}
                                onChange={(event) => setFilterText(event.target.value)}
                            />
                        </div>
                        <div
                            style={{
                                padding: '16px',
                                overflowY: 'scroll',
                                maxHeight: '252px',
                                borderBottom: '1px solid #DCDCDC',
                                borderTop: '1px solid #DCDCDC', 
                            }}
                        >

                            {Object.keys(facets).sort(fieldConfig.columnFilterSortFunction).filter(filterFunc(filterText)).map((key) => (

                                <div key={key} style={{ display: 'flex' }}>
                                    <input
                                        key={key}
                                        id={`${fieldConfig.name}-facet-select-${key}`}
                                        type="checkbox"
                                        checked={(columnFilterConfig && columnFilterConfig.allowedValues.includes(key)) || false}
                                        onChange={() => onFacetSelectionChange(key)}>

                                    </input>
                                    <label
                                        htmlFor={`${fieldConfig.name}-facet-select-${key}`}
                                    >
                                        {key} ({facets[key]})
                                    </label>

                                </div>

                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '5px', padding: '6px' }}>
                            <Button variant={'contained'}
                                className="reset-filter-button"
                                onClick={onClickResetFiltersButton}
                                sx={{ textTransform: 'none', fontWeight: '600' }}
                            >
                                Reset
                            </Button>
                        </div>
                    </div>
                </Popover>
            </React.Fragment>
        )}
    </PopupState>
};
import React, {forwardRef} from 'react';

import ImportalTable, {ImportalTableHandles} from "@/shared-components/ImportalTable/ImportalTable";
import {columnConfigsFromData} from "common/utilities/importalTable";
import {ColumnConfig} from "common/interfaces/importalTable";


export interface Props {
    columnConfig?: Array<{ name: string; displayName: string; }>,
    data: any[]
}

const ReportTable = forwardRef<ImportalTableHandles, Props>(({
                                                                 data,
                                                                 columnConfig
                                                             }, ref) => {


    const addSortAndFilterToColumns = (columns: Array<{ name: string; displayName: string; dataType?: string }>): ColumnConfig[] => {
        return columns.map(column => ({
            ...column,
            dataType: column.dataType || 'string',
            sortable: true,
            filterable: true,
        }));

    }

    // @ts-ignore
    return <ImportalTable ref={ref} columnConfig={addSortAndFilterToColumns(columnConfig || columnConfigsFromData(data))}
                          data={data}
                          embeddedStyling={true}/>

});

const ReportTableRow = (rowData: any, index: number) => {
    return <div>ROW</div>
}


export default ReportTable;
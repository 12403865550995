import React, {useContext} from "react";
import {Button, Card, CardContent, Typography} from "@mui/material";
import "./ComplianceSummary.css";
import LightTooltip from "../tooltip-component/TooltipComponent";
import InfoIcon from "@mui/icons-material/Info";
import ReactHtmlParser from 'react-html-parser';
import PgaDescriptionModal from "@/pages/compliance-one/PGADescriptionModal";
import ADDModal from "@/pages/hdfs/ADDModal";
import CVDModal from "@/pages/hdfs/CVDModal";
import {CurrencyUtility} from 'common/utilities/currencyUtility';
import {ComplianceSummaryContext, CurrencyCode} from 'common/interfaces/complianceSummary';
import {useNavigate} from "react-router-dom";
import {useActivePlan} from "@/custom-hooks/plan/PlanProvider";
import {AppContext} from "@/App";
import {getCountryNameFromISOCode} from "common/utilities/countryIsoCodes";
import {
    renderDMSubtext,
    renderDutyMitigationRows,
    renderHDFSRows,
    renderHDFSSubtext
} from "@/utils/complianceSummaryRendering";
import {Code} from "common/interfaces/code";
import {UnitOfMeasureForQuantityAllowedValues} from "common/interfaces/productfields";

export interface Props {
    htsCode?: Code,
    complianceSummary: ComplianceSummaryContext,
    handleReset: () => void,
}

export default function ComplianceSummary({htsCode, complianceSummary, handleReset}: Props) {

    const {handleOpenPlansModal} = useContext(AppContext)

    const [pgaDescriptionModalOpen, setPgaDescriptionModalOpen] = React.useState(false);
    const [modalRow, setModalRow] = React.useState([]);
    const [ADDModalOpen, setADDModalOpen] = React.useState(false);
    const [CVDModalOpen, setCVDModalOpen] = React.useState(false);
    const formatTotalValue = (totalValue) => {
        let valueToReturn = parseFloat(totalValue).toLocaleString("en-US");
        return `$${valueToReturn}`;
    };
    const navigate = useNavigate();
    const activePlan = useActivePlan();

    const handleADDModalOpen = (row) => {
        setModalRow(row);
        setADDModalOpen(true);
    }

    const handleADDModalClose = () => {
        setADDModalOpen(false);
    }

    const handleCVDModalOpen = (row) => {
        setModalRow(row);
        setCVDModalOpen(true);
    }

    const handleCVDModalClose = () => {
        setCVDModalOpen(false);
    }

    const renderResults = (results) => {
        const renderedContent = results.map((content, index) => {
            if (content.includes('<i>') && !content.includes('<u>')) {
                // Check if the content contains <i> tags and render with italics
                return <i key={index}>{ReactHtmlParser(content)}</i>;
            } else if (content.includes('<u>') && !content.includes('<i>')) {
                return <u key={index}>{ReactHtmlParser(content)}</u>;
            } else if (content.includes('<u>') && content.includes('<i>')) {
                return <i><u key={index}>{ReactHtmlParser(content)}</u></i>;
            } else {
                // Render regular text as is
                return <span key={index}>{content}</span>;
            }
        });

        return renderedContent;
    };

    const onClickSearchAnotherProduct = (e) => {

        if (activePlan.hasAvailableSearches) {
            handleReset();
        } else {
            handleOpenPlansModal()
        }
    };

    const renderHTML = (inputString) => {
        const startIndex = '<i>';
        const endIndex = '</i>';

        const results: any = [];
        let currentIndex = 0;

        while (currentIndex < inputString.length) {
            const iStartIndex = inputString.indexOf(startIndex, currentIndex);
            if (iStartIndex === -1) {
                break;
            }

            const iEndIndex = inputString.indexOf(endIndex, iStartIndex);
            if (iEndIndex === -1) {
                break;
            }

            results.push(inputString.substring(currentIndex, iStartIndex)); // Text before <i>
            results.push(inputString.substring(iStartIndex, iEndIndex + endIndex.length)); // Include <i> and </i> tags with content

            currentIndex = iEndIndex + endIndex.length;
        }

        // Add the remaining text (if any) after the last </i> tag
        if (currentIndex < inputString.length) {
            results.push(inputString.substring(currentIndex));
        }
        return renderResults(results);
    };


    const handleBackToDashboard = (e) => {
        navigate("/dashboard");
    }

    const handlePgaDescriptionModalOpen = (row) => {
        setModalRow(row);
        setPgaDescriptionModalOpen(true);
    }

    const handlePgaDescriptionModalClose = () => {
        setPgaDescriptionModalOpen(false);
    }

    const renderDutyCalculatorRows = (dutyCalculatorOutput: any) => {
        let dutyCalculatorRowsToReturn: any = [];
        dutyCalculatorRowsToReturn.push(
            <div className="breakdown-container-header" key="headre-duty-title-pga-one">
                <div className="duty-calculation-type">Type</div>
                <div className="duty-calculation-rate">Rate</div>
                <div className="duty-calculation-amount">Amount</div>
            </div>
        )
        dutyCalculatorOutput.appliedDuties.forEach((duty, index) => {
            dutyCalculatorRowsToReturn.push(
                <div className="breakdown-container-row" key={index}>
                    <div className="duty-calculation-type">
                        {duty.description}
                    </div>
                    <div className="duty-calculation-rate">{duty.rateString}</div>
                    <div className="duty-calculation-amount">
                        {CurrencyUtility.formatCurrency(duty.amount, CurrencyCode.USD)}
                    </div>
                </div>
            )
        });
        dutyCalculatorRowsToReturn.push(
            <div className="breakdown-container-row-end">
                <div className="duty-calculation-type">
                    <b>Total Duties and Fees Owed</b>
                </div>
                <div className="duty-calculation-rate"></div>
                <div className="duty-calculation-amount">
                    <b>{CurrencyUtility.formatCurrency(dutyCalculatorOutput.totalDuties, CurrencyCode.USD)}</b>
                </div>
            </div>
        )
        return dutyCalculatorRowsToReturn;
    }

    const renderPGARows = (currentComplianceOneSummary) => {
        let pgaRowsToReturn: any = [];
        if (currentComplianceOneSummary.partneringGovernmentAgencyOutput.flags.length > 0) {
            pgaRowsToReturn = [
                <div className="my-shipments-subheader compliance-summary-subheader">
                    Your product currently
                    has {currentComplianceOneSummary.partneringGovernmentAgencyOutput.flags.length} flags by United
                    States government agencies.
                </div>
            ];
            pgaRowsToReturn.push(
                <div className="breakdown-container-header" key="headre-duty-title-pga-one">
                    <div className="compliance-summary-pga-flag">PGA Flag</div>
                    <div className="compliance-summary-agency-name">Agency Name</div>
                    <div className="compliance-summary-agency-description">Description from Agency</div>
                    <div className="agency-links">License Link</div>
                </div>
            );
            currentComplianceOneSummary.partneringGovernmentAgencyOutput.flags.forEach((row: any, index: number) => {
                pgaRowsToReturn.push(
                    <div className="breakdown-container-row" key={index}>
                        <div className="compliance-summary-pga-flag">{row.PGA_Flag}</div>
                        <div className="compliance-summary-agency-name">
                            <LightTooltip title={row["PGA Agency Name"]}>
                                {row["PGA Agency"]}
                            </LightTooltip>
                        </div>

                        <div className="compliance-summary-agency-description">
                            {row["PGA Description"]}
                            <LightTooltip title="Click here for a detailed breakdown of the flag">
                                <div className="tooltip-summary-container">
                                    <InfoIcon className="pga-info-icon"
                                              onClick={() => handlePgaDescriptionModalOpen(row)}
                                              sx={{fontSize: "14px"}}/>
                                </div>
                            </LightTooltip>
                        </div>
                        {row["License/Permit Link"] ?
                            <div className="agency-links">
                                <a href={row["License/Permit Link"]} target="_blank" style={{textDecoration: 'none'}}
                                   rel="noreferrer">{row["PGA Agency"]} Link</a>
                            </div>
                            :
                            <></>
                        }
                    </div>
                );
            });
        } else {
            pgaRowsToReturn = [
                <div className="my-shipments-subheader compliance-summary-subheader">
                    Your product is not currently flagged by any United States agencies.
                </div>
            ];
        }

        return pgaRowsToReturn;
    };

    return (
        <div className="card-container">
            <Card className="summary-card-container" sx={{minWidth: 275}}>
                <CardContent>
                    <div className="summary-primary-header">
                        <div>
                            <div className="header-question">
                                <Typography>Compliance Summary</Typography>
                            </div>
                            <div style={{display: 'flex', gap: '40px'}}
                                 className="my-shipments-subheader compliance-summary-subheader">
                                <div className="header-question">
                                    <div style={{fontWeight: '600', minWidth: '106px'}} className="value-fees">Cargo
                                        Value
                                    </div>
                                    <div
                                        className="value-fees">{(complianceSummary && complianceSummary.input.totals?.totalValue) ? formatTotalValue(complianceSummary.input.totals?.totalValue) : ""} </div>
                                </div>
                                <div className="header-question">
                                    <div style={{fontWeight: '600', minWidth: '151px'}} className="value-fees">Country
                                        of Origin
                                    </div>
                                    <div
                                        className="value-fees">{getCountryNameFromISOCode(complianceSummary.input.countryOfOrigin)} </div>
                                </div>
                                <div className="header-question">
                                    <div style={{fontWeight: '600'}} className="value-fees">HTS Code</div>
                                    <div
                                        className="value-fees">{complianceSummary.input.htsno}</div>
                                </div>
                                <div className="header-question">
                                    <div style={{fontWeight: '600'}} className="value-fees">Description</div>
                                    <div
                                        className="value-fees">{htsCode?.htsSentence} </div>
                                </div>
                                {complianceSummary.input.totals?.totalWeight &&
                                    <div className="header-question">
                                        <div style={{fontWeight: '600'}} className="value-fees">Weight</div>
                                        <div
                                            className="value-fees-dc">{complianceSummary.input.totals?.totalWeight.toString()} kgs
                                        </div>
                                    </div>
                                }
                                {(complianceSummary.input.totals?.totalUnits && complianceSummary.input.totals?.totalUnitsMeasureOfQuantity !== UnitOfMeasureForQuantityAllowedValues.Individual) &&
                                    <div className="header-question">
                                        <div style={{fontWeight: '600'}} className="value-fees">Quantity</div>
                                        <div
                                            className="value-fees-dc">{complianceSummary.input.totals?.totalUnits.toString()} {complianceSummary.input.totals.totalUnitsMeasureOfQuantity}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card className="summary-card-container animate delay-1">
                <CardContent>
                    <div className="header-question">
                        <Typography>Duty Calculation</Typography>
                    </div>
                    <div className="my-shipments-subheader compliance-summary-subheader">
                        Your product currently
                        has {complianceSummary.dutyCalculationOutput?.appliedDuties.length} different types of
                        duties and fees associated with it.
                    </div>
                    {renderDutyCalculatorRows(complianceSummary.dutyCalculationOutput)}
                </CardContent>
            </Card>
            <Card className="summary-card-container animate delay-2">
                <CardContent>
                    <div className="header-question">
                        <Typography>Partner Government Agency Search</Typography>
                    </div>
                    {(complianceSummary && complianceSummary.partneringGovernmentAgencyOutput?.flags) ? renderPGARows(complianceSummary) : ""}
                </CardContent>
            </Card>
            <Card className="summary-card-container animate delay-3">
                <CardContent>
                    <div className="header-question">
                        <Typography>Hidden Duties and Fees Search</Typography>
                    </div>
                    <div className="my-shipments-subheader compliance-summary-subheader">
                        {renderHDFSSubtext(complianceSummary.hiddenDutiesAndFeesOutput)}
                    </div>
                    {renderHDFSRows(complianceSummary.hiddenDutiesAndFeesOutput, handleADDModalOpen, handleCVDModalOpen)}
                </CardContent>
            </Card>
            <Card className="summary-card-container animate delay-4">
                <CardContent>
                    <div className="header-question">
                        <Typography>Duty Mitigation</Typography>
                    </div>
                    <div className="my-shipments-subheader compliance-summary-subheader">
                        {renderDMSubtext(complianceSummary.dutyMitigationOutput)}
                    </div>
                    {renderDutyMitigationRows(complianceSummary.dutyMitigationOutput)}
                </CardContent>
            </Card>
            <div className="next-button-container">
                <Button
                    fullWidth
                    className="back-button-text"
                    variant="text"
                    sx={{minWidth: '250px'}}
                    onClick={onClickSearchAnotherProduct}
                >
                    Search for another product
                </Button>
                <Button
                    onClick={handleBackToDashboard}
                    fullWidth
                    variant="contained"
                    className="next-button-green"
                    sx={{minWidth: '200px'}}
                >
                    Back to Dashboard
                </Button>
            </div>
            <PgaDescriptionModal open={pgaDescriptionModalOpen} row={modalRow}
                                 onClose={handlePgaDescriptionModalClose}/>
            <ADDModal open={ADDModalOpen} row={modalRow} onClose={handleADDModalClose}/>
            <CVDModal open={CVDModalOpen} row={modalRow} onClose={handleCVDModalClose}/>
        </div>
    );
}

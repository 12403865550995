import React from "react";
import {Report} from 'common/interfaces/reports';
import Link from "@mui/material/Link";
import ImportalSimpleTable from "@/shared-components/ImportalSimpleTable";
import {useTheme} from "@mui/material";
import {mmmddyyHmmDate} from "@/utils/activePlanUtils";

export interface Props {
    reports?: Report[],
    onReportSelected: (report?: Report) => void
}

export default function ReportList({reports, onReportSelected}: Props) {

    const theme = useTheme();

    return <>
        <ImportalSimpleTable
            data={reports || []}
            columns={['Report Name', 'State', 'Last Run']}
            renderListItem={(datum: Report, index) =>

                <>
                    <Link sx={{textDecoration: 'none', cursor: 'pointer'}} component="a" onClick={(e) => {
                        e.preventDefault();
                        onReportSelected(datum);
                    }}>
                        <span>{datum.name || "Report"}</span>
                    </Link>
                    <span>{datum.state}</span>
                    <span>{mmmddyyHmmDate(datum.lastRun?.toString())}</span>
                </>

            }
        />

    </>

}
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Paper } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import "./ShipmentTable.css"
import ImportalTable from "@/shared-components/ImportalTable/ImportalTable";
import { ColumnConfig } from 'common/interfaces/importalTable';

const capitalizeAllWords = (str: string) => {
    if (str) {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
            return match.toUpperCase();
        });
    } else {
        return "";
    }

}

function createData(
    {
        refNumber,
        mbl,
        hbl,
        isfOnFile,
        customsCleared,
        notes
    }: any,
    Id: number
) {

    return {
        Id,
        refNumber,
        mbl,
        hbl,
        isfOnFile,
        customsCleared,
        notes,
        status: () => (
            <Chip
                label={capitalizeAllWords(notes)}
                sx={{
                    backgroundColor: "#77A8F6",
                    color: "white",
                    fontWeight: "bold",
                    marginRight: "6px",
                    width: "150px",

                }}
            />
        ),
    };
}

const Row = (props: any) => {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography> {row.refNumber} </Typography>
                </TableCell>
                <TableCell>
                    <Typography>{row.mbl ? row.mbl : ""}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{row.hbl ? row.hbl : ""}</Typography>
                </TableCell>
                <TableCell>
                    {row.isfOnFile ?
                        <CheckCircleIcon style={{ color: 'green' }} />
                        : <CloseIcon style={{ color: 'red' }} />}
                </TableCell>
                <TableCell>
                    <Typography>{row.status()}</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open}>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="right">Comments</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="A">
                                        <TableCell component="th" scope="row">
                                            {new Date(row.UpdatedDate).toLocaleString()}
                                        </TableCell>
                                        <TableCell>Customs clearance requested - submitted to Importal</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            {/* )} */}
        </React.Fragment>
    );
}

const shipmentTableColumns: Array<ColumnConfig> = [
    {
        displayName: 'Reference No.',
        name: 'referenceNumber',
        dataType: 'string',
        sortable: true,
        filterable: true
    },
    {
        displayName: 'Master Bill',
        name: 'mbl',
        dataType: 'string',
        sortable: true,
        filterable: true
    },
    {
        displayName: 'House Bill',
        name: 'hbl',
        dataType: 'string',
        sortable: true,
        filterable: true
    },
    {
        displayName: 'ISF On File',
        name: 'isfOnFile',
        dataType: 'boolean',
        sortable: true,
        filterable: true,
        categorizer: (value: string | boolean) => {
            switch (value) {
                case 'true':
                    return 'true'
                case 'false':
                    return 'false'
                case true:
                    return 'true'
                case false:
                    return 'false'
                default:
                    return ''
            }
        }
    },
    // {
    //     displayName: 'Customs Cleared',
    //     name: 'customsCleared',
    //     dataType: 'boolean',
    //     sortable: true,
    //     filterable: true,
    //     categorizer: (value: string | boolean) => {
    //         switch (value) {
    //             case true:
    //                 return 'true'
    //             case false:
    //                 return 'false'
    //             default:
    //                 return ''
    //         }
    //     }
    // },
    // {
    //     displayName: 'Status',
    //     name: 'internalStatus',
    //     dataType: 'string',
    //     sortable: true,
    //     filterable: true,
    //     categorizer: capitalizeAllWords
    // },
]

const ShipmentTable = ({ rows, isLoading }) => {

    return (
        <ImportalTable
            data={rows}
            columnConfig={shipmentTableColumns}
            loading={isLoading}
        />

    );
}
export default ShipmentTable

import {useMatch} from "react-router";
import React, {useEffect, useState} from "react";
import {Classification, ClassificationPopulated} from "common/interfaces/classification";
import {Typography, useTheme} from "@mui/material";
import {useAPI} from "@/api/APIContext";
import ImportalCard from "@/shared-components/ImportalCard";
import {ConsumerViewClassification} from "@/shared-components/Classification/ConsumerViewClassification";


export function ConsumerClassificationPage() {

    const theme = useTheme();
    const api = useAPI();
    const match = useMatch({
        path: "/dashboard/hts-classifications/:classificationId",
    });

    const [classification, setClassification] = useState<ClassificationPopulated | undefined>()

    useEffect(() => {
        const classificationId = match?.params.classificationId
        if (!classificationId) return

        api.getClassificationById(classificationId)
            .then(({data}) => {
                setClassification(data)
            })
            .catch(err => {
                console.error('error getting classification for consumer')
                console.error(err)
            })
            .finally(() => {

            })


    }, [])


    return <>

        {classification &&
            <>
                <div className='product-library-header'>
                    <div className="business-header-text">
                        <div style={{display: 'flex'}}>
                            <Typography sx={{color: theme.palette.primary.main, fontSize: "26px", display: 'flex'}}>
                                {classification?.referenceNumber}
                            </Typography>

                        </div>
                        <div style={{marginTop: '0px', marginBottom: "0px"}} className="universal-subheader">
                            View classification and entry details and query cargo manifests
                        </div>
                    </div>
                </div>

                <br/>
                <br/>
                <ImportalCard title={classification.referenceNumber!}>
                    <ConsumerViewClassification classificationRequestId={classification._id!.toString()}/>
                </ImportalCard>


            </>

        }


    </>

}
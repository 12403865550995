import {Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAPI} from "@/api/APIContext";
import {BusinessUser} from 'common/interfaces/business';
import {useActiveBusiness} from "@/custom-hooks/business/BusinessProvider";
import {useActivePlan} from "@/custom-hooks/plan/PlanProvider";

export default function PlanAdmin() {

    const api = useAPI();
    const activeBusiness = useActiveBusiness();
    const activePlan = useActivePlan();

    const [planMembers, setPlanMembers] = useState<BusinessUser[]>([])

    useEffect(() => {

        if (activeBusiness.business && activeBusiness.business._id) {
            api.getBusinessUsers(activeBusiness.business._id.toString())
                .then(({data: users}) => {
                    setPlanMembers(users)
                })
                .catch(err => {
                    console.error('error getting active business users for PlanAdmin')
                })
        }


    }, [activeBusiness]);

    return (
        <Card style={{marginTop: '0.5rem', marginBottom: '5px'}}>
            <CardContent>
                <div className="header-question">
                    <Typography>Plan Admin</Typography>
                </div>
                <div className="settings-profile-main-content">
                    { planMembers &&
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {planMembers.map((member) => (
                                        <TableRow key={member._id!.toString()}>
                                            <TableCell>{member.email}</TableCell>
                                            <TableCell>{activePlan.metadata?.planAdmins?.includes(member._id!.toString()) ? "Admin" : "User"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
            </CardContent>
        </Card>
    );
}
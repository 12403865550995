import React, { ReactNode, useState } from 'react';
import { Typography, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

interface ImportalPageProps {
    header: ReactNode;
    subheader: string;
    children?: ReactNode;
    rightButton?: ReactNode;
    loadingStatus: LoadingStatus;
    loadingText: string;
    successText: string;
    errorText: string
}

const ImportalPage: React.FC<ImportalPageProps> = ({
    header,
    subheader,
    children,
    rightButton,
    loadingStatus,
    loadingText,
    successText,
    errorText }) => {
    
        const theme = useTheme();


    return (

        <>
            <div className='product-library-header'>
                <div className="business-header-text">
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: "26px" }}>
                        {header}
                    </Typography>
                    <div className="universal-subheader">
                        {subheader}
                    </div>
                </div>
                <div className="create-shipment-button-container">
                    <div>
                        {rightButton}
                    </div>
                </div>
            </div>
            {children}
            <DataImportLoader
                loadingState={{ loadingStatus }}
                loadingText={loadingText}
                successText={successText}
                errorText={errorText}
            />
        </>

    );
};

export default ImportalPage;

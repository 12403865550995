import {Breadcrumbs, Typography} from '@mui/material';
import React, {useState} from 'react';
import Link from "@mui/material/Link";
import {NavigateNext} from "@mui/icons-material";

type BreadCrumbTextGenerator = (item: any) => string

interface BreadcrumbItem {
    label: string | BreadCrumbTextGenerator;
    render: ({popItem, pushItem, item}) => React.JSX.Element
}

interface Props {
    showCrumbAtBaseLevel?: boolean,
    elements: BreadcrumbItem[];
}

export const ImportalBreadCrumbs = ({showCrumbAtBaseLevel = false, elements}: Props) => {
    const [breadcrumbTrail, setBreadcrumbTrail] = useState<any[]>([{}]);

    // Method to push an item to the breadcrumb trail
    const pushItem = (item: any) => {
        setBreadcrumbTrail([...breadcrumbTrail, item]);
    };

    // Method to pop an item from the breadcrumb trail
    const popItem = () => {
        setBreadcrumbTrail(breadcrumbTrail.slice(0, -1));
    };


    const getActiveBreadCrumbElement = (breadcrumbTrail: any[]) => {
        const item = breadcrumbTrail[breadcrumbTrail.length - 1];
        const element = elements[breadcrumbTrail.length - 1]
        return element.render({popItem, pushItem, item})
    }

    return (
        <>
            {(elements && elements.length) &&
                <>

                    {(showCrumbAtBaseLevel || breadcrumbTrail.length > 1) &&
                        <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
                            {breadcrumbTrail.map((item, index) => {
                                const {label} = elements[index];
                                const labelText = typeof label === 'function' ? label(item) : label;

                                const isLastItem = index === breadcrumbTrail.length - 1;
                                return isLastItem ? (
                                    <Typography color="text.primary" key={index}>
                                        {labelText}
                                    </Typography>
                                ) : (
                                    <Link
                                        id={index + '-bread-crumb-link'}
                                        underline="hover"
                                        href={item.href}
                                        key={index}
                                        onClick={() => popItem()}>
                                        {labelText}
                                    </Link>
                                );
                            })}
                        </Breadcrumbs>
                    }


                    {getActiveBreadCrumbElement(breadcrumbTrail)}

                </>
            }
        </>
    )
        ;
};

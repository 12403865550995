import {Container} from "@mui/material";
import ImportalCard from "@/shared-components/ImportalCard";
import SummaryMetrics from "@/pages/reports/SummaryMetrics";


export default function Summary() {

    return <>
        <Container maxWidth={'xl'}>
            <SummaryMetrics/>
        </Container>
    </>
}


import React, { useEffect, useMemo } from 'react';
import './CardPlan.css';
import { Button, Paper, Typography } from '@mui/material';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import CheckIcon from '@mui/icons-material/Check';
import { OfferedPurchaseAny } from 'common/interfaces/plan';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';

export interface Props {
  offeredPurchase: OfferedPurchaseAny;
  onSelected: (arg0: OfferedPurchaseAny) => void;
}

const CardPlan = ({ offeredPurchase, onSelected }: Props) => {
  const activePlan = useActivePlan();
  const isTrialingPlan = activePlan.plan.status === 'trialing';
  const isEnterprisePlan = offeredPurchase.displayTitle === 'Enterprise';
  const isFreePlan = offeredPurchase.displayTitle === 'Free';

  const isCurrentPlan = useMemo(
    () => offeredPurchase.displayTitle === activePlan.plan.title,
    [offeredPurchase.displayTitle, activePlan.plan.title]
  );

  const renderDescriptionWithIcons = () => {
    const parts = offeredPurchase.descriptionHTML.split('✅');
    return (
      <>
        {parts.map((part, index) => (
          <div key={index} className='plan-list-item-container'>
            {index > 0 && (
              <div className='check-container'>
                <CheckIcon sx={{ color: '#388E3C' }} />
              </div>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: part.replace(/\n/g, '<br />'),
              }}
            />
          </div>
        ))}
      </>
    );
  };

  const renderButton = () => {
    if (isCurrentPlan) {
      if (activePlan.plan.status !== 'trialing') {
        return <div className="current-plan-text">Current Plan</div>;
      }
  
      return (
        <ImportalPrimaryButton
          onClick={() => onSelected(offeredPurchase)}
          text="Select"
          style={{ fontSize: '13px', width: '90px' }}
        />
      );
    }
  
    const isEnterprise = offeredPurchase.displayTitle === 'Enterprise';
    const isFree = offeredPurchase.displayTitle === 'Free';
  
    if (!isFree && !isEnterprise) {
      return (
        <ImportalPrimaryButton
          onClick={() => onSelected(offeredPurchase)}
          text="Select"
          style={{ fontSize: '13px', width: '80px' }}
        />
      );
    }
  
    if (isEnterprise) {
      return (
        <ImportalPrimaryButton
          onClick={() => onSelected(offeredPurchase)}
          text="Contact Us"
          style={{ fontSize: '13px', width: '120px', fontWeight: '700' }}
        />
      );
    }
  
    return null; // Handle any remaining cases if necessary
  };
  

  return (
    <Paper className='vmcr-card'>
      <div className='modal-content'>
        <div style={{ fontWeight: '700', fontSize: '18px', color: '#525256'  }}>{offeredPurchase.displayTitle}</div>
        <div style={{ color: 'grey', minHeight: '28px' }}>
          {offeredPurchase.advertisedOneTimePrice && (
            <Typography variant='subtitle1'>
              ${offeredPurchase.advertisedOneTimePrice}
            </Typography>
          )}
          {offeredPurchase.advertisedMonthlyPrice && (
            <Typography variant='subtitle1'>
              ${offeredPurchase.advertisedMonthlyPrice}/month
            </Typography>
          )}
          {offeredPurchase.advertisedYearlyPrice && (
            <Typography variant='subtitle1'>
              ${offeredPurchase.advertisedYearlyPrice}/year
            </Typography>
          )}
        </div>
        <div style={{color: 'grey'}}>
          {renderDescriptionWithIcons()}
        </div>
      </div>
      <div className='vmcr-pricing-button-container'>{renderButton()}</div>
    </Paper>
  );
};

export default CardPlan;

import React, {useState} from "react";
import {Box, Button, CircularProgress, Typography, useTheme} from "@mui/material";
import {Check} from "@mui/icons-material"
import DescriptionIcon from "@mui/icons-material/Description";
import {styled} from "@mui/system";
import './ImportDocument.css';
import {useAPI} from "@/api/APIContext";
import {Document, DocumentType} from "common/interfaces/document";

const Input = styled("input")({
    display: "none",
});

interface Props {
    customsClearanceDocuments?: Document[],
    onSetCustomsClearanceDocuments?: (customsClearanceDocuments?: Document[]) => void,
    onSubmit: () => void,
}

const ImportDocument = ({customsClearanceDocuments, onSetCustomsClearanceDocuments, onSubmit}: Props) => {

    const api = useAPI();
    const theme = useTheme();
    const [loading, setLoading] = useState<{
        "COMMERCIAL_INVOICE": boolean,
        "PACKING_LIST": boolean,
        "BILL_OF_LADING": boolean,
    }>({
        BILL_OF_LADING: false, COMMERCIAL_INVOICE: false, PACKING_LIST: false,
    })

    const handleFileUpload = (type: DocumentType) => (e) => {

        if (!e.target.files) {
            return;
        }
        const file: any = e.target.files[0];
        setLoading({...loading, [type]: true})

        const newDocument: Document = {
            type
        } as Document

        api.createDocument(newDocument, file)
            .then(({data}) => {
                console.log('customs clearance docs')
                console.log(data)
                // onSetCustomsClearanceDocuments([])
            })
            .catch(err => {
                console.error('error uploading file with name:', type)
                console.error(err)
            })
            .finally(() => {
                setLoading({...loading, [type]: false})
            })

    }

    const renderButtonContent = (fileName: string, label: string) => {
        if (loading[fileName]) {
            return <>{label} <CircularProgress color={'inherit'} size={18}/></>
        } else if (customsClearanceDocuments && customsClearanceDocuments[fileName]) {
            return <>{label} <Check/></>
        } else {
            return label;
        }
    }

    return (
        <Box>
            <div className="header-question">
                <Typography>
                    Upload your documents
                </Typography>
            </div>
            <div className="unlading-descriptive-text">
                <p><strong>Optional:</strong> feel free to upload any of these documents you have on hand. If you don't
                    have them, no worries just hit submit and we'll get them later.</p>
            </div>
            <div className="import-doc-button-container">
                <Button
                    sx={{minWidth: '250px'}}
                    startIcon={<DescriptionIcon/>}
                    className="next-button-green"
                    component={'label'}
                >
                    {renderButtonContent('commercialInvoice', 'Commercial Invoice')}
                    <Input
                        type="file"
                        accept="*"
                        hidden
                        multiple
                        onChange={handleFileUpload(DocumentType.COMMERCIAL_INVOICE)}
                    />
                </Button>
            </div>
            <div className="import-doc-button-container">
                <Button
                    sx={{minWidth: '250px'}}
                    startIcon={<DescriptionIcon/>}
                    className="next-button-green"
                    component={'label'}
                >
                    {renderButtonContent('packingList', 'Packing List')}
                    <Input
                        type="file"
                        accept="*"
                        hidden
                        multiple
                        onChange={handleFileUpload(DocumentType.PACKING_LIST)}
                    />
                </Button>
            </div>
            <div className="import-doc-button-container">
                <Button
                    sx={{minWidth: '250px'}}
                    startIcon={<DescriptionIcon/>}
                    className="next-button-green"
                    component={'label'}

                >
                    {renderButtonContent('billOfLading', 'Bill of Lading')}
                    <Input
                        type="file"
                        accept="*"
                        hidden
                        multiple
                        onChange={handleFileUpload(DocumentType.BILL_OF_LADING)}

                    />
                </Button>
            </div>
            <div className="next-button-container">
                <Button
                    type="submit"
                    onClick={onSubmit}
                    fullWidth
                    className="next-button-green"
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </Box>
    );
};

export default ImportDocument;
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Dialog,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    styled,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Add from "@mui/icons-material/Add";
import './CreateShipmentModal.css';

import {useAPI} from "@/api/APIContext";
import {Container, ShipmentTransportationType, ShipmentWithDocuments} from "common/interfaces/shipment";
import {BusinessWithDocuments} from "common/interfaces/business";
import {useNavigate} from "react-router";
import {LoadingStatus} from "@/components/data-import-loader/DataImportLoader";
import {Document, shipmentCreationFilter} from "common/interfaces/document";
import ManageDocuments from "@/shared-components/ManageDocuments/ManageDocuments";
import ManageContainers from "@/shared-components/ManageContainers/ManageContainers";
import {ShipmentsContext} from "./Shipments";

export interface CreateShipmentModalProps {
    open: boolean;
    onClose: () => void;
    latestReferenceNumber: string;
}

const Input = styled("input")({
    display: "none",
});

export default function CreateShipmentModal({onClose, open, latestReferenceNumber}: CreateShipmentModalProps) {

    const api = useAPI();
    const {setLoadingStatus, setLoadingText, setSuccessText, setErrorText} = useContext(ShipmentsContext)
    const [businesses, setBusinesses] = useState<BusinessWithDocuments[]>([]);
    const [businessInput, setBusinessInput] = useState<string>('');
    const navigate = useNavigate();
    const [documents, setDocuments] = useState<Document[]>([]);
    const [shipment, setShipment] = useState<ShipmentWithDocuments>({
        referenceNumber: latestReferenceNumber,
        documents: [],
        containers: [],
        hbls: [""],
    })

    useEffect(() => {
        if (latestReferenceNumber) {
            setShipment({
                ...shipment,
                referenceNumber: latestReferenceNumber
            })
        }
    }, [latestReferenceNumber])

    useEffect(() => {
        if (businessInput) {
            api.searchBusinessesByName(businessInput).then((response) => {
                setBusinesses(response.data);
            }).catch((error) => {
                console.error('Failed to fetch businesses:', error);
            });
        } else {
            setBusinesses([]);
        }
    }, [businessInput]);

    const handleClose = (event, reason): any => {
        if (event.target.getAttribute('aria-label') !== 'close') {
            return;
        }
        onClose();
    };

    const handleShipmentChange = <K extends keyof ShipmentWithDocuments>(key: K, value: ShipmentWithDocuments[K]) => {
        setShipment((prevShipment) => ({
            ...prevShipment,
            [key]: value,
        }));
    };

    const handleAddHbl = () => {
        const updatedHbls = [...(shipment.hbls || []), ""];
        handleShipmentChange('hbls', updatedHbls);
    };

    const handleHblChange = (index, value) => {
        const newHbls = shipment.hbls?.map((hbl, i) => (i === index ? value : hbl));
        handleShipmentChange('hbls', newHbls);
    }

    const handleCreateShipment = (e) => {
        setLoadingStatus(LoadingStatus.LOADING);
        setLoadingText('Creating Shipment...');
        api.createShipmentViaBroker(shipment).then((response) => {
            setLoadingStatus(LoadingStatus.SUCCESS);
            setSuccessText('Shipment created successfully');
            const shipment: ShipmentWithDocuments = response.data;
            onClose();
            navigate(`/broker/dashboard/shipments/${shipment._id}`);
        }).catch((error) => {
            setLoadingStatus(LoadingStatus.ERROR);
            setErrorText('Error loading shipment: ' + error.response.data.error);
        });
    }

    const renderBillsOfLadingSection = (shipment) => {
        switch (shipment.type) {
            case ShipmentTransportationType.OCEAN:
                return (
                    <>
                        <div style={{marginTop: '12px'}} className="business-info-header">
                            <Typography>
                                Bills of Lading
                            </Typography>
                        </div>
                        <div className="shipment-general-info-row">
                            <div className="business-form-field-container">
                                <div className="business-form-field-label">
                                    <Typography>Master Bill No.</Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={shipment.mbl}
                                    onChange={(e) => handleShipmentChange('mbl', e.target.value)}
                                />
                            </div>
                            {shipment.hbls.map((hbl, index) => (
                                <div key={index} className="business-form-field-container">
                                    <div style={{display: 'flex', alignItems: 'center'}}
                                         className="business-form-field-label">
                                        <Typography>House Bill No.</Typography>
                                        {index === shipment.hbls.length - 1 && (
                                            <IconButton
                                                sx={{marginLeft: '4px', width: '22px', height: '22px'}}
                                                onClick={handleAddHbl}
                                            >
                                                <Add sx={{fontSize: '20px'}}/>
                                            </IconButton>
                                        )}
                                    </div>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={hbl}
                                        onChange={(e) => handleHblChange(index, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                );
            case ShipmentTransportationType.AIR:
                return (
                    <>
                        <div style={{marginTop: '12px'}} className="business-info-header">
                            <Typography>
                                Master Airway Bills of Lading
                            </Typography>
                        </div>
                        <div className="shipment-general-info-row">
                            <div className="business-form-field-container">
                                <div className="business-form-field-label">
                                    <Typography>Master Airway Bill No.</Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={shipment.mbl}
                                    onChange={(e) => handleShipmentChange('mbl', e.target.value)}
                                />
                            </div>
                            {shipment.hbls.map((hbl, index) => (
                                <div key={index} className="business-form-field-container">
                                    <div style={{display: 'flex', alignItems: 'center'}}
                                         className="business-form-field-label">
                                        <Typography>House Airway Bill No.</Typography>
                                        {index === shipment.hbls.length - 1 && (
                                            <IconButton
                                                sx={{marginLeft: '4px', width: '22px', height: '22px'}}
                                                onClick={handleAddHbl}
                                            >
                                                <Add sx={{fontSize: '20px'}}/>
                                            </IconButton>
                                        )}
                                    </div>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={hbl}
                                        onChange={(e) => handleHblChange(index, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                );
            case ShipmentTransportationType.TRUCK:
                return (
                    <>
                        <div style={{marginTop: '12px'}} className="business-info-header">
                            <Typography>
                                PAPS
                            </Typography>
                        </div>
                        <div className="shipment-general-info-row">
                            <div className="business-form-field-container">
                                <div className="business-form-field-label">
                                    <Typography>PAPS No.</Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={shipment.paps}
                                    onChange={(e) => handleShipmentChange('paps', e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                );
            case ShipmentTransportationType.RAIL:
                return (
                    <>
                        <div style={{marginTop: '12px'}} className="business-info-header">
                            <Typography>
                                Rail Bill of Lading
                            </Typography>
                        </div>
                        <div className="shipment-general-info-row">
                            <div className="business-form-field-container">
                                <div className="business-form-field-label">
                                    <Typography>Rail Master Bill No.</Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={shipment.mbl}
                                    onChange={(e) => handleShipmentChange('mbl', e.target.value)}
                                />
                            </div>
                            <div className="business-form-field-container">
                                <div className="business-form-field-label">
                                    <Typography>I.T. No.</Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={shipment.itNumber}
                                    onChange={(e) => handleShipmentChange('itNumber', e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <div style={{marginTop: '12px'}} className="business-info-header">
                            <Typography>
                                Bills of Lading
                            </Typography>
                        </div>
                        <div className="shipment-general-info-row">
                            <div className="business-form-field-container">
                                <div className="business-form-field-label">
                                    <Typography>Master Bill No.</Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={shipment.mbl}
                                    onChange={(e) => handleShipmentChange('mbl', e.target.value)}
                                />
                            </div>
                            {shipment.hbls.map((hbl, index) => (
                                <div key={index} className="business-form-field-container">
                                    <div style={{display: 'flex', alignItems: 'center'}}
                                         className="business-form-field-label">
                                        <Typography>House Bill No.</Typography>
                                        {index === shipment.hbls.length - 1 && (
                                            <IconButton
                                                sx={{marginLeft: '4px', width: '22px', height: '22px'}}
                                                onClick={handleAddHbl}
                                            >
                                                <Add sx={{fontSize: '20px'}}/>
                                            </IconButton>
                                        )}
                                    </div>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={hbl}
                                        onChange={(e) => handleHblChange(index, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                );

        }
    }

    const renderContainerInfoSection = (shipment) => {
        if (shipment.type === ShipmentTransportationType.OCEAN || shipment.type === ShipmentTransportationType.RAIL) {
            return (
                <ManageContainers editable={true} useDeleteConfirmationModal={false} containers={shipment.containers}
                                  onContainersUpdated={saveContainersToShipment} parentName={'shipment'}
                                  fontSize={'18px'}/>);
        } else {
            return <></>;
        }
    }

    const saveDocumentsToShipment = (documents: Document[]) => {
        const updatedShipment = {
            ...shipment,
            documents: documents
        }
        setShipment(updatedShipment);
    }

    const saveContainersToShipment = (containers: Container[]) => {
        const updatedShipment = {
            ...shipment,
            containers: containers
        }
        setShipment(updatedShipment);
    }


    return (
        <Dialog maxWidth="lg" fullWidth={true} onClose={handleClose} open={open}>
            <div className="header-question">
                <Typography>Create Shipment</Typography>
            </div>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <div className="vmcr-step-title-container">
                {shipment.referenceNumber}
            </div>
            <div className="business-info-header">
                <Typography>
                    General Information
                </Typography>
            </div>
            <div className="shipment-general-info-row">
                <div className="business-form-field-container">
                    <div className="business-form-field-label">
                        <Typography>Port No.</Typography>
                    </div>
                    <TextField
                        value={shipment.referenceNumber}
                        fullWidth
                        size="small"
                        onChange={(e) => handleShipmentChange('referenceNumber', e.target.value)}
                    />
                </div>
                <div className="business-form-field-container">
                    <div className="business-form-field-label">
                        <Typography>Customer Ref. No.</Typography>
                    </div>
                    <TextField
                        fullWidth
                        size="small"
                        value={shipment.partnerReferenceNumber}
                        onChange={(e) => handleShipmentChange('partnerReferenceNumber', e.target.value)}
                    />
                </div>
                <div className="business-form-field-container">
                    <div className="business-form-field-label">
                        <Typography>Business</Typography>
                    </div>
                    <Autocomplete
                        fullWidth
                        id="business-select-demo"
                        size="small"
                        autoHighlight
                        options={businesses}
                        getOptionLabel={(option) => option.name}
                        onInputChange={(event, newInputValue) => {
                            setBusinessInput(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                            handleShipmentChange('businessName', newValue?.name)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps
                                }}
                            />
                        )}
                    />
                </div>
                <div className="business-form-field-container">
                    <div className="business-form-field-label">
                        <Typography>Mode of Transportation</Typography>
                    </div>
                    <FormControl fullWidth>
                        <Select
                            fullWidth
                            size="small"
                            value={shipment.type}
                            onChange={(e) => handleShipmentChange('type', e.target.value as ShipmentTransportationType)}
                        >
                            <MenuItem value={ShipmentTransportationType.OCEAN}>Ocean</MenuItem>
                            <MenuItem value={ShipmentTransportationType.AIR}>Air</MenuItem>
                            <MenuItem value={ShipmentTransportationType.TRUCK}>Truck</MenuItem>
                            <MenuItem value={ShipmentTransportationType.RAIL}>Rail</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            {renderBillsOfLadingSection(shipment)}
            <div style={{marginTop: '10px'}}>
                {renderContainerInfoSection(shipment)}
            </div>
            <div style={{marginTop: '10px'}}>
                <ManageDocuments filter={shipmentCreationFilter} documents={documents} setDocuments={setDocuments}
                                 useDeleteConfirmationModal={false} onDocumentsUpdated={saveDocumentsToShipment}
                                 parentName={'shipment'} fontSize={'18px'}/>
            </div>
            <div className="next-button-container">
                <Button
                    fullWidth
                    className="back-button-text"
                    variant="text"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    color="info"
                    size="medium"
                    variant="contained"
                    className="next-button-green"
                    onClick={handleCreateShipment}
                >
                    Create
                </Button>
            </div>
        </Dialog>
    );
}
import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    Autocomplete,
    Card,
    CardContent,
    Chip,
    Divider,
    MenuItem,
    styled,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {useAPI} from "@/api/APIContext";
import {Entry} from "common/interfaces/entry";
import {
    formatHumanReadableDate,
    generateShipmentChangeText,
    generateShipmentTrackingChangeText,
    howLongAgo,
    renderArrayWithCommas
} from "@/utils/shipmentUtils";
import Button from "@mui/material/Button";
import Close from '@mui/icons-material/Close';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment-timezone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './ShipmentPage.css';
import {
    CargoManifestQuery,
    CargoManifestQueryType,
    EntryNumberQuery,
    MasterAirwayBillQuery,
    MasterBillQuery,
} from 'common/interfaces/aceDataSource';
import DataImportLoader, {LoadingStatus} from '@/components/data-import-loader/DataImportLoader';
import PopupMenu from '@/shared-components/PopupMenu/PopupMenu';
import EditIcon from "@mui/icons-material/Edit";
import {Add, Check} from "@mui/icons-material";
import {BusinessWithDocuments, Mode} from 'common/interfaces/business';
import {Action, ActionType, ActionTypeToShortText} from 'common/interfaces/action';
import FloatingTooltip from '@/shared-components/FloatingTooltip/FloatingTooltip';
import ImportalTabs from '@/shared-components/ImportalTabs/ImportalTabs';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import {
    Container,
    InternalShipmentStatus,
    InternalShipmentStatusColor,
    InternalShipmentStatusText,
    Shipment,
    ShipmentMetadata,
    ShipmentTransportationType,
    ShipmentWithDocuments
} from "common/interfaces/shipment";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FlightIcon from '@mui/icons-material/Flight';
import TrainIcon from '@mui/icons-material/Train';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {buildEntryNumberNetchbUrl} from '@/utils/entryUtils';
import {Document, shipmentModificationFilter} from "common/interfaces/document";
import ManageDocuments from '@/shared-components/ManageDocuments/ManageDocuments';
import ManageContainers from '@/shared-components/ManageContainers/ManageContainers';
import WarningAlert from '@/shared-components/WarningAlert/WarningAlert';
import {CustomsOnboarding, CustomsOnboardingSteps} from 'common/interfaces/customsOnboarding';
import ShipmentContents from './ShipmentContents';
import DeleteConfirmationModal from '@/shared-components/DeleteConfirmationModal/DeleteConfirmationModal';
import {CargoManifestResults} from "@/shared-components/CMQRendering/CMQRendering";
import {findCarrierCodeInMbl} from "common/utilities/shipmentUtility";


const Input = styled("input")({
    display: "none",
});

export default function ShipmentPage() {
    const api = useAPI();
    const theme = useTheme();
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
    const [loadingText, setLoadingText] = useState('');
    const [successText, setSuccessText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [businesses, setBusinesses] = useState<BusinessWithDocuments[]>([]);
    const [businessInput, setBusinessInput] = useState<string>('');
    let billType = "";
    const location = useLocation();
    const [entry, setEntry]: any = useState({});
    const [entries, setEntries] = useState<Entry[]>([]);
    const [shipment, setShipment]: any = useState({});
    const [shipmentMetadata, setShipmentMetadata] = useState<ShipmentMetadata | undefined>(undefined);
    const [containers, setContainers] = useState([]);
    const [shipmentTrackingUpdates, setShipmentTrackingUpdates] = useState<any>([]);
    const [shipmentHistory, setShipmentHistory] = useState<any>([]);

    const [shipmentSummaryMode, setShipmentSummaryMode] = useState(Mode.VIEW);
    const [entryBillingMode, setEntryBillingMode] = useState(Mode.VIEW);
    const [showBusinessAlert, setShowBusinessAlert] = useState(true);
    const [businessAlertMessage, setBusinessAlertMessage] = useState('');
    const [businessAlertActionText, setBusinessAlertActionText] = useState('');
    const [documents, setDocuments] = useState<Document[]>([]);
    const [businessIsCustomsOnboarded, setBusinessIsCustomsOnboarded] = useState(true);
    const [businessIsOnboarded, setBusinessIsOnboarded] = useState(true);
    const [deleteShipmentModalOpen, setDeleteShipmentModalOpen] = useState(false);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseBusinessAlert = (): void => {
        setShowBusinessAlert(false);
    }

    useEffect(() => {
        if (documents.length > 0) {
            setShipment({
                ...shipment,
                documents: documents
            })
        }
    }, [documents])

    useEffect(() => {
        if (containers.length > 0) {
            setShipment({
                ...shipment,
                containers: containers
            })
        }
    }, [containers])

    const buildMasterBillQuery = (mbol: string): MasterBillQuery => {
        const masterBillQuery: MasterBillQuery = {
            carrierCode: mbol.substring(0, 4),
            billNumber: mbol.substring(4),
            type: CargoManifestQueryType.MASTER_BILL
        };
        return masterBillQuery;
    }


    const buildMasterAirwayBillQuery = (mbol: string) => {
        const masterAirwayBillQuery: MasterAirwayBillQuery = {
            billNumber: mbol,
            type: CargoManifestQueryType.MASTER_AIRWAY_BILL
        }
        return masterAirwayBillQuery;
    }

    const buildEntryNumberQuery = (entryNumber: string): EntryNumberQuery => {
        // Splitting the input string by the "-" delimiter
        const parts = entryNumber.split('-');
        const entryNumberQuery: EntryNumberQuery = {
            filerCode: parts[0],
            entryNumber: parts[1],
            checksum: parts[2],
            type: CargoManifestQueryType.ENTRY_NUMBER
        }
        return entryNumberQuery;
    }


    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 's' && shipmentSummaryMode === Mode.EDIT) {
                event.preventDefault();
                handleUpdateShipment(shipmentRef.current);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [shipmentSummaryMode]);


    const handleCmqClick = (type) => {
        let query: CargoManifestQuery = {} as CargoManifestQuery;
        setAnchorEl(null)
        switch (type) {
            case "entryNumber":
                query = buildEntryNumberQuery(entry?.entryNumber as string);
                break;
            case "masterBill":
                query = buildMasterBillQuery(shipment?.mbl as string)
                break;
            case "masterAirwayBill":
                query = buildMasterAirwayBillQuery(shipment?.mbl as string)
        }
        onDoQuery(query);
    }


    const saveContainersToShipment = (containers: Container[]) => {
        const updatedShipment = {
            ...shipment,
            containers: containers
        }
        setShipment(updatedShipment);
    }

    const formatCreatedAtDate = (dateString: string, timeZone: string = 'America/New_York'): string => {
        return moment(dateString).tz(timeZone).format('MM/DD/YY, h:mm A');
    }


    const renderIconBasedOnTransportType = (type: ShipmentTransportationType) => {
        switch (type) {
            case ShipmentTransportationType.OCEAN:
                return <FloatingTooltip title="Ocean" placement="top">
                    <DirectionsBoatIcon/>
                </FloatingTooltip>
            case ShipmentTransportationType.AIR:
                return <FloatingTooltip title="Air" placement="top">
                    <FlightIcon/>
                </FloatingTooltip>
            case ShipmentTransportationType.TRUCK:
                return <FloatingTooltip title="Truck" placement="top">
                    <LocalShippingIcon/>
                </FloatingTooltip>
            case ShipmentTransportationType.RAIL:
                return <FloatingTooltip title="Rail" placement="top">
                    <TrainIcon/>
                </FloatingTooltip>
            default:
                break;
        }
    }


    const updateEntry = () => {
        api.updateEntry(entry._id, entry).then((response) => {
            setSuccessText('Entry updated successfully');
            setLoadingStatus(LoadingStatus.SUCCESS)
        }).catch((error) => {
            setErrorText('Error updating entry');
            setLoadingStatus(LoadingStatus.ERROR)
        });
    }

    const saveDocumentsToShipment = (documents: Document[]) => {
        const updatedShipment = {
            ...shipment,
            documents: documents
        }
        updateShipment(updatedShipment);
    }

    const updateShipment = (shipment) => {
        setLoadingText('Updating shipment...');
        setLoadingStatus(LoadingStatus.LOADING);
        api.updateShipment(shipment._id, shipment).then((response) => {
            handleChangeMode(setShipmentSummaryMode, Mode.VIEW);
            setSuccessText('Shipment updated successfully');
            setLoadingStatus(LoadingStatus.SUCCESS);
            getShipmentData(shipment._id);
            refreshShipmentHistory(shipment._id);
        }).catch((error) => {
            setErrorText('Error updating shipment');
            setLoadingStatus(LoadingStatus.ERROR)
        })
    }


    const createStatusChip = (status: InternalShipmentStatus) => {
        return (
            <div className="status-chip-container">
                <Chip
                    label={InternalShipmentStatusText[status]}
                    sx={{
                        backgroundColor: InternalShipmentStatusColor[status],
                        color: "white",
                        fontWeight: "bold",
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        height: '26px'
                    }}
                />
            </div>
        )
    }

    const getBusinessCustomsOnboarding = async (shipment: ShipmentWithDocuments) => {
        try {
            const response = await api.getCustomsOnboardingForBusiness(shipment.business?.toString() as string);
            const customsOnboarding: CustomsOnboarding = response.data;

            if (customsOnboarding.step === CustomsOnboardingSteps.COMPLETE) {
                setBusinessIsCustomsOnboarded(true);
            } else {
                setBusinessIsCustomsOnboarded(false);
                setBusinessAlertMessage(`${shipment.businessName} has been created, but is not customs onboarded yet.`)
                setBusinessAlertActionText('Complete Customs Onboarding');
            }
        } catch (error) {
            setLoadingStatus(LoadingStatus.ERROR);
            setErrorText('Error getting customs onboarding information');
        }
    };

    const getShipmentData = async (shipmentId: string) => {
        try {
            setLoadingStatus(LoadingStatus.LOADING);
            setLoadingText('Getting shipment information...');

            const shipmentResponse = await api.getShipmentById(shipmentId);
            const shipment: ShipmentWithDocuments = shipmentResponse.data;

            document.title = `Importal - ${shipment.referenceNumber}`;
            setLoadingStatus(LoadingStatus.SUCCESS);
            setSuccessText('Successfully retrieved shipment');
            setShipment(shipment);

            if (shipment.documents) {
                setDocuments(shipment.documents);
            }

            if (shipment.business) {
                setBusinessIsOnboarded(true);
                getBusinessCustomsOnboarding(shipment);
            } else {
                setBusinessIsOnboarded(false);
                if (shipment.businessName) {
                    setBusinessAlertMessage(`${shipment.businessName} has not been created yet. Click on the button on the right to create it in the broker app.`);
                    setBusinessAlertActionText(`Create ${shipment.businessName}`);
                } else {
                    setBusinessAlertMessage(`This shipment does not have a business associated with it, please add a business to it.`);
                    setBusinessAlertActionText('');
                }

            }

            getShipmentMetadata(shipmentId);
            const entriesResponse = await api.getEntriesByShipmentId(shipmentId);
            setEntries(entriesResponse.data);
            setEntry(entriesResponse.data[entriesResponse.data.length - 1]);

        } catch (error) {
            setLoadingStatus(LoadingStatus.ERROR);
            setErrorText('Error getting shipment information');
        }
    };

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const shipmentId = pathParts[pathParts.length - 1];
        if (!shipmentId) return;

        getShipmentData(shipmentId);
        refreshShipmentHistory(shipmentId);
        return () => {
            document.title = 'Importal';
        };
    }, [location]);


    const getShipmentMetadata = (shipmentId: string): void => {
        api.getShipmentMetadata(shipmentId)
            .then(({data}) => {

                setShipmentMetadata(data);
                setShipmentTrackingUpdates(data.trackingUpdates);

            })
    }

    const onDoQuery = async (query: CargoManifestQuery) => {
        setLoadingText('Querying cargo manifest...');
        setLoadingStatus(LoadingStatus.LOADING);
        if (!shipment) return
        try {
            const shipmentId = shipment._id!.toString()
            await api.getCargoManifest(shipmentId, query);
            getShipmentMetadata(shipmentId);
            setSuccessText('Successfully retrieved cargo manifest');
            setLoadingStatus(LoadingStatus.SUCCESS);
            refreshShipmentHistory(shipment._id)
        } catch (err) {
            setErrorText('Error querying cargo manifest');
            setLoadingStatus(LoadingStatus.ERROR);
            console.error('error getting entry metadata')
            console.error(err)
        }
    }


    function determineBillType(input: string): string {
        if (/^\d+$/.test(input)) {
            billType = "Airway";
            return "masterAirwayBill";
        } else if (/^[0-9a-zA-Z]+$/.test(input)) {
            billType = "OceanTruckRail";
            return "masterBill";
        } else {
            return "invalidInput";
        }
    }

    const renderMenuItemsBasedOnShipmentType = () => {
        const shipmentBillType = determineBillType(shipment.mbl);
        if (shipmentBillType === "masterBill") {
            return [
                <MenuItem key="masterBill" onClick={() => handleCmqClick("masterBill")} disableRipple>
                    Master Bill
                </MenuItem>,
                <Divider key="divider1"/>,
                <MenuItem key="houseBill" onClick={handleClose} disabled disableRipple>
                    House Bill
                </MenuItem>,
                <MenuItem key="entryNumber" onClick={() => handleCmqClick("entryNumber")} disabled disableRipple>
                    Entry Number
                </MenuItem>
            ];
        } else if (shipmentBillType === "masterAirwayBill") {
            return [
                <MenuItem key="masterAirwayBill" onClick={() => handleCmqClick("masterAirwayBill")}>
                    Master Airway Bill
                </MenuItem>,
                <Divider key="divider2"/>,
                <MenuItem key="houseAirwayBill" disabled>
                    House Airway Bill
                </MenuItem>,
                <MenuItem key="entryNumber" onClick={() => handleCmqClick("entryNumber")} disabled disableRipple>
                    Entry Number
                </MenuItem>
            ];
        }
        return [
            <MenuItem key="masterBill" onClick={() => handleCmqClick("masterBill")} disableRipple>
                Master Bill
            </MenuItem>,
            <Divider key="divider1"/>,
            <MenuItem key="houseBill" onClick={handleClose} disabled disableRipple>
                House Bill
            </MenuItem>,
            <MenuItem key="entryNumber" onClick={() => handleCmqClick("entryNumber")} disabled disableRipple>
                Entry Number
            </MenuItem>
        ];
    };

    const handleChangeMode = (setModeFunction: any, newMode: Mode) => {
        setModeFunction(newMode);
    }

    const handleShipmentChange = (name, value) => {
        setShipment({...shipment, [name]: value});
    }

    const handleEntryChange = (name, value) => {
        setEntry({...entry, [name]: value});
    }

    const handleEntriesChange = (index, name, value) => {
        setEntries(prevEntries =>
            prevEntries.map((entry, i) =>
                i === index ? {...entry, [name]: value} : entry
            )
        );
    };


    const handleAddHbl = () => {
        const updatedHbls = [...(shipment.hbls || []), ""];
        handleShipmentChange('hbls', updatedHbls);
    };

    const handleHblChange = (index, value) => {
        const newHbls = shipment.hbls?.map((hbl, i) => (i === index ? value : hbl));
        handleShipmentChange('hbls', newHbls);
    }

    const handleDeleteShipmentConfirmClose = (confirm: boolean) => {
        if (confirm) {
            setLoadingText('Deleting shipment...');
            setLoadingStatus(LoadingStatus.LOADING);
            api.deleteShipment(shipment._id).then((response) => {
                setSuccessText('Successfully deleted shipment');
                setLoadingStatus(LoadingStatus.SUCCESS);
                setDeleteShipmentModalOpen(false);
                navigate('/broker/dashboard/shipments');
            }).catch((error) => {
                setErrorText('Error deleting shipment: ' + error);
                setLoadingStatus(LoadingStatus.ERROR);
                setDeleteShipmentModalOpen(false);
            })
        } else {
            setDeleteShipmentModalOpen(false);
        }

    }


    useEffect(() => {
        if (businessInput) {
            api.searchBusinessesByName(businessInput).then((response) => {
                setBusinesses(response.data);
            }).catch((error) => {
                console.error('Failed to fetch businesses:', error);
            });
        } else {
            setBusinesses([]);
        }
    }, [businessInput]);

    const renderShipmentField = (cardMode, value, name) => {
        if (cardMode === Mode.EDIT) {
            switch (name) {
                case 'businessName':
                    return (
                        <div className="entry-business-info-row-container">
                            <div className="entry-row-header">Business</div>
                            <div className="entry-row-text">
                                <Autocomplete
                                    sx={{minWidth: '250px'}}
                                    fullWidth
                                    id="business-select-demo"
                                    size="small"
                                    autoHighlight
                                    options={businesses}
                                    getOptionLabel={(option) => option.name}
                                    onInputChange={(event, newInputValue) => {
                                        setBusinessInput(newInputValue);
                                    }}
                                    onChange={(event, newValue) => {
                                        setShipment({
                                            ...shipment,
                                            businessName: newValue?.name,
                                            business: newValue?._id
                                        })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                    )
                case 'billed':
                case 'paid':
                    return (
                        <TextField
                            select
                            fullWidth
                            variant="standard"
                            value={value ? 'Yes' : 'No'}
                            onChange={(e) => handleShipmentChange(name, e.target.value === 'Yes')}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    );
                case 'statementAuthorized':
                    return (
                        <TextField
                            select
                            fullWidth
                            variant="standard"
                            value={value ? 'Yes' : 'No'}
                            onChange={(e) => handleEntryChange(name, e.target.value === 'Yes')}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    );
                case 'customsCleared':
                    return (
                        <TextField
                            select
                            fullWidth
                            variant="standard"
                            value={value ? 'Yes' : 'No'}
                            onChange={(e) => handleShipmentChange(name, e.target.value === 'Yes')}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    );
                case 'isfOnFile':
                    return (
                        <div className="entry-business-info-row-container">
                            <div className="entry-row-header">ISF On File</div>
                            <TextField
                                select
                                fullWidth
                                variant="standard"
                                value={value ? 'Yes' : 'No'}
                                onChange={(e) => handleShipmentChange(name, e.target.value === 'Yes')}
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </TextField>
                        </div>
                    )
                case 'type':
                    return (
                        <TextField
                            select
                            fullWidth
                            variant="standard"
                            value={value}
                            onChange={(e) => handleShipmentChange(name, e.target.value)}
                        >
                            <MenuItem value={ShipmentTransportationType.OCEAN}>Ocean</MenuItem>
                            <MenuItem value={ShipmentTransportationType.AIR}>Air</MenuItem>
                            <MenuItem value={ShipmentTransportationType.TRUCK}>Truck</MenuItem>
                            <MenuItem value={ShipmentTransportationType.RAIL}>Rail</MenuItem>
                        </TextField>
                    );
                case 'entryNumber':
                case 'statementDate':
                    return (
                        <TextField
                            fullWidth
                            variant="standard"
                            onChange={(e) => handleEntryChange(name, e.target.value)}
                            value={value}
                        />
                    );
                case 'internalStatus':
                    return (
                        <TextField
                            select
                            fullWidth
                            variant="standard"
                            value={value}
                            onChange={(e) => handleShipmentChange(name, e.target.value)}
                        >
                            {Object.keys(InternalShipmentStatus).map((statusKey) => (
                                <MenuItem key={statusKey} value={InternalShipmentStatus[statusKey]}>
                                    {InternalShipmentStatusText[statusKey]}
                                </MenuItem>
                            ))}
                        </TextField>
                    )
                case 'containers':
                    return (
                        <ManageContainers editable={true} fontSize={"18px"} parentName={"shipment"}
                                          useDeleteConfirmationModal={false} containers={shipment.containers}
                                          onContainersUpdated={saveContainersToShipment}/>
                    )
                case 'hbls':
                    return (
                        <>
                            {shipment.hbls.map((hbl, index) => (
                                <div key={index} className="entry-business-info-row-container">
                                    <div style={{display: 'flex', alignContent: 'center'}}
                                         className="entry-row-header">House Bill
                                        {index === shipment.hbls.length - 1 && (
                                            <IconButton
                                                sx={{marginLeft: '4px', width: '22px', height: '22px'}}
                                                onClick={handleAddHbl}
                                            >
                                                <Add sx={{fontSize: '20px'}}/>
                                            </IconButton>
                                        )}
                                    </div>
                                    <div className="entry-row-text">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant='standard'
                                            value={hbl}
                                            onChange={(e) => handleHblChange(index, e.target.value)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </>
                    );

                default:
                    return (
                        <TextField
                            fullWidth
                            variant="standard"
                            onChange={(e) => handleShipmentChange(name, e.target.value)}
                            value={value}
                        />
                    );
            }
        } else {
            const vizionText = shipmentTrackingUpdates.length > 0 ? `Updated by Vizion ${howLongAgo(shipmentTrackingUpdates[shipmentTrackingUpdates.length - 1].createdAt)}` : `No tracking information from Vizion yet.`
            switch (name) {
                case 'businessName':
                    return <></>;
                case 'type':
                    return renderIconBasedOnTransportType(shipment.type);
                case 'billed':
                case 'paid':
                case 'statementAuthorized':
                    return (
                        <Typography>{value ?
                            <CheckIcon style={{color: 'green'}}/>
                            : <CloseIcon style={{color: 'red'}}/>}
                        </Typography>
                    );
                case 'isfOnFile':
                    return (
                        <div className="entry-business-info-row-container">
                            <div className="entry-row-header">ISF On File</div>
                            <Typography>{shipment.isfOnFile ?
                                <CheckIcon style={{color: 'green'}}/>
                                : <CloseIcon style={{color: 'red'}}/>}
                            </Typography>
                        </div>)
                case 'customsCleared':
                    return (
                        <Typography>{shipment.customsCleared ?
                            <CheckIcon style={{color: 'green'}}/>
                            : <CloseIcon style={{color: 'red'}}/>}
                        </Typography>
                    )
                case 'etd':
                    return (
                        <div>
                            <FloatingTooltip title={vizionText}>
                                <div><b>ETD</b> {value ? formatHumanReadableDate(value) : <>No data yet.</>}</div>
                            </FloatingTooltip>
                        </div>
                    );
                case 'eta':
                    return (
                        <div>
                            <FloatingTooltip title={vizionText}>
                                <div><b>ETA</b> {value ? formatHumanReadableDate(value) : <>No data yet.</>}</div>
                            </FloatingTooltip>
                        </div>
                    );
                case 'internalStatus':
                    return createStatusChip(shipment.internalStatus);
                case 'entryNumber':
                    if (value) {
                        return <div className="entry-row-text-value"><a href={buildEntryNumberNetchbUrl(value)}
                                                                        target="_blank">{value}</a></div>
                    } else {
                        return <div className="entry-row-text-value">{value}</div>
                    }
                case 'hbls':
                    return (
                        <div className="entry-business-info-row-container">
                            <div className="entry-row-header">House Bill(s)</div>
                            <div className="entry-row-text-value">{renderArrayWithCommas(value)}</div>
                        </div>

                    )
                default:
                    return (
                        <div className="entry-row-text-value">{value}</div>
                    );
            }
        }
    };

    const renderContainerInfoSection = () => {
        return (<ManageContainers editable={shipmentSummaryMode === Mode.EDIT} fontSize={"18px"} parentName={"shipment"}
                                  useDeleteConfirmationModal={false} containers={shipment.containers}
                                  onContainersUpdated={saveContainersToShipment}/>);

    }

    // const mapLineItemsToBill = (lineItems) => {
    //     let result;
    //     if (billType === "Airway") {
    //         result = mapLineItemsToMasterAirwayBill(lineItems);
    //     } else if (billType === "OceanTruckRail") {
    //         result = mapLineItemstoOceanTruckRailBill(lineItems);
    //     }
    //     return result;
    // }
    //
    // const mapLineItemsToMasterAirwayBill = (lineItems) => {
    //     const result: any[] = [];
    //     let parent: any = null;
    //     lineItems.forEach((lineItem) => {
    //         if (isWR1LineItem(lineItem)) {
    //             result.push(lineItem);
    //         } else if (isWSDLineItem(lineItem)) {
    //             result.push(lineItem);
    //         }
    //     });
    //     // Push the last parent object if it exists
    //     if (parent !== null) {
    //         result.push(parent);
    //     }
    //     return result;
    // }
    //
    // const mapLineItemstoOceanTruckRailBill = (lineItems) => {
    //     const result: any[] = [];
    //     let parent: any = null;
    //     lineItems.forEach((lineItem) => {
    //         if (isWR4LineItem(lineItem)) {
    //             if (parent !== null) {
    //                 result.push(parent);
    //             }
    //             parent = {...lineItem, lineItems: []};
    //         } else if (isWR5LineItem(lineItem) && parent !== null) {
    //             // Mapping the string to a new object
    //             // we can sanitize strings here?
    //             parent.lineItems.push(lineItem);
    //         }
    //     });
    //     // Push the last parent object if it exists
    //     if (parent !== null) {
    //         result.push(parent);
    //     }
    //     return result;
    // }

    const handleCancelEditShipment = () => {
        setShipmentSummaryMode(Mode.VIEW);
    }

    const shipmentRef = useRef(shipment);

    useEffect(() => {
        shipmentRef.current = shipment;
    }, [shipment]);

    const handleUpdateShipment = (shipment: Shipment<any>) => {
        updateShipment(shipment);
        updateEntry();
    }

    const handleDeleteShipment = () => {
        setDeleteShipmentModalOpen(true);
    }

    useEffect(() => {
        if (businessInput) {
            api.searchBusinessesByName(businessInput).then((response) => {
                setBusinesses(response.data);
            }).catch((error) => {
                console.error('Failed to fetch businesses:', error);
            });
        } else {
            setBusinesses([]);
        }
    }, [businessInput]);


    const handleCreateTrackingReference = () => {
        setLoadingStatus(LoadingStatus.LOADING);
        setLoadingText('Creating Vizion tracking reference...');
        api.createShipmentTrackingReference(shipment._id).then((response) => {
            setShipment(response.data);
            setLoadingStatus(LoadingStatus.SUCCESS);
            setSuccessText('Successfully created Vizion tracking reference');
        }).catch((error) => {
            setLoadingStatus(LoadingStatus.ERROR);
            setErrorText('Error creating Vizion tracking reference');
        })
    }

    const renderModeIconButton = (setModeFunction: any, mode: Mode) => {
        if (mode === Mode.EDIT) {
            return (
                <div>
                    <FloatingTooltip title="Cancel" placement="top">
                        <IconButton
                            onClick={() => handleCancelEditShipment()}
                            style={{
                                color: 'red',
                            }}
                        >
                            <Close/>
                        </IconButton>
                    </FloatingTooltip>
                    <FloatingTooltip title="Save changes" placement="top">
                        <IconButton
                            onClick={() => handleUpdateShipment(shipment)}
                            style={{
                                color: 'green',
                            }}
                        >
                            <Check/>
                        </IconButton>
                    </FloatingTooltip>
                </div>

            )
        } else {
            return (
                <div style={{display: 'flex'}}>
                    {shipment.mbl ? (
                        !shipment.trackingId && findCarrierCodeInMbl(shipment.mbl) ? (
                            <FloatingTooltip title="Create Vizion tracking reference" placement="top">
                                <Button
                                    className="vizion-button-green"
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="text"
                                    sx={{textTransform: 'none'}}
                                    onClick={handleCreateTrackingReference}
                                    disabled={shipment.mbl === ''}
                                >
                                    Track
                                </Button>
                            </FloatingTooltip>
                        ) : shipment.trackingId ? (
                            <div style={{
                                color: 'grey',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '5px'
                            }}>
                                Shipment tracked by Vizion
                            </div>
                        ) : null
                    ) : null}

                    <FloatingTooltip title="Edit" placement="top">
                        <IconButton
                            onClick={() => handleChangeMode(setModeFunction, Mode.EDIT)}
                            style={{color: 'grey'}}
                        >
                            <EditIcon/>
                        </IconButton>
                    </FloatingTooltip>
                    <FloatingTooltip title="Delete" placement="top">
                        <IconButton
                            onClick={() => handleDeleteShipment()}
                            style={{color: 'grey'}}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </FloatingTooltip>
                </div>
            )
        }
    }

    const renderISFInformation = () => {
        if (shipment.type === ShipmentTransportationType.OCEAN || shipment.type === ShipmentTransportationType.RAIL || shipment.type === undefined) {
            return renderShipmentField(shipmentSummaryMode, shipment.isfOnFile, 'isfOnFile');
        }
    }

    const summaryTab = () => {
        return (
            <>
                {shipment ?
                    <>
                        <Card className="my-shipments-card">
                            <CardContent>
                                <div className="my-shipments-header"
                                     style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D1D1D1', paddingBottom: '8px'}}>
                                    <div className="shipment-summary-header-main-info">
                                        <div>Summary</div>
                                        <div>{renderShipmentField(shipmentSummaryMode, shipment.internalStatus, 'internalStatus')}</div>
                                        <div
                                            style={{marginLeft: '5px'}}>{renderShipmentField(shipmentSummaryMode, shipment.type, 'type')}
                                        </div>
                                        <div
                                        className="shipment-summary-header-row-container">
                                        <div
                                            className="shipment-summary-row-text">{renderShipmentField(shipmentSummaryMode, shipment.etd, 'etd')}</div>
                                    </div>
                                    <div style={{marginLeft: '5px'}}
                                         className="shipment-summary-header-row-container">
                                        <div
                                            className="shipment-summary-row-text">{renderShipmentField(shipmentSummaryMode, shipment.eta, 'eta')}</div>
                                    </div>
                                    </div>
                                    {renderModeIconButton(setShipmentSummaryMode, shipmentSummaryMode)}
                                </div>
                                <div className="entry-info-container">
                                    <div className="entry-business-info-container">
                                        <div className="business-info-header">
                                            <Typography>
                                                General Information
                                            </Typography>
                                        </div>
                                        <div className="entry-business-info-row">
                                            {renderShipmentField(shipmentSummaryMode, shipment.businessName, 'businessName')}
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Customer Ref. No.</div>
                                                <div
                                                    className="entry-row-text">{renderShipmentField(shipmentSummaryMode, shipment.partnerReferenceNumber, 'partnerReferenceNumber')}
                                                </div>
                                            </div>
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Entry No.</div>
                                                <div
                                                    className="entry-row-text">{renderShipmentField(shipmentSummaryMode, entry.entryNumber, 'entryNumber')}</div>
                                            </div>
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">ISF No.</div>
                                                {renderShipmentField(shipmentSummaryMode, shipment.isfNumber, 'isfNumber')}
                                            </div>
                                            {renderISFInformation()}
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Customs Cleared</div>
                                                <div
                                                    className="entry-row-text">{renderShipmentField(shipmentSummaryMode, shipment.customsCleared, 'customsCleared')}</div>
                                            </div>
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Invoice No.</div>
                                                {renderShipmentField(shipmentSummaryMode, shipment.invoiceNumber, 'invoiceNumber')}
                                            </div>
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Billed</div>
                                                {renderShipmentField(shipmentSummaryMode, shipment.billed, 'billed')}
                                            </div>
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Paid</div>
                                                {renderShipmentField(shipmentSummaryMode, shipment.paid, 'paid')}
                                            </div>
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Statement Authorized</div>
                                                {renderShipmentField(shipmentSummaryMode, entry.statementAuthorized, 'statementAuthorized')}
                                            </div>
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Statement Date</div>
                                                {renderShipmentField(shipmentSummaryMode, entry.statementDate, 'statementDate')}
                                            </div>
                                            <div style={{minWidth: '200px'}}
                                                 className="entry-business-info-row-container">
                                                <div className="entry-row-header">Notes</div>
                                                <div
                                                    className="entry-row-text">{renderShipmentField(shipmentSummaryMode, shipment.notes, 'notes')}</div>
                                            </div>
                                        </div>
                                        <div className="business-info-header">
                                            <Typography>
                                                Bills of Lading
                                            </Typography>
                                        </div>
                                        <div className="entry-business-info-row">
                                            <div className="entry-business-info-row-container">
                                                <div className="entry-row-header">Master Bill</div>
                                                <div className="entry-row-text">
                                                    {renderShipmentField(shipmentSummaryMode, shipment.mbl, 'mbl')}
                                                </div>
                                            </div>
                                            {renderShipmentField(shipmentSummaryMode, shipment.hbls, 'hbls')}
                                        </div>
                                        {renderContainerInfoSection()}
                                    </div>
                                </div>
                            </CardContent>
                            <DeleteConfirmationModal open={deleteShipmentModalOpen} title={"Delete Shipment"}
                                                     itemName={'shipment'} onClose={handleDeleteShipmentConfirmClose}/>
                        </Card>
                        <div style={{display: 'flex', justifyContent: 'center', gap: '4px', width: '100%'}}>
                            <Card sx={{flex: 1}} className="my-shipments-card">
                                <CardContent>
                                    <div className="entry-card-header-container">
                                        <div className="my-shipments-header">Cargo Manifest</div>
                                        <div className="cmq-button-container">
                                            <div>
                                                <Button
                                                    aria-controls={open ? 'demo-customized-menu' : undefined}
                                                    aria-haspopup="true"
                                                    className='cmq-button-green'
                                                    aria-expanded={open ? 'true' : undefined}
                                                    variant="contained"
                                                    sx={{textTransform: 'none'}}
                                                    onClick={handleClick}
                                                    endIcon={<KeyboardArrowDownIcon/>}
                                                    disabled={shipment.mbl === '' || shipment.mbl === undefined}
                                                >
                                                    Query
                                                </Button>
                                                <PopupMenu
                                                    id="demo-customized-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                >
                                                    {renderMenuItemsBasedOnShipmentType()}
                                                </PopupMenu>
                                            </div>
                                        </div>
                                    </div>
                                    {(shipmentMetadata?.lastCMQParsed && shipmentMetadata?.lastCMQRaw) ?
                                        <CargoManifestResults
                                            parsedCargoManifest={shipmentMetadata.lastCMQParsed}
                                            rawCargoManifest={shipmentMetadata.lastCMQRaw}
                                            billType={billType}
                                        /> :
                                        <div className='no-results-container'>No cargo manifest retrieved yet for this
                                            shipment.</div>}
                                </CardContent>
                            </Card>
                            <Card sx={{flex: 1}} className="my-shipments-card">
                                <CardContent>
                                    <ManageDocuments filter={shipmentModificationFilter}
                                                     useDeleteConfirmationModal={true} fontSize={'24px'}
                                                     documents={documents} setDocuments={setDocuments}
                                                     onDocumentsUpdated={saveDocumentsToShipment}
                                                     parentName={'shipment'}/>
                                </CardContent>
                            </Card>
                        </div>
                    </>
                    :
                    <span>Loading...</span>
                }
            </>
        )
    }

    const refreshShipmentHistory = (shipmentId: string) => {
        setLoadingText('Refreshing shipment history...');
        setLoadingStatus(LoadingStatus.LOADING);
        api.getDocumentActions(shipmentId).then((response) => {
            setSuccessText('Successfully retrieved shipment history');
            setLoadingStatus(LoadingStatus.SUCCESS);
            setShipmentHistory(response.data);
        }).catch((error) => {
            setErrorText('Error getting shipment history');
            setLoadingStatus(LoadingStatus.ERROR);
        })
    }

    const renderChangeCount = (action: Action<any>) => {
        switch (action.type) {
            case ActionType.CREATE_SHIPMENT:
            case ActionType.UPDATE_SHIPMENT:
                return generateShipmentChangeText(action)[1];
            case ActionType.CREATE_TRACKING:
            case ActionType.UPDATE_TRACKING:
                return generateShipmentTrackingChangeText(action)[1];
        }
    }

    const renderActionSummary = (action: Action<any>) => {
        switch (action.type) {
            case ActionType.CREATE_SHIPMENT:
            case ActionType.UPDATE_SHIPMENT:
                return generateShipmentChangeText(action)[0];
            case ActionType.CREATE_TRACKING:
            case ActionType.UPDATE_TRACKING:
                return generateShipmentTrackingChangeText(action)[0];
            case ActionType.CARGO_MANIFEST_QUERY_RETRIEVED:
                return "A Cargo Manifest Query was performed";
            case ActionType.CARGO_MANIFEST_QUERY_CHANGED:
                return `${action.properties['linesChanged']?.length || 0} lines were changed`;
        }
    }

    const renderUpdatedBy = (action: Action<any>) => {
        switch (action.type) {
            case ActionType.CREATE_SHIPMENT:
            case ActionType.UPDATE_SHIPMENT:
                return `${action.updatedBy.firstName} ${action.updatedBy.lastName}`;
            case ActionType.CREATE_TRACKING:
                return `${action.updatedBy.firstName} ${action.updatedBy.lastName}`;
            case ActionType.UPDATE_TRACKING:
                return action.updatedBy;
            case ActionType.CARGO_MANIFEST_QUERY_RETRIEVED:
                return `${action.updatedBy.firstName} ${action.updatedBy.lastName}`;
            case ActionType.CARGO_MANIFEST_QUERY_CHANGED:
                return action.updatedBy;
        }
    }

    const historyTab = () => {
        return (
            <Card className="my-shipments-card">
                <CardContent>
                    {shipmentHistory.length > 0 ? (
                        <>
                            <div className="entry-card-header-container">
                                <div className="my-shipments-header">History</div>
                            </div>
                            <div className="shipment-page-breakdown-container-header" key="header-duty-title-pga-one">
                                <div className="shipment-history-date-time">Date / Time</div>
                                <div className="shipment-history-type">Type</div>
                                <div className="shipment-history-change-count">Changes</div>
                                <div className="shipment-history-summary">Summary</div>
                                <div className="shipment-history-updated-by">Updated By</div>
                            </div>
                            {shipmentHistory.map((shipmentHistoryLineItem, index) => (
                                <div className="shipment-breakdown-container-row" key={index}>
                                    <div className="shipment-history-date-time">
                                        {formatHumanReadableDate(shipmentHistoryLineItem.createdAt)}
                                    </div>
                                    <div className="shipment-history-type">
                                        {ActionTypeToShortText[shipmentHistoryLineItem.type]}
                                    </div>
                                    <div className="shipment-history-change-count">
                                        {renderChangeCount(shipmentHistoryLineItem)}
                                    </div>
                                    <div className="shipment-history-summary">
                                        {renderActionSummary(shipmentHistoryLineItem)}
                                    </div>
                                    <div className="shipment-history-updated-by">
                                        {renderUpdatedBy(shipmentHistoryLineItem)}
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className="no-results-container">No history yet for this shipment.</div>
                    )}
                </CardContent>
            </Card>
        );
    };


    const handleBusinessAction = () => {
        if (!businessIsOnboarded) {
            navigate('/broker/dashboard/businesses?create=true');
        } else if (businessIsOnboarded && !businessIsCustomsOnboarded) {
            navigate(`/broker/dashboard/businesses/${shipment.business}`);
        }
    }


    return (
        <>
            <div className='product-library-header'>
                <div className="business-header-text">
                    <div style={{display: 'flex'}}>
                        <Typography sx={{color: theme.palette.primary.main, fontSize: "26px", display: 'flex'}}>
                            {shipment.business ? (
                                <a style={{color: 'inherit'}} target='_blank'
                                   href={`/broker/dashboard/businesses/${shipment.business}`}>{shipment.businessName}</a>
                            ) : (
                                shipment.businessName
                            )}
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <KeyboardArrowRightIcon/>
                            </span>
                            {shipment?.referenceNumber}
                        </Typography>

                    </div>
                    <div style={{marginTop: '0px', marginBottom: "0px"}} className="universal-subheader">
                        View shipment and entry details and query cargo manifests
                    </div>
                </div>
            </div>
            {!businessIsCustomsOnboarded || !businessIsOnboarded ? <WarningAlert
                message={businessAlertMessage}
                onClose={handleCloseBusinessAlert}
                actionText={businessAlertActionText}
                onActionClick={handleBusinessAction}
            /> : <></>}
            <ImportalTabs tabsConfig={[
                {
                    title: "Summary",
                    element: summaryTab()
                },
                {
                    title: "History",
                    element: historyTab()
                },
                {
                    title: "Contents",
                    element: <> {(shipment && shipment._id) &&
                        <ShipmentContents shipmentId={shipment._id!.toString()}/>
                    }</>
                }
            ]}/>
            <DataImportLoader
                loadingState={{loadingStatus}}
                loadingText={loadingText}
                successText={successText}
                errorText={errorText}
            />
        </>
    );
}


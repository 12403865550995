import {ChartConfig} from "common/interfaces/reports";
import ImportalHistogram from "@/components/reporting/ImportalHistogram";
import ImportalPieChart from "@/components/reporting/ImportalPieChart";


export interface Props {
    chartConfig: ChartConfig,
    data: any[]
}

export default function ReportChart({chartConfig, data}: Props) {

    const getChart = (chartConfig: ChartConfig) => {
        if (chartConfig.chartType === "PIE") {
            return <ImportalPieChart chartConfig={chartConfig} data={data}></ImportalPieChart>
        } else if (chartConfig.chartType === "HISTOGRAM") {
            return <ImportalHistogram chartConfig={chartConfig} data={data}></ImportalHistogram>
        } else {
            return <div>Unimplemented Chart Type: {chartConfig.chartType}</div>
        }
    }

    return (
        <div>
            <h3>{chartConfig.chartName}</h3>
            <h5>{chartConfig.chartDescriptor}</h5>
            {getChart(chartConfig)}
        </div>
    )
}
import {Button, Card, CardContent, InputAdornment, TextField, Typography, useTheme} from "@mui/material";
import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface Props {
    totalWeight?: string,
    onTotalWeightSelected: (totalWeight?: string) => void,
    onNext: () => void,
    onBack: () => void
}

export default function WeightInfo({
                                       totalWeight,
                                       onTotalWeightSelected,
                                       onNext,
                                       onBack
                                   }: Props) {
    const theme = useTheme();

    const handleVariableAmount = (e) => {
        onTotalWeightSelected(e.target.value)
    };

    const inputIsValid = (): boolean => {
        return !!(totalWeight)
    }

    return (
        <Card className="card-container">
            <CardContent>
                <div className="card-container">
                    <div className="header-question">
                        <Typography
                            sx={{
                                color: theme.palette.primary.main,
                                fontSize: "36px",
                                marginTop: "15px",
                            }}
                        >
                            What is the total weight of your cargo?
                        </Typography>
                    </div>
                    <div className="unlading-descriptive-text">
                        For those who aren't on the metric system, 1 kg = 2.205 lbs.
                    </div>
                    <div className="variable-value-text-field">
                        <TextField
                            required
                            fullWidth
                            id="cargo-value"
                            label=""
                            onChange={handleVariableAmount}
                            value={totalWeight || ""}
                            name="cargo-value"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="next-button-container">
                        <Button
                            fullWidth
                            className="back-button-text"
                            startIcon={<KeyboardArrowLeftIcon/>}
                            variant="text"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                        <Button
                            fullWidth
                            onClick={onNext}
                            className="next-button-green"
                            endIcon={<KeyboardArrowRightIcon/>}
                            variant="contained"
                            disabled={!inputIsValid()}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
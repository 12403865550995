import React, {useEffect} from "react";
import {Button, Card, CardContent, Typography} from "@mui/material";
import "./CompliancePgaSearch.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {ComplianceSummaryContext} from "common/interfaces/complianceSummary";

export interface Props {
    complianceSummary: ComplianceSummaryContext,
    onNext: () => void,
    onBack: () => void
}

export default function CompliancePgaSearch({complianceSummary, onNext, onBack}: Props) {

    const [existingPGAs, setExistingPGAs] = React.useState([]);
    let existingPGAsToUpdate: any = [];

    useEffect(() => {
        existingPGAsToUpdate = [];
        complianceSummary.partneringGovernmentAgencyOutput?.flags.forEach((row) => {
            if (
                !existingPGAsToUpdate.find(
                    (pga) => pga["PGA Agency"] === row["PGA Agency"]
                )
            ) {
                existingPGAsToUpdate.push(row);
            }
        });
        setExistingPGAs(existingPGAsToUpdate);
    }, [complianceSummary]);

    const renderPGAChips = (currentComplianceOneSummary: ComplianceSummaryContext) => {
        let pgaRowsToReturn: any = [];
        if ((currentComplianceOneSummary.partneringGovernmentAgencyOutput?.flags.length || 0) > 1) {
            pgaRowsToReturn = [
                <div style={{fontWeight: "bold", marginBottom: '24px'}}>
                    Your product has been flagged by {existingPGAs.length} government
                    agencies:
                </div>,
            ];
            currentComplianceOneSummary.partneringGovernmentAgencyOutput?.flags.forEach((row: any, index: number) => {
                if (
                    !existingPGAsToUpdate.find(
                        (pga) => pga["PGA Agency"] === row["PGA Agency"]
                    )
                ) {
                    existingPGAsToUpdate.push(row);
                    pgaRowsToReturn.push(
                        <div className="animate-oga">
                            <div className="exclusion-content"
                                 style={{textAlign: 'center'}}>{row["PGA Agency Name"]}</div>
                        </div>
                    );
                }
            });
        } else if ((currentComplianceOneSummary.partneringGovernmentAgencyOutput?.flags.length === 1)) {
            pgaRowsToReturn = [
                <div style={{fontWeight: "bold", marginBottom: '24px'}}>
                    Your product has been flagged by {existingPGAs.length} government
                    agency:
                </div>,
            ];
            currentComplianceOneSummary.partneringGovernmentAgencyOutput.flags.forEach((row: any, index: number) => {
                if (
                    !existingPGAsToUpdate.find(
                        (pga) => pga["PGA Agency"] === row["PGA Agency"]
                    )
                ) {
                    existingPGAsToUpdate.push(row);
                    pgaRowsToReturn.push(
                        <div className="animate-oga">
                            <div className="exclusion-content"
                                 style={{textAlign: 'center'}}>{row["PGA Agency Name"]}</div>
                        </div>
                    );
                }
            });
        } else {
            pgaRowsToReturn = [
                <div>
                    Your product is not currently flagged by any United States agencies.
                </div>
            ]
        }
        return pgaRowsToReturn;
    };

    return (
        <div className="card-container">
            <Card sx={{minWidth: 275}}>
                <CardContent>
                    <div className="header-question">
                        <Typography>
                            Partnering Government Agency Search
                        </Typography>
                    </div>
                    <div className="unlading-descriptive-text">
                        Partnering Government Agency (PGA) search is a review that we do to see if your cargo may be
                        regulated by other government agencies in addition to US Customs and
                        Border Protection. We'll highlight them briefly below but give you
                        a full breakdown on the summary page.
                    </div>
                    <div className="pga-content-wrapper">
                        <div className="pga-circle-container">
                            <div className="pga-chips-container">{renderPGAChips(complianceSummary)}</div>
                        </div>
                        <div className="next-button-container">
                            <Button
                                fullWidth
                                className="back-button-text"
                                startIcon={<KeyboardArrowLeftIcon/>}
                                variant="text"
                                onClick={onBack}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                onClick={onNext}
                                fullWidth
                                variant="contained"
                                className="next-button-green"
                                endIcon={<KeyboardArrowRightIcon/>}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';
import {useActivePlan} from "@/custom-hooks/plan/PlanProvider";
import {FeatureName} from 'common/interfaces/plan';

export default function LinearProgressBar() {

    const activePlan = useActivePlan();

    const [usage, setUsage] = useState<number>()

    useEffect(() => {

        if (activePlan.metadata) {
            const searchesFeatureUsage = activePlan.metadata.planUsage.find((feature) => feature.featureName === FeatureName.SEARCHES)
            setUsage(searchesFeatureUsage?.usage)
        } else {
            setUsage(0)
        }


    }, [activePlan.metadata]);

    const renderLinearValue = (searchCount) => {
        let valueToReturn = 0;
        if (searchCount <= 2) {
            valueToReturn = searchCount * 50;
        } else {
            valueToReturn = 100;
        }
        return valueToReturn;
    }

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 5,
        borderRadius: 2,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 2,
            backgroundColor: theme.palette.mode === 'light' ? '#ffd965' : '#ffd965',
        },
    }));

    return (
        <Box sx={{width: '100%'}}>
            <BorderLinearProgress variant="determinate" value={renderLinearValue(usage)}/>
        </Box>
    );
}
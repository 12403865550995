import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Button, IconButton, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './PGADescriptionModal.css';

export interface SimpleDialogProps {
  row: any,
  open: boolean;
  onClose: (e?: any) => void;
}

export default function PgaDescriptionModal(props: SimpleDialogProps) {
  const { onClose, open, row } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="contact-us-dialog-container">
      <Dialog
        maxWidth="lg"
        onClose={handleClose}
        open={open}
      >
        <div>
          <div>
            <div className="header-question">
              <Typography>
              {row["PGA Agency Name"]}
              </Typography>
            </div>
            <div className="pga-description-subheader">
            {row.PGA_Flag} Flag - {row["PGA Description"]}
            </div>
            <IconButton
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon sx={{fontSize: '24px'}} />
            </IconButton>
          </div>
          <div className="pga-description-title">Breaking it down</div>
          <div className="pga-description-subheader">{row["User Message"]}</div>
          <div className="pga-questions-container">
            <div>Questions? We're here - set up a time with one of our experts.</div>
            <div className="next-button-container">
            <a style={{textDecoration: "none"}} target="_blank" href="https://calendly.com/alex-0rfp/30min" rel="noreferrer"><Button sx={{minWidth: '200px'}} variant="contained" className="next-button-green">Set up a 1x1</Button></a>    
            </div>            
          </div>
        </div>
      </Dialog>
    </div>
  );
}

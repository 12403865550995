import React from 'react';
import {TransportationType} from "@/api";
import {Card, CardContent, ToggleButton, ToggleButtonGroup, Typography, useTheme} from "@mui/material";
import LightTooltip from "@/components/tooltip-component/TooltipComponent";
import InfoIcon from "@mui/icons-material/Info";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import FlightIcon from "@mui/icons-material/Flight";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import './ModeOfTransportation.css'

interface Props {
    modeOfTransportation?: TransportationType,
    onModeOfTransportationSelected: (tranportationType: TransportationType) => void,
    onNext: () => void,
    onBack: () => void
}

export default function ModeOfTransportation({
                                                 modeOfTransportation,
                                                 onModeOfTransportationSelected,
                                                 onNext,
                                                 onBack
                                             }: Props) {

    const theme = useTheme();
    const handleTransportationChange = (event, newTransportationType) => {
        if (newTransportationType !== null) {
            onModeOfTransportationSelected(newTransportationType)
        }
    };

    const inputIsValid = () => !!modeOfTransportation;

    return (
        <Card className="card-container">
            <CardContent>

                <div className="header-question">
                    <div className="transportation-header-container">
                        <Typography>
                            Select Transportation Type
                        </Typography>
                        <LightTooltip
                            title="The transportation type is how the cargo first arrives in the US (i.e. - A container is shipped from Yantian China via Ocean and arrives in Los Angeles = Ocean)">
                            <div className="tooltip-container">
                                <InfoIcon sx={{fontSize: "16px"}}/>
                            </div>
                        </LightTooltip>
                    </div>
                </div>
                <div className="transportation-type-descriptive-text">
                    All three transportation types are subject to the
                    <LightTooltip
                        title="The Merchandise Processing Fee (MPF) is a fee of .3464% which is multiplied by the cargo value you entered in step one, and has a minimum of $31.67 and a maximum of $614.35.">
                        <div className="tooltip-container">
                            <b>Merchandise Processing Fee</b>
                        </div>
                    </LightTooltip>
                    . Ocean-based calculations are subject to an additional
                    <LightTooltip
                        title="The Harbor Maintenance Fee (HMF) is a fee of .125% multiplied by the cargo value you entered in step one. This fee only applies to calculations arriving via ocean.">
                        <div className="tooltip-container">
                            <b>Harbor Maintenance Fee</b>.
                        </div>
                    </LightTooltip>
                </div>
                <div className="transportation-buttons-container">
                    <ToggleButtonGroup
                        value={modeOfTransportation}
                        exclusive
                        onChange={handleTransportationChange}
                        fullWidth
                        sx={{maxWidth: '750px'}}
                        size={'medium'}
                    >
                        <ToggleButton
                            value={TransportationType.OCEAN}
                            color={"primary"}
                            size={'medium'}
                            sx={{textTransform: 'none'}}
                        >
                            <div
                                className={'mode-of-transportation-button'}
                                style={{margin: theme.spacing(1), gap: theme.spacing(1)}}
                            >
                                <DirectionsBoatIcon fontSize={'large'}/>
                                <span
                                    style={{
                                        fontWeight: modeOfTransportation === TransportationType.OCEAN ? 700 : 400
                                    }}
                                >
                                    Ocean
                                </span>
                            </div>
                        </ToggleButton>
                        <ToggleButton
                            value={TransportationType.AIR}
                            color={"primary"}
                            size={'medium'}
                            sx={{textTransform: 'none'}}
                        >
                            <div
                                className={'mode-of-transportation-button'}
                                style={{margin: theme.spacing(1), gap: theme.spacing(1)}}
                            >
                                <FlightIcon fontSize={'large'}/>
                                <span
                                    style={{
                                        fontWeight: modeOfTransportation === TransportationType.AIR ? 700 : 400
                                    }}
                                >
                                    Air
                                </span>
                            </div>
                        </ToggleButton>
                        <ToggleButton
                            value={TransportationType.TRUCK}
                            color={"primary"}
                            size={'medium'}
                            sx={{textTransform: 'none'}}
                        >
                            <div
                                className={'mode-of-transportation-button'}
                                style={{margin: theme.spacing(1), gap: theme.spacing(1)}}
                            >
                                <LocalShippingIcon fontSize={'large'}/>
                                <span
                                    style={{
                                        fontWeight: modeOfTransportation === TransportationType.TRUCK ? 700 : 400
                                    }}
                                >
                                    Truck
                                </span>
                            </div>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <div className="next-button-container">
                    <Button
                        fullWidth
                        className="back-button-text"
                        startIcon={<KeyboardArrowLeftIcon/>}
                        variant="text"
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        fullWidth
                        onClick={onNext}
                        className="next-button-green"
                        endIcon={<KeyboardArrowRightIcon/>}
                        variant="contained"
                        disabled={!inputIsValid()}
                    >
                        Next
                    </Button>
                </div>

            </CardContent>
        </Card>
    );
}

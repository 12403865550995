import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
    Badge,
    BadgeProps,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TableFooter,
    TextField,
    InputAdornment,
    Paper,
} from '@mui/material';
import HoverState from '@/shared-components/HoverState';
import { FilterAlt, Search, Add } from '@mui/icons-material';
import { styled } from '@mui/system';
import {
    createTableFilterFunction,
    createTableSortFunction,
    FilterFunction,
    noFilter,
    noSort,
    SortFunction,
} from 'common/utilities/importalTable';
import TableColumnFilterConfigButtonAndPopover from '@/pages/product-library/TableColumnFilterConfigButtonAndPopover';
import {
    ColumnConfig,
    ColumnFilterConfig,
    ColumnSortConfig,
    SortDirection,
    TableConfigState,
    TableFilterConfig,
    TableSortConfig,
} from 'common/interfaces/importalTable';
import './ImportalTable.css';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';

const SortOrderBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        padding: '4px',
        transform: 'translate(0px, 0px) scale(0.7)',
    },
}));

const FilterAppliedBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        padding: '4px',
        transform: 'translate(0px, 0px) scale(0.7)',
    },
}));

interface Props<T> {
    data: Array<T>;
    rowGenerator?: (rowData: T, index: number) => React.JSX.Element;
    columnConfig: Array<ColumnConfig>;
    topRightElement?: React.JSX.Element;
    subKeyForData?: string;
    embeddedStyling?: boolean;
    loading?: boolean;
    defaultRowsPerPage?: number;
    hasRowExpand?: boolean;
    topRightButton?: React.JSX.Element;
    useSearch?: boolean;
}

export interface ImportalTableHandles {
    getTableConfig: () => TableConfigState;
}

const DefaultRowGenerator = (rowData: any, index: number) => {
    return (
        <TableRow key={`default-row-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell />
            {Object.keys(rowData).map((key) => {
                return <TableCell>{rowData[key]}</TableCell>;
            })}
        </TableRow>
    );
};

const ImportalTable = forwardRef<ImportalTableHandles, Props<any>>(
    (
        {
            data = [],
            rowGenerator,
            columnConfig,
            topRightElement,
            subKeyForData,
            embeddedStyling = false,
            loading = false,
            defaultRowsPerPage = 20,
            hasRowExpand = true,
            topRightButton,
            useSearch = true,
        },
        ref
    ) => {
        const [searchQuery, setSearchQuery] = useState<string>('');
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
        const [tableFilterConfig, setTableFilterConfig] = useState<TableFilterConfig>({});
        const [filterFunction, setFilterFunction] = useState<FilterFunction>(() => noFilter);
        const [tableSortConfig, setTableSortConfig] = useState<TableSortConfig>([]);
        const [sortFunction, setSortFunction] = useState<SortFunction>(() => noSort);
        const [sortedAndFilteredData, setSortedAndFilteredData] = useState<any[]>([]);

        useImperativeHandle(ref, () => ({
            getTableConfig() {
                return {
                    columns: columnConfig,
                    subKeyForData,
                    sortConfig: tableSortConfig,
                    filterConfig: tableFilterConfig,
                };
            },
        }));

        useEffect(() => {
            setFilterFunction(() => createTableFilterFunction(tableFilterConfig, subKeyForData));
        }, [tableFilterConfig]);

        useEffect(() => {
            setSortFunction(() => createTableSortFunction(columnConfig, tableSortConfig, subKeyForData));
        }, [tableSortConfig]);

        useEffect(() => {
            if (data && data.length > 0) {
                let filteredData = data.filter(filterFunction).sort(sortFunction);

                if (searchQuery) {
                    filteredData = filteredData.filter((row) =>
                        Object.values(subKeyForData ? row[subKeyForData] : row).some((value) => String(value).toLowerCase().includes(searchQuery.toLowerCase()))
                    );
                }

                setSortedAndFilteredData(filteredData);
            }
        }, [data, sortFunction, filterFunction, searchQuery]);

        const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value);
            setPage(0); // Reset to first page when search query changes
        };

        const handleChangePage = useMemo(
            () => (event: unknown, newPage: number) => {
                setPage(newPage);
            },
            [setPage]
        );

        const handleChangeRowsPerPage = useMemo(
            () => (event: React.ChangeEvent<HTMLInputElement>) => {
                setRowsPerPage(+event.target.value);
                setPage(0);
            },
            [setRowsPerPage, setPage]
        );

        const getColumnFiterConfig = (columnName: string): ColumnFilterConfig => {
            return tableFilterConfig[columnName];
        };

        const createRowGeneratorForColumnConfig = (
            columnConfig: ColumnConfig[]
        ): ((rowData: any, index: number) => React.JSX.Element) => {
            return (rowData: any, index: number) => {
                return (
                    <TableRow key={`default-row-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell />
                        {columnConfig.map((columnDefinition) => {
                            return <TableCell>{rowData[columnDefinition.name]}</TableCell>;
                        })}
                    </TableRow>
                );
            };
        };

        const onFilterConfigChanged = (columnFilterConfig: ColumnFilterConfig) => {
            const newColumnFilterConfig = { ...tableFilterConfig };
            newColumnFilterConfig[columnFilterConfig.columnName] = columnFilterConfig;
            setTableFilterConfig(newColumnFilterConfig);
        };

        const onClickColumnSort = (columnName: string) => {
            const matchesName = (sortConfig: ColumnSortConfig) => sortConfig.columnName === columnName;
            const newTableSortConfig = [...tableSortConfig];

            const currentSortDirection = tableSortConfig.find(matchesName);
            if (currentSortDirection && currentSortDirection.direction === SortDirection.asc) {
                // set to desc
                newTableSortConfig[newTableSortConfig.findIndex(matchesName)]['direction'] = SortDirection.desc;
            } else if (currentSortDirection && currentSortDirection.direction === SortDirection.desc) {
                // remove entirely
                newTableSortConfig.splice(newTableSortConfig.findIndex(matchesName), 1);
            } else {
                // set to asc
                newTableSortConfig.push({
                    columnName: columnName,
                    direction: SortDirection.asc,
                });
            }

            setTableSortConfig(newTableSortConfig);
        };

        const isColumnFiltered = (columnName: string): boolean | undefined => {
            return (
                tableFilterConfig[columnName] &&
                tableFilterConfig[columnName].allowedValues &&
                tableFilterConfig[columnName].allowedValues.length > 0
            );
        };

        const getNumberOfFiltersForColumn = (columnName: string): number | undefined => {
            if (
                tableFilterConfig[columnName] &&
                tableFilterConfig[columnName].allowedValues &&
                tableFilterConfig[columnName].allowedValues.length > 0
            ) {
                return tableFilterConfig[columnName].allowedValues.length;
            } else {
                return undefined;
            }
        };

        const getTableSortLabel = (columnConfig: ColumnConfig): React.JSX.Element => {
            return (
                <TableSortLabel
                    active={isSorted(columnConfig.name)}
                    direction={getColumnSortDirection(columnConfig.name)}
                    onClick={() => onClickColumnSort(columnConfig.name)}
                >
                    {columnConfig.displayName || columnConfig.name}
                </TableSortLabel>
            );
        };

        const isSorted = (columnName: string): boolean => {
            return -1 !== tableSortConfig.findIndex((columnSortConfig) => columnSortConfig.columnName === columnName);
        };

        const getColumnSortDirection = (columnName: string): SortDirection | undefined => {
            return tableSortConfig.find((columnSortConfig) => columnSortConfig.columnName === columnName)?.direction;
        };

        const getSortOrderForColumn = (columnName: string): number | undefined => {
            const foundIndex = tableSortConfig.findIndex((columnSortConfig) => columnSortConfig.columnName === columnName);
            return foundIndex !== -1 ? foundIndex + 1 : undefined;
        };

        const headerCellStyle = {
            whiteSpace: 'nowrap',
            textAlign: 'left',
            fontWeight: '600',
        };

        const getTableStyling = (): CSSProperties => {
            if (embeddedStyling) {
                return {
                    borderColor: 'rgba(173,165,165,0.61)',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    backgroundColor: '#eeeeee',
                    borderRadius: '8px',
                };
            }
            return {};
        };

        return (
            <div className="importal-table-and-search-container">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '10px',
                        gap: '8px'
                    }}
                >
                    {useSearch && (
                        <Paper
                            sx={{
                                minWidth: '350px',
                                paddingLeft: '10px',
                            }}
                        >
                            <TextField
                                variant='outlined'
                                autoComplete='off'
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Paper>
                    )}
                    {topRightButton}
                </div>
                <Paper>
                <TableContainer className='importal-table-container' style={{ ...getTableStyling() }}>
                    <div style={{ overflowX: 'scroll', maxWidth: '100%' }}>
                        <Table className='importal-table'>
                            <TableHead className='importal-table-header'>
                                <TableRow className='importal-table-header-row'>
                                    {hasRowExpand &&
                                        <TableCell
                                            className='importal-table-header-row-cell'
                                            id='expand-spacer'
                                        />
                                    }
                                    {columnConfig.map((fieldConfig, index) => (
                                        <React.Fragment key={`field-${index}`}>
                                            <HoverState>
                                                {(isHovered, bindHover) => (
                                                    <TableCell
                                                        sx={headerCellStyle}
                                                        className={`importal-table-header-row-cell ${fieldConfig.sortable && 'sorting'
                                                            } ${fieldConfig.filterable && 'filtering'}`}
                                                        key={fieldConfig.name}
                                                        {...bindHover}
                                                    >
                                                        {!fieldConfig.sortable &&
                                                            (fieldConfig.displayName || fieldConfig.name)}
                                                        {fieldConfig.sortable && (
                                                            <SortOrderBadge
                                                                badgeContent={getSortOrderForColumn(fieldConfig.name)}
                                                                color='primary'
                                                            >
                                                                {getTableSortLabel(fieldConfig)}
                                                            </SortOrderBadge>
                                                        )}
                                                        {fieldConfig.filterable && (
                                                            <TableColumnFilterConfigButtonAndPopover
                                                                fieldConfig={fieldConfig}
                                                                data={
                                                                    subKeyForData
                                                                        ? data.map((datum) => datum[subKeyForData])
                                                                        : data
                                                                }
                                                                onFilterConfigChanged={onFilterConfigChanged}
                                                                columnFilterConfig={getColumnFiterConfig(fieldConfig.name)}
                                                                triggerElement={
                                                                    <FilterAppliedBadge
                                                                        badgeContent={getNumberOfFiltersForColumn(fieldConfig.name)}
                                                                        color='primary'
                                                                    >
                                                                        <IconButton
                                                                            style={{
                                                                                opacity:
                                                                                    isHovered ||
                                                                                        isColumnFiltered(fieldConfig.name)
                                                                                        ? '1.0'
                                                                                        : '0.0',
                                                                            }}
                                                                        >
                                                                            <FilterAlt />
                                                                        </IconButton>
                                                                    </FilterAppliedBadge>
                                                                }
                                                            />
                                                        )}
                                                    </TableCell>
                                                )}
                                            </HoverState>
                                        </React.Fragment>
                                    ))}
                                    {topRightElement && (
                                        <TableCell
                                            className='importal-table-header-row-cell'
                                            sx={headerCellStyle}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {topRightElement}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedAndFilteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((datum, index) =>
                                        (
                                            rowGenerator ||
                                            (columnConfig
                                                ? createRowGeneratorForColumnConfig(columnConfig)
                                                : DefaultRowGenerator)
                                        )(datum, index)
                                    )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        className='table-pagination'
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        count={sortedAndFilteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>
            </div >
        );
    }
);

export default ImportalTable;

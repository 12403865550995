import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import "./BrokerAuthorizedSigner.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useAPI } from "@/api/APIContext";
import {useActiveBusiness} from "@/custom-hooks/business/BusinessProvider";
import { useActiveUser } from "@/custom-hooks/user/UserProvider";
import {Business, BusinessWithDocuments} from 'common/interfaces/business';
import { CheckBox, TitleTwoTone } from "@mui/icons-material";
import { CustomsOnboardingSteps } from "common/interfaces/customsOnboarding";

export default function BrokerAuthorizedSigner({ handleEvent, handleBack, handleLoading, updateCustomsOnboarding, customsOnboarding }) {
  const theme = useTheme();
  const [businessStructure, setBusinessStructure] = useState<string | undefined>("");
  const [businessStructureError, setBusinessStructureError] = useState("");
  const [ein, setEIN]: any = useState("");
  const [einError, setEINError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [address, setAddress] = useState<string | undefined>("");
  const [signeeTitle, setSigneeTitle] = useState<string | undefined>("");
  const [state, setState] = useState<String>("");
  const [stateError, setStateError] = useState<String>("");
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState<BusinessWithDocuments>();
  const api = useAPI();
  const activeBusiness = useActiveBusiness();
  const user = useActiveUser();
  const [isAuthorizedSigner, setIsAuthorizedSigner] = useState(true);
  const [authorizedSignerInfo, setAuthorizedSignerInfo] = useState({
    fullName: "",
    title: "",
    email: "",
  });
  const [mainStakeholderInfo, setMainStakeholderInfo] = useState({
    fullName: `${user.user?.firstName} ${user.user?.lastName}`,
    title: "",
    email: `${user.user?.email}` 
  })
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [currentTitles, setCurrentTitles] = useState([]);
  const [currentTitle, setCurrentTitle] = useState("");

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsEmailValid(validateEmail(inputEmail));
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  function fetchBusiness() {
    api.getActiveBusiness()
    .then(({data}) => {
      setCurrentBusiness(data[0]);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    if (isAuthorizedSigner) {
      setIsNextDisabled(false);
    } else {
      if (isEmailValid) {
        setIsNextDisabled(false)
      } else {
        setIsNextDisabled(true)
      }
    }
  }, [isAuthorizedSigner, isEmailValid])


  const handleAuthorizedSignerClick = (e) => {
    if (e.target.value === "Yes") {
      setIsNextDisabled(false);
      setIsAuthorizedSigner(true);
    } else {
      setIsAuthorizedSigner(false);
      setIsNextDisabled(true);
    }
  }

  const handleAuthorizeSignerInfoChange = (e) => {
    const { name, value } = e.target;
    setAuthorizedSignerInfo({
      ...authorizedSignerInfo,
      [name]: value
    })
  }

  const handleNext = (e) => {
    if (isAuthorizedSigner) {
      updateCustomsOnboarding(activeBusiness.business?._id, { authorizedSigner: authorizedSignerInfo, step: CustomsOnboardingSteps.PHOTO_ID});
    } else {
      updateCustomsOnboarding(activeBusiness.business?._id, { authorizedSigner: authorizedSignerInfo, mainStakeholder: mainStakeholderInfo, step: CustomsOnboardingSteps.PHOTO_ID })
    }
    handleEvent();
  };

  const renderAuthorizedSignerEmailSection = () => {
      return (
        <>
        <div className="authorized-signer-email-header">Authorized Signer Information</div>
        <div className="authorized-signer-email-container">
          <div className="as-form-field-container">
            <div className="business-form-field-label">
              <Typography>Full Name</Typography>
            </div>
            <TextField
              fullWidth
              name="fullName"
              size="small"
              onChange={handleAuthorizeSignerInfoChange}
              value={authorizedSignerInfo.fullName}
            />
          </div>
          <div className="as-form-field-container">
            <div className="business-form-field-label">
              <Typography>Title</Typography>
            </div>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                fullWidth
                name="title"
                onChange={handleAuthorizeSignerInfoChange}
                value={authorizedSignerInfo.title}
                size="small"
              >
                  {currentTitles.map((title, index) => (
                      <MenuItem key={index+1} value={title}>{title}</MenuItem>
                  ))}
              </Select>
        </FormControl>
          </div>
          <div className="as-form-field-container">
            <div className="business-form-field-label">
              <Typography>Email Address</Typography>
            </div>
            <TextField
              fullWidth
              name="email"
              onChange={handleAuthorizeSignerInfoChange}
              size="small"
              value={authorizedSignerInfo.email}
            />
          </div>
        </div>
        </>
      )
  }


  const handleNextClick = (e) => {
    if (isAuthorizedSigner) {
      handleNext(e)
    } else {
      // handleUpdateCustomsOnboardingStep()
    }
  }

  useEffect(() =>(fetchBusiness()), []);

  useEffect(() => {
    if (currentBusiness !== undefined) {
      setEIN(currentBusiness.ein || "");
      setName(currentBusiness.name);
      setBusinessStructure(currentBusiness.businessStructure);
      setAddress(currentBusiness.address);
      setSigneeTitle(currentBusiness.signeeTitle);
    }
  }, [currentBusiness])

  useEffect(() => {
    generateSignerList()
  }, [activeBusiness])

  const generateSignerList = () => {
    let titlesToReturn: any = [];
    switch (activeBusiness.business?.businessStructure) {
      case "Limited Liability Company": 
        titlesToReturn = ["Registered Owner", "Managing Member"];
        break;
      case "Partnership":
        titlesToReturn = ["Partner", "General Partner"];
        break;
      case "Corporation":
        titlesToReturn = ["Vice President", "Assistant Vice President", "Treasurer", "Assistant Treasurer", "General Counsel", "Assistant General Counsel",
          "Corporate Secretary", "Owner", "President", "Chairman of the Board", "CEO", "CIO", "CFO", "COO"
        ];
    };
    setCurrentTitles(titlesToReturn);
}

  return (
    <div>
      <div className="customs-form-field-container">
        {renderAuthorizedSignerEmailSection()}
      </div>
      <div className="next-button-container">
        <Button
          fullWidth
          className="back-button-text"
          variant="text"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          type="submit"
          color="info"
          size="medium"
          onClick={handleNext}
          variant="contained"
          className="next-button-green"
          endIcon={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

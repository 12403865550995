import { Box, Tab, Tabs, Card } from "@mui/material";
import React, { useState, useCallback, memo } from "react";
import { TabPanelProps } from "@mui/joy";

export interface TabConfig {
    title: string;
    element: React.JSX.Element;
}

export interface Props {
    tabsConfig: TabConfig[];
}

const CustomTabPanel = memo((props: TabPanelProps & { index: number; value: number }) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
});

export default function ImportalTabs({ tabsConfig }: Props) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue);
    }, []);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Tabs
                    value={selectedTabIndex}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{ sx: { display: 'none' } }} // Hide default tab indicator
                >
                    {tabsConfig.map((tabConfig, index) => (
                        <Tab
                            key={index}
                            sx={{
                                textTransform: "none",
                                fontSize: "16px",
                                fontWeight: selectedTabIndex === index ? "700" : "normal",
                                backgroundColor: selectedTabIndex === index ? "white" : "transparent",
                                borderRadius: "10px 10px 0px 0px",
                                boxShadow: selectedTabIndex === index ? "rgba(0, 0, 0, 0.1) 0px 4px 12px" : "none",
                                zIndex: selectedTabIndex === index ? 1 : 0,
                            }}
                            label={tabConfig.title}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Box>
            {tabsConfig.map((tabConfig, index) => (
                <CustomTabPanel key={index} value={selectedTabIndex} index={index}>
                    {tabConfig.element}
                </CustomTabPanel>
            ))}
        </>
    );
}





import React, {useState} from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import axios from "axios";
import * as ls from "local-storage";
import './Profile.css';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email("Enter valid email")
        .required("Email is required"),
    phone: yup
        .string()
        .trim()
        .matches(phoneRegExp, "Phone Number is not valid")
        .required("Phone Number is required"),
});

const Profile = ({onPassworDialogue, handleLoading}) => {
    const [phnChange, setPhnChange] = useState(false);

    const [user, setUser] = useState<{
        email: string;
        _id: string;
        phone: string;
    }>(ls.get("user"));

    const phoneChange = (e) => {
        setPhnChange(true);
        formik.handleChange(e);
    };

    const formik = useFormik({
        initialValues: {
            email: user?.email,
            phone: user?.phone,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            handleLoading(true);
            axios
                .put(
                    `${process.env.REACT_APP_API_BASE_URL}/users/${user?._id}`,
                    values,
                    {headers: {Authorization: ls.get("token")}}
                )
                .then(function (response) {
                    console.log(response.data);
                    setUser(response.data);
                    ls.set("user", response.data);
                    handleLoading(false);
                })
                .catch(function (error) {
                    console.error(error);
                    handleLoading(false);
                });
        },
    });

    return (
        <Card style={{marginTop: '0.5rem', marginBottom: '5px'}}>
            <CardContent>
                <div className="header-question">
                    <Typography>Profile</Typography>
                </div>
                <div className="settings-profile-main-content">
                    <div>
                        <div className="settings-universal-subheader">Email</div>
                        <div className="universal-subheader-two">{formik.initialValues.email}</div>
                    </div>
                    <div>
                        <div className="settings-universal-subheader">Phone</div>
                        <div className="universal-subheader-two">{formik.initialValues.phone}</div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default Profile;

import { Action, ActionType, CreateShipmentAction, ShipmentTrackingActionProperties, UpdateShipmentAction, UpdateShipmentTrackingActionProperties } from "common/interfaces/action";
import { Shipment, vizionSupportedCarriers } from "common/interfaces/shipment";
import moment from "moment-timezone";
import React from "react";

export function formatHumanReadableDate(dateString) {
    let formattedDate;

    // Define the target time zone
    const targetTimeZone = 'America/New_York';

    // Check if the date string matches ISO 8601 format with time zone
    if (moment(dateString, moment.ISO_8601, true).isValid()) {
        // Parse the date with time zone and convert to Eastern Time
        const dateWithTimezone = moment.parseZone(dateString).tz(targetTimeZone);
        formattedDate = dateWithTimezone.format('M/D/YY');
    }
    // Check if the date string matches MM/DD/YY format
    else if (moment(dateString, 'MM/DD/YY', true).isValid()) {
        // Parse the date assuming it's in local time zone and convert to Eastern Time
        const localDate = moment(dateString, 'M/DD/YY').tz(targetTimeZone);
        formattedDate = localDate.format('MMMM Do YYYY');
    }
    else {
        return dateString;
    }

    return formattedDate;
}

export function howLongAgo(dateString: string): string {
    const now = moment().tz('America/New_York');
    let date;


    if (moment(dateString, moment.ISO_8601, true).isValid()) {
        date = moment.parseZone(dateString).tz('America/New_York');
    }

    else if (moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
        date = moment.utc(dateString).tz('America/New_York');
    }

    else if (moment(dateString, 'MM/DD/YY', true).isValid()) {
        date = moment(dateString, 'MM/DD/YY').tz('America/New_York');
    }
    else {
        return "";
    }

    const diffDays = now.diff(date, 'days');

    if (diffDays === 0) {
        return "today";
    } else if (diffDays === 1) {
        return "yesterday";
    } else if (diffDays === 2) {
        return "two days ago";
    } else if (diffDays === 3) {
        return "three days ago";
    } else if (diffDays === 4) {
        return "four days ago";
    } else if (diffDays === 5) {
        return "five days ago";
    } else if (diffDays === 6) {
        return "six days ago";
    } else if (diffDays <= 7) {
        return "last week";
    } else {
        return "more than one week ago";
    }
}

export const shipmentDateCategorizer = (value: string | undefined): string => {
    if (value === undefined) return 'Empty'; // Put undefined dates at the bottom

    // Parse the date using moment-timezone
    const date = moment.tz(value, [
        "MM/DD/YYYY",
        "M/D/YYYY",
        moment.ISO_8601
    ], true, 'America/New_York');

    if (date.isValid()) {
        return formatHumanReadableDate(date);
    }

    return 'Empty'; // Invalid dates go to the bottom
};

export const shipmentDateSortFunction = (a: string, b: string): number => {
    const dateA = moment(a, ['MM/DD/YY', 'M/D/YY'], true); // Support both formats and strict parsing
    const dateB = moment(b, ['MM/DD/YY', 'M/D/YY'], true);

    // Check if both dates are valid
    if (!dateA.isValid() && !dateB.isValid()) {
        return 0; // Both are invalid, consider them equal
    }
    if (!dateA.isValid()) {
        return 1; // Place invalid dateA after valid dateB
    }
    if (!dateB.isValid()) {
        return -1; // Place invalid dateB after valid dateA
    }

    // Compare valid dates, placing the most recent date first
    const diff = dateB.diff(dateA);
    return diff === 0 ? 0 : diff > 0 ? 1 : -1; // Normalize the difference to -1, 0, or 1
};


export const renderArrayWithCommas = (values) => {
    if (!Array.isArray(values)) {
        return null;
    }

    return values.map((value, index) => (
        <React.Fragment key={index}>
            {value}
            {index < values.length - 1 && ', '}
        </React.Fragment>
    ));
}

export const generateShipmentChangeText = (shipmentAction: CreateShipmentAction | UpdateShipmentAction): [string, number] => {

    const diff = shipmentAction.properties || {} as Shipment<any>;
    const changedProperties = Object.keys(diff);

    if (shipmentAction.type === ActionType.CREATE_SHIPMENT) {
        return [`Shipment was created with ${changedProperties.length} values added.`, changedProperties.length];
    } else if (shipmentAction.type === ActionType.UPDATE_SHIPMENT) {
        const propertiesText = changedProperties.length > 1
            ? `${changedProperties.slice(0, -1).join(', ')} and ${changedProperties.slice(-1)}`
            : changedProperties[0];

        return [`Shipment ${propertiesText} ${changedProperties.length > 1 ? 'were' : 'was'} updated.`, changedProperties.length];
    }

    return ['', 0];
}

export const generateShipmentTrackingChangeText = (
    action: Action<ShipmentTrackingActionProperties>
): [string, number] => {
    let changeCount = 0;

    if (action.type === ActionType.CREATE_TRACKING) {
        // Handle the CreateShipmentTrackingAction case
        // Since CreateShipmentTrackingActionProperties is an empty object, just return a generic creation message
        return ["Tracking reference created.", changeCount];
    }

    if (action.type === ActionType.UPDATE_TRACKING) {
        const trackingAction = action.properties as UpdateShipmentTrackingActionProperties;
        
        // Ensure trackingAction is not empty
        if (!trackingAction || Object.keys(trackingAction).length === 0) {
            return ["No changes detected in tracking update.", changeCount];
        }

        const etdText = trackingAction.etd ? `ETD was updated to ${formatHumanReadableDate(trackingAction.etd)}` : "";
        const etaText = trackingAction.eta ? `ETA was updated to ${formatHumanReadableDate(trackingAction.eta)}` : "";
        let statusText = '';

        if (etdText && !etaText && !trackingAction.departed && !trackingAction.arrived) {
            return [`${etdText}.`, 1];
        }

        if (!etdText && etaText && !trackingAction.departed && !trackingAction.arrived) {
            return [`${etaText}.`, 1];
        }

        if (!etdText && !etaText && trackingAction.departed && !trackingAction.arrived) {
            return ["The shipment has departed the origin port.", 1];
        }

        if (!etdText && !etaText && !trackingAction.departed && trackingAction.arrived) {
            return ["The shipment has arrived at the destination port.", 1];
        }

        // If multiple fields are updated, use the general update message.
        if (trackingAction.departed && trackingAction.arrived) {
            statusText = "The shipment has departed and arrived.";
        } else if (trackingAction.departed && !trackingAction.arrived) {
            statusText = "The shipment has departed but has not arrived yet.";
        } else if (!trackingAction.departed && trackingAction.arrived) {
            statusText = "The shipment has not departed yet but has arrived.";
        }

        const generalUpdateMessage = `Shipment tracking updated. ${etdText}, ${etaText}, and ${statusText}`;
        changeCount = [trackingAction.etd, trackingAction.eta].filter(Boolean).length;
        if (trackingAction.departed) changeCount++;
        if (trackingAction.arrived) changeCount++;
        return [generalUpdateMessage.trim(), changeCount];
    }

    // Default return if action type doesn't match expected types
    return ['', changeCount];
};




export const findCarrierCodeInMbl = (mbl: string): string | null => {
    for (const carrier of vizionSupportedCarriers) {
        if (mbl.includes(carrier.code)) {
            if (carrier.code === 'MEDU') {
                return 'MSCU';
            } else {
                return carrier.code;
            }

        }
    }
    return null;
}









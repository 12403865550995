import React, { useContext, useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Link, MenuItem, Paper, TextField, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import "./ShipmentsTable.css"
import ImportalTable from "@/shared-components/ImportalTable/ImportalTable";
import { ColumnConfig } from 'common/interfaces/importalTable';
import { useLocation } from "react-router-dom";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import FloatingTooltip from "@/shared-components/FloatingTooltip/FloatingTooltip";
import { BillingStatus, InternalShipmentStatus, InternalShipmentStatusColor, InternalShipmentStatusText, Shipment, ShipmentTransportationType, ShipmentTransportationTypeText } from "common/interfaces/shipment";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FlightIcon from '@mui/icons-material/Flight';
import TrainIcon from '@mui/icons-material/Train';
import EditIcon from "@mui/icons-material/Edit";
import { formatHumanReadableDate, shipmentDateCategorizer, shipmentDateSortFunction } from "@/utils/shipmentUtils";
import { Mode } from "common/interfaces/business";
import { LoadingStatus } from "@/components/data-import-loader/DataImportLoader";
import { useAPI } from "@/api/APIContext";
import { ShipmentsContext } from "./Shipments";
import ImportalPrimaryButton from "@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton";
import { Add } from "@mui/icons-material";


function createData(
    {
        _id,
        referenceNumber,
        partnerReferenceNumber,
        businessName,
        internalStatus,
        mbl,
        hbl,
        etd,
        eta,
        dueDate,
        isfOnFile,
        customsCleared,
        notes,
        type,
        billing,
        billed
    }: any,
    Id: number
) {

    return {
        _id,
        referenceNumber,
        partnerReferenceNumber,
        businessName,
        internalStatus,
        mbl,
        hbl,
        etd,
        eta,
        dueDate,
        isfOnFile,
        customsCleared,
        notes,
        type,
        billing,
        billed
    };
}

const Row = (props: any) => {
    const { row, api } = props;
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const [shipment, setShipment] = useState<any>({} as any);
    const [entry, setEntry] = useState<any>({} as any);
    const { refreshShipments, setLoadingStatus, setLoadingText, setSuccessText, setErrorText } = useContext(ShipmentsContext);

    const [modifyMode, setModifyMode] = useState<any>({
        internalStatus: { mode: Mode.VIEW, hover: false },
        isfOnFile: { mode: Mode.VIEW, hover: false },
        customsCleared: { mode: Mode.VIEW, hover: false },
        notes: { mode: Mode.VIEW, hover: false },
        billing: { mode: Mode.VIEW, hover: false },
        billed: { mode: Mode.VIEW, hover: false },
    });

    useEffect(() => {
        setShipment(row);
    }, [row]);

    const renderISFInformation = (shipment) => {
        if (shipment.type === ShipmentTransportationType.AIR || shipment.type === ShipmentTransportationType.TRUCK) {
            return (<>N/A</>)
        } else {
            return shipment.isfOnFile ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
        }
    }

    const renderBooleanIcons = (property: boolean) => {
        if (property) {
            return <CheckIcon style={{ color: 'green' }} />;
        } else {
            return <CloseIcon style={{ color: 'red' }} />;
        }
    }

    const updateShipment = (shipment, name) => {
        setLoadingText('Updating shipment...');
        setLoadingStatus(LoadingStatus.LOADING);
        setShipment(shipment);
        api.updateShipment(shipment._id, shipment).then((response) => {
            refreshShipments();
            setModifyMode(prev => ({ ...prev, [name]: { ...prev[name], mode: Mode.VIEW } }))
            setSuccessText(`${shipment.referenceNumber} updated successfully`);
            setLoadingStatus(LoadingStatus.SUCCESS);
        }).catch((error) => {
            setModifyMode(prev => ({ ...prev, [name]: { ...prev[name], mode: Mode.VIEW } }))
            setErrorText(`Error updating shipment ${shipment.referenceNumber}`);
            setLoadingStatus(LoadingStatus.ERROR);
        })
    }

    const renderIconBasedOnTransportType = (type: ShipmentTransportationType) => {
        switch (type) {
            case ShipmentTransportationType.OCEAN:
                return <FloatingTooltip title="Ocean" placement="top">
                    <DirectionsBoatIcon sx={{ fontSize: '20px' }} />
                </FloatingTooltip>
            case ShipmentTransportationType.AIR:
                return <FloatingTooltip title="Air" placement="top">
                    <FlightIcon />
                </FloatingTooltip>
            case ShipmentTransportationType.TRUCK:
                return <FloatingTooltip title="Truck" placement="top">
                    <LocalShippingIcon />
                </FloatingTooltip>
            case ShipmentTransportationType.RAIL:
                return <FloatingTooltip title="Rail" placement="top">
                    <TrainIcon />
                </FloatingTooltip>
            default:
                break;
        }
    }

    const renderStatusChip = (status: InternalShipmentStatus) => {
        return (
            <div className="status-chip-container">
                <Chip
                    label={InternalShipmentStatusText[status]}
                    sx={{
                        backgroundColor: InternalShipmentStatusColor[status],
                        color: "white",
                        fontWeight: "bold",
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        height: '26px'
                    }}
                />
            </div>
        )
    }

    const handleReferenceNumberClick = (id: string) => {
        const basePath = "/broker/dashboard/shipments";
        const url = `${basePath}/${id}`;
        window.open(url, '_blank');
    };

    const handleEntryChange = (name, value) => {
        setEntry({ ...entry, [name]: value });
    }

    const handleShipmentChange = (name, value) => {
        setShipment({ ...shipment, [name]: value });
    }

    const renderField = (name, value) => {
        // Ensure non-empty rendering even when value is an empty string
        const displayValue = value === "" ? "\u00A0" : value; // Use a non-breaking space as a fallback

        if (modifyMode[name]?.mode === Mode.EDIT) {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(() => {
                        switch (name) {
                            case 'billed':
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        variant="standard"
                                        value={value ? 'Yes' : 'No'}
                                        onChange={(e) => handleShipmentChange(name, e.target.value === 'Yes')}
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </TextField>
                                );
                            case 'isfOnFile':
                            case 'customsCleared':
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        variant="standard"
                                        value={value ? 'Yes' : 'No'}
                                        onChange={(e) => handleShipmentChange(name, e.target.value === 'Yes')}
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </TextField>
                                );
                            case 'type':
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        variant="standard"
                                        value={value}
                                        onChange={(e) => handleShipmentChange(name, e.target.value)}
                                    >
                                        <MenuItem value={ShipmentTransportationType.OCEAN}>Ocean</MenuItem>
                                        <MenuItem value={ShipmentTransportationType.AIR}>Air</MenuItem>
                                        <MenuItem value={ShipmentTransportationType.TRUCK}>Truck</MenuItem>
                                        <MenuItem value={ShipmentTransportationType.RAIL}>Rail</MenuItem>
                                    </TextField>
                                );
                            case 'entryNumber':
                                return (
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => handleEntryChange(name, e.target.value)}
                                        value={value}
                                    />
                                );
                            case 'internalStatus':
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        variant="standard"
                                        value={value}
                                        onChange={(e) => handleShipmentChange(name, e.target.value)}
                                    >
                                        {Object.keys(InternalShipmentStatus).map((statusKey) => (
                                            <MenuItem key={statusKey} value={InternalShipmentStatus[statusKey]}>
                                                {InternalShipmentStatusText[statusKey]}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                );
                            default:
                                return (
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => handleShipmentChange(name, e.target.value)}
                                        value={value}
                                    />
                                );
                        }
                    })()}
                    <IconButton onClick={() => setModifyMode(prev => ({ ...prev, [name]: { ...prev[name], mode: Mode.VIEW } }))} size="small">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                        <CheckIcon sx={{ color: 'green' }} onClick={() => updateShipment(shipment, name)} fontSize="small" />
                    </IconButton>
                </div>
            );
        } else {
            return (
                modifyMode[name] ? (
                    <div
                        onMouseEnter={() => setModifyMode(prev => ({ ...prev, [name]: { ...prev[name], hover: true } }))}
                        onMouseLeave={() => setModifyMode(prev => ({ ...prev, [name]: { ...prev[name], hover: false } }))}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <FloatingTooltip title="Edit">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(() => {
                                    switch (name) {
                                        case 'type':
                                            return renderIconBasedOnTransportType(value);
                                        case 'isfOnFile':
                                            return renderISFInformation(shipment);
                                        case 'customsCleared':
                                            return renderBooleanIcons(shipment.customsCleared)
                                        case 'billed':
                                            return renderBooleanIcons(shipment.billed)
                                        case 'internalStatus':
                                            return renderStatusChip(value);
                                        default:
                                            return <span style={{ minWidth: '30px' }}>{displayValue}</span>;
                                    }
                                })()}
                            </div>
                        </FloatingTooltip>
                        {modifyMode[name].hover && (
                            <IconButton size="small" onClick={() => setModifyMode(prev => ({ ...prev, [name]: { ...prev[name], mode: Mode.EDIT } }))}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        )}
                    </div>
                ) : (
                    <span>{displayValue}</span>
                )
            );
        }
    };




    return (
        <React.Fragment>
            <TableRow onClick={() => setOpen(!open)} className="broker-shipment-table-row">
                <TableCell sx={{ width: '20px!important' }}></TableCell>
                <TableCell sx={{ width: '50px' }}>
                    <Link sx={{ textDecoration: 'none' }} component="button" onClick={() => handleReferenceNumberClick(shipment._id)}>
                        {renderField('referenceNumber', shipment.referenceNumber)}
                    </Link>
                </TableCell>
                <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '50px', minWidth: '0px!important', whiteSpace: 'nowrap' }}>
                    {renderField('businessName', shipment.businessName)}
                </TableCell>
                <TableCell sx={{ width: '170px', whiteSpace: 'nowrap' }}>
                    {renderField('mbl', shipment.mbl)}
                </TableCell>
                <TableCell sx={{ width: '20px' }}>
                    {renderIconBasedOnTransportType(shipment.type)}
                </TableCell>
                <TableCell sx={{ width: '100px' }}>
                    {renderField('etd', formatHumanReadableDate(shipment.etd))}
                </TableCell>
                <TableCell sx={{ width: '100px' }}>
                    {renderField('dueDate', formatHumanReadableDate(shipment.dueDate))}
                </TableCell>
                <TableCell sx={{ width: '100px' }}>
                    {renderField('eta', formatHumanReadableDate(shipment.eta))}
                </TableCell>
                <TableCell sx={{ width: '20px' }}>
                    {renderField('isfOnFile', shipment.isfOnFile)}
                </TableCell>
                <TableCell sx={{ width: '20px' }}>
                    {renderField('customsCleared', shipment.customsCleared)}
                </TableCell>
                <TableCell sx={{ width: '20px' }}>
                    {renderField('billed', shipment.billed)}
                </TableCell>
                <TableCell sx={{ width: '130px' }}>
                    {renderField('internalStatus', shipment.internalStatus)}
                </TableCell>
                <TableCell sx={{textWrap: 'nowrap'}}>
                    {renderField('notes', shipment.notes)}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const shipmentTableColumns: Array<ColumnConfig> = [
    {
        displayName: 'Ref No.',
        name: 'referenceNumber',
        dataType: 'string',
        sortable: true,
        filterable: true
    },
    {
        displayName: 'Business',
        name: 'businessName',
        dataType: 'string',
        sortable: true,
        filterable: true
    },
    {
        displayName: 'MBL',
        name: 'mbl',
        dataType: 'string',
        sortable: true,
        filterable: true
    },
    {
        displayName: 'MOT',
        name: 'type',
        dataType: 'string',
        sortable: true,
        filterable: true,
        categorizer: (value: string) => {
            if (value) {
                return ShipmentTransportationTypeText[value]
            } else {
                return 'Empty'
            }
        }
    },
    {
        displayName: 'ETD',
        name: 'etd',
        dataType: 'string',
        sortable: true,
        filterable: true,
        categorizer: shipmentDateCategorizer,
        columnFilterSortFunction: shipmentDateSortFunction
    },
    {
        displayName: 'Due Date',
        name: 'dueDate',
        dataType: 'string',
        sortable: true,
        filterable: true,
        categorizer: shipmentDateCategorizer,
        columnFilterSortFunction: shipmentDateSortFunction
    },
    {
        displayName: 'ETA',
        name: 'eta',
        dataType: 'string',
        sortable: true,
        filterable: true,
        categorizer: shipmentDateCategorizer,
        columnFilterSortFunction: shipmentDateSortFunction
    },
    {
        displayName: 'ISF On File',
        name: 'isfOnFile',
        dataType: 'boolean',
        sortable: true,
        filterable: true,
        categorizer: (value: string | boolean) => {
            switch (value) {
                case true:
                    return 'Yes'
                case false:
                    return 'No'
                default:
                    return 'Empty'
            }
        }
    },
    {
        displayName: 'Customs Cleared',
        name: 'customsCleared',
        dataType: 'boolean',
        sortable: true,
        filterable: true,
        categorizer: (value: string | boolean) => {
            switch (value) {
                case true:
                    return 'Yes'
                case false:
                    return 'No'
                default:
                    return 'Empty'
            }
        }
    },
    {
        displayName: 'Billed',
        name: 'billed',
        dataType: 'string',
        sortable: true,
        filterable: true,
        categorizer: (value: string | boolean) => {
            switch (value) {
                case true:
                    return 'Yes'
                case false:
                    return 'No'
                default:
                    return 'Empty'
            }
        }
    },
    {
        displayName: 'Status',
        name: 'internalStatus',
        dataType: 'string',
        sortable: true,
        filterable: true,
        categorizer: (value: string) => {
            return InternalShipmentStatusText[value];
        }
    },
    {
        displayName: 'Notes',
        name: 'notes',
        dataType: 'string',
        sortable: true,
        filterable: true,
        categorizer: (value: string) => {
            if (value === "") {
                return "Empty"
            } else {
                return value;
            }
        }
    },
]

interface ShipmentTableProps {
    rows: Shipment<any>[],
    isLoading: boolean,
    createShipmentButton?: React.JSX.Element;
}

const ShipmentTable = ({ rows, isLoading, createShipmentButton}: ShipmentTableProps) => {

    const api = useAPI();

    return (
        <div className="broker-shipments-table-container">
                <ImportalTable
                    data={rows}
                    rowGenerator={(row, index) => <Row key={`row-${index}`} api={api} row={createData(row, index)} />}
                    columnConfig={shipmentTableColumns}
                    loading={isLoading}
                    topRightButton={createShipmentButton}
                />
        </div>

    );
}
export default ShipmentTable

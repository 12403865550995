import React, {useState} from 'react';
import {
    Button,
    IconButton,
    MenuItem,
    Select,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import {Add, Check, Close, Delete as DeleteIcon, Edit as EditIcon} from '@mui/icons-material';
import {Document, DocumentType, DocumentTypeText} from "common/interfaces/document";
import {useAPI} from '@/api/APIContext';
import {Mode} from 'common/interfaces/business';
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal';
import Dropzone from 'react-dropzone';
import './ManageDocuments.css';

const Input = styled("input")({
    display: "none",
});

interface ManageDocumentsProps {
    documents: Document[];
    setDocuments: (documents: Document[]) => void;
    onDocumentsUpdated: (documents: Document[]) => void;
    parentName?: string;
    fontSize?: string;
    filter: DocumentType[];
    useDeleteConfirmationModal?: boolean;
}

// Function to get filtered document type keys based on a given filter
const getFilteredDocumentTypeKeys = (filter: DocumentType[]): (keyof typeof DocumentType)[] => {
    return Object.keys(DocumentType)
        .filter(key => filter.includes(DocumentType[key as keyof typeof DocumentType])) as (keyof typeof DocumentType)[];
};

export default function ManageDocuments({
                                            documents,
                                            setDocuments,
                                            onDocumentsUpdated,
                                            parentName,
                                            fontSize,
                                            filter,
                                            useDeleteConfirmationModal
                                        }: ManageDocumentsProps) {
    const api = useAPI();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(0);

    const handleDeleteConfirmClose = (confirm: boolean) => {
        handleDeleteDocumentRow(indexToDelete);
        setDeleteConfirmOpen(false);
    }

    const handleDocumentChange = <K extends keyof Document>(index: number, key: K, value: Document[K]) => {
        const updatedDocuments = documents ? documents.map((doc, i) =>
            i === index ? {...doc, [key]: value} : doc
        ) : [];
        setDocuments(updatedDocuments);
    };

    const handleAddDocumentRow = () => {
        const updatedDocuments: Document[] = [
            ...(documents || []),
            {type: DocumentType.BILL_OF_LADING, mode: Mode.ADD} as Document
        ];
        setDocuments(updatedDocuments);
    };

    const handleCancelEditDocumentRow = (indexToUpdate: number) => {
        const updatedDocuments = [...documents || []].map((document, index) =>
            index === indexToUpdate ? {...document, mode: Mode.VIEW} : document
        );
        setDocuments(updatedDocuments);
    };

    const handleEditDocumentRow = (indexToUpdate: number) => {
        const updatedDocuments = [...documents || []].map((document, index) =>
            index === indexToUpdate ? {...document, mode: Mode.EDIT} : document
        );
        setDocuments(updatedDocuments);
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = e.target.files ? e.target.files[0] : undefined;
        handleDocumentChange(index, 'file', file);
    };

    const handleFileUploads = (files: File[]) => {
        // Create a new array of documents by mapping over the files array
        const newDocuments = files.map((file) => ({
            type: DocumentType.BILL_OF_LADING, // or a default document type, you can change as needed
            mode: Mode.ADD,
            file: file,
        }));

        // Combine the new documents with the existing ones
        const updatedDocuments: Document[] = [...(documents || []), ...newDocuments] as Document[];

        // Update the state with the combined array
        setDocuments(updatedDocuments);
        onDocumentsUpdated(updatedDocuments);

    };


    const createDocument = (document: Document, updatedDocuments: Document[], index: number) => {
        api.createDocument(document, document.file as File)
            .then(({data}) => {
                updatedDocuments[index] = data
                setDocuments(updatedDocuments);
                onDocumentsUpdated(updatedDocuments);
            })
            .catch(err => {
                console.error('error uploading file with type:', document.type);
                console.error(err);
            })
    }

    const updateDocument = (document: Document, updatedDocuments: Document[], index: number) => {
        api.updateDocument(document!._id.toString(), document, document.file)
            .then(({data}) => {
                updatedDocuments[index] = data;
                setDocuments(updatedDocuments);
                onDocumentsUpdated(updatedDocuments);
            });
    }

    const handleSaveDocumentRow = (indexToUpdate: number) => {
        const updatedDocuments = [...documents as Document[]];

        if (documents) {
            const documentToSave: Document = documents[indexToUpdate];
            if (documentToSave) {
                if (documentToSave.url) {
                    updateDocument(documentToSave, updatedDocuments, indexToUpdate);
                } else {
                    createDocument(documentToSave, updatedDocuments, indexToUpdate);
                }
            }
        }
    };

    const handleOpenDeleteConfirmModal = (index) => {
        setIndexToDelete(index);
        if (useDeleteConfirmationModal) {
            setDeleteConfirmOpen(true);
        } else {
            handleDeleteDocumentRow(index);
        }

    }

    const handleDeleteDocumentRow = (index: number) => {
        const updatedDocuments = documents?.filter((_, i) => i !== index);
        setDocuments(updatedDocuments);
        onDocumentsUpdated(updatedDocuments);
    };

    const renderUploadButtonText = (document: Document): string => {
        if (document.file) {
            return document.file.name;
        } else if (document.url) {
            return document.fileName as string;
        } else {
            return "Upload";
        }
    }

    const handleViewDocumentClick = (e: any, document: Document) => {
        e.preventDefault();
        window.open(`/documents/${document._id}`, '_blank');
    }

    const renderDocumentRow = (document: Document, index: number) => {
        if (document.mode === Mode.EDIT || document.mode === Mode.ADD) {
            return (
                <TableRow key={index}>
                    <TableCell>
                        <Select
                            id="demo-simple-select"
                            fullWidth
                            size="small"
                            onChange={(e) => handleDocumentChange(index, 'type', e.target.value as DocumentType)}
                            value={document.type || ""}
                        >
                            {getFilteredDocumentTypeKeys(filter).map((key) => (
                                <MenuItem key={key} value={DocumentType[key]}>
                                    {DocumentTypeText[key]}
                                </MenuItem>
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell sx={{maxWidth: '250px'}}>
                        <Button
                            fullWidth
                            variant="text"
                            sx={{
                                textTransform: 'none',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                            component="label"
                        >
                            {renderUploadButtonText(document)}
                            <Input
                                type="file"
                                hidden
                                accept="*"
                                multiple
                                onChange={e => handleFileUpload(e, index)}
                            />
                        </Button>
                    </TableCell>
                    <TableCell>
                        <TextField
                            value={document.notes}
                            onChange={(e) => handleDocumentChange(index, 'notes', e.target.value)}
                            fullWidth
                            size="small"
                        />
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleCancelEditDocumentRow(index)}>
                            <Close/>
                        </IconButton>
                        <IconButton disabled={!document.file && !document.url}
                                    onClick={() => handleSaveDocumentRow(index)}>
                            <Check/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        } else {
            return (
                <TableRow key={index}>
                    <TableCell>{DocumentTypeText[document.type as DocumentType]}</TableCell>
                    <TableCell sx={{
                        maxWidth: '250px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    }}>{document.url ? <a target="_blank" rel="noreferrer" href="/"
                                          onClick={(e) => handleViewDocumentClick(e, document)}>{document.fileName}</a> : <></>}</TableCell>
                    <TableCell>{document.notes}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleEditDocumentRow(index)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteConfirmModal(index)}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        }
    };

    return (
        <>
            <div className="entry-card-header-container">
                <div style={{fontSize: fontSize}} className="my-shipments-header">Documents</div>
                <IconButton onClick={handleAddDocumentRow}><Add/></IconButton>
            </div>
            <Dropzone onDrop={(acceptedFiles) => handleFileUploads(acceptedFiles)} noClick>
                {({getRootProps, getInputProps}) => (
                    <>
                        <input {...getInputProps()} />
                        {documents && documents.length > 0 ? (
                            <div {...getRootProps()} className="documents-table-container">
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: '700', color: '#525256'}}>Type</TableCell>
                                                <TableCell sx={{fontWeight: '700', color: '#525256'}}>File</TableCell>
                                                <TableCell sx={{fontWeight: '700', color: '#525256'}}>Notes</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="user-row">
                                            {documents.map((document, index) => (
                                                renderDocumentRow(document, index)
                                            ))}
                                            <div className="upload-hint-container"></div>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        ) : (
                            <div {...getRootProps()} className='no-results-container'>
                                No documents saved yet for this {parentName}.
                            </div>
                        )}
                    </>
                )}
            </Dropzone>
            <DeleteConfirmationModal open={deleteConfirmOpen} title={"Delete Document"} itemName={'document'}
                                     onClose={handleDeleteConfirmClose}/>
        </>
    );

}


import React, { createContext, useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useAPI } from "@/api/APIContext";
import './BusinessPage.css';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { BusinessUser, BusinessWithDocuments } from 'common/interfaces/business';
import BusinessAdminTab from "@/broker-app/pages/businesses/BusinessAdminTab";
import { UsagePlanAny, UsagePlanMetadata } from 'common/interfaces/plan';
import { Entry } from 'common/interfaces/entry';
import { Link } from 'common/interfaces/session';
import ImportalTabs from "@/shared-components/ImportalTabs/ImportalTabs";
import Checkbox from "@mui/material/Checkbox";
import { useMatch } from "react-router";
import { CustomsOnboarding, CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';
import BusinessReportingTab from "@/broker-app/pages/businesses/BusinessReportingTab";
import { Report } from 'common/interfaces/reports';
import ShipmentsTable from '../shipments/ShipmentsTable';
import { ShipmentWithDocumentIds } from 'common/interfaces/shipment';
import ManageDocuments from "@/shared-components/ManageDocuments/ManageDocuments";
import { businessDocumentsFilter, Document } from 'common/interfaces/document';
import ClassificationRequestsTab from "@/broker-app/pages/businesses/ClassificationRequestsTab";
import ImportalCard from '@/shared-components/ImportalCard';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { Add } from '@mui/icons-material';

export interface IBusinessPageContext {
    plan?: UsagePlanAny,
    refreshPlan: () => void
    planMetadata?: UsagePlanMetadata,
    refreshPlanMetadata: () => void,
    users: any[],
    refreshUsers: () => void,
    business?: BusinessWithDocuments,
    refreshBusiness: () => void,
    entries?: Entry[],
    links: Link[],
    refreshLinks: () => void,
    customsOnboarding?: CustomsOnboarding
    refreshCustomsOnboarding: () => void,
    isCustomsOnboarded?: boolean

    reports?: Report[]
    refreshReports: () => void,
    documents?: Document[]
}

export const BusinessPageContext = createContext<IBusinessPageContext>({
    plan: undefined,
    refreshPlan: () => {
    },
    planMetadata: undefined,
    refreshPlanMetadata: () => {
    },
    users: [],
    refreshUsers: () => {
    },
    business: undefined,
    refreshBusiness: () => {
    },
    entries: [],
    links: [],

    refreshLinks: () => {
    },
    customsOnboarding: undefined,
    refreshCustomsOnboarding: () => {
    },
    isCustomsOnboarded: false,

    reports: undefined,
    refreshReports: () => {
    },
    documents: [],
});

const { Provider } = BusinessPageContext;


export default function BusinessPage() {
    const api = useAPI();
    const theme = useTheme();
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING)

    const match = useMatch({
        path: "/broker/dashboard/businesses/:businessId",
    });
    const [entries, setEntries] = useState<Entry[]>([]);
    const [business, setBusiness] = useState<BusinessWithDocuments | undefined>();
    const [plan, setPlan] = useState<UsagePlanAny | undefined>(undefined)
    const [planMetadata, setPlanMetadata] = useState<UsagePlanMetadata | undefined>(undefined)
    const [users, setUsers] = useState<BusinessUser[]>([])
    const [links, setLinks] = useState<Link[]>([]);
    const [reports, setReports] = useState<Report[]>([]);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [shipments, setShipments] = useState<ShipmentWithDocumentIds[]>([])
    const [customsOnboarding, setCustomsOnboarding] = useState<CustomsOnboarding | undefined>(undefined);
    const [loadingText, setLoadingText] = useState('');
    const [successText, setSuccessText] = useState('');
    const [errorText, setErrorText] = useState('');


    const saveDocumentsToBusiness = (documents: Document[]) => {
        const updatedBusiness: BusinessWithDocuments = {
            ...business as BusinessWithDocuments,
            documents: documents
        }
        updateBusiness(updatedBusiness);
    }


    const updateBusiness = (updatedBusiness: BusinessWithDocuments) => {
        const businessId = business?._id?.toString();
        setLoadingStatus(LoadingStatus.LOADING);
        setLoadingText('Updating business...');
        if (businessId) {
            api.updateBusiness(businessId, updatedBusiness).then((response) => {
                setLoadingStatus(LoadingStatus.SUCCESS);
                setSuccessText('Successfully updated business.');
                refreshBusiness();
            }).catch((error) => {
                setLoadingStatus(LoadingStatus.ERROR);
                setErrorText(`Error updating business: ${error}`);
            })
        }

    }

    const refreshUsers = () => {
        api.getBusinessUsers(match?.params.businessId!)
            .then(({ data: users }) => {
                setUsers(users)
            })
            .catch(err => {
                console.error('error getting business users for BusinessPage')
            })
    }

    const refreshLinks = () => {
        api.getLinksForBusiness(match?.params.businessId!)
            .then(({ data: links }) => {
                if (Array.isArray(links)) {
                    setLinks(links)
                }
            })
            .catch(err => {
                console.error(`error getting business users for BusinessPage: ${err}`);
            })
    }

    const refreshBusiness = () => {
        api.getBusinessById(match?.params.businessId!)
            .then((response) => {
                setBusiness(response.data);
                if (response.data.documents) {
                    setDocuments(response.data.documents);
                }
            })
            .catch(err => {
                console.error(err);
            });

    }

    const refreshPlan = () => {
        api.getPlanForBusiness(match?.params.businessId!)
            .then(({ data: plan }) => {
                setPlan(plan);
            })
            .catch(err => {
                console.error('error getting business plan for BusinessPage', err);
            });

    }

    const refreshPlanMetadata = () => {
        api.getPlanMetadataForBusiness(match?.params.businessId!)
            .then(({ data: metadata }) => {
                setPlanMetadata(metadata);
            })
            .catch(err => {
                console.error('error getting business plan metadata for BusinessPage', err);
            });
    }

    const refreshCustomsOnboarding = () => {
        api.getCustomsOnboardingForBusiness(match?.params.businessId!)
            .then(({ data: customsOnboarding }) => {
                setCustomsOnboarding(customsOnboarding)
            })
            .catch((error) => {
                console.error(error);
            })
    }


    useEffect(() => {
        if (match) {
            const businessId = match.params.businessId as string;
            refreshBusiness()
            refreshUsers();
            refreshLinks();
            refreshPlan()
            refreshPlanMetadata()
            refreshCustomsOnboarding();
            refreshShipments();
            refreshReports()
        }
    }, []);


    const refreshShipments = () => {
        setLoadingStatus(LoadingStatus.LOADING);
        api.getBusinessShipments(match?.params.businessId!)
            .then(function (response) {
                if (Array.isArray(response.data)) {
                    const shipments: ShipmentWithDocumentIds[] = response.data;
                    setShipments(shipments);
                }
                setLoadingStatus(LoadingStatus.SUCCESS);
            })
            .catch(function (error) {
                console.error(error);
                setLoadingStatus(LoadingStatus.ERROR);
            });
    };

    const handleCustomsOnboardedCheckboxClick = (e) => {
        if (e.target.checked && !isCustomsOnboarded()) {
            setLoadingStatus(LoadingStatus.LOADING);
            const completedCustomsOnboarding = { ...customsOnboarding, step: CustomsOnboardingSteps.COMPLETE };
            api.updateCustomsOnboarding(business?._id?.toString() as string, completedCustomsOnboarding).then((response) => {
                setLoadingStatus(LoadingStatus.SUCCESS);
                refreshCustomsOnboarding();
            }).catch((error) => {
                setLoadingStatus(LoadingStatus.ERROR);
            })
        }
    }


    const summaryTab = (business: BusinessWithDocuments) => {
        return <div>
            <ImportalCard title={'Summary'}>
                <div className="entry-info-container">
                    <div className="entry-business-info-container">
                        <div className="entry-business-info-row">
                            <div>
                                <div className="entry-row-header">Address</div>
                                <div className="entry-row-text">{business.address}</div>
                            </div>
                            {
                                business.additionalState !== "" ?
                                    <div>
                                        <div className="entry-row-header">State of
                                            Incorporation
                                        </div>
                                        <div
                                            className="entry-row-text">{business.additionalState}</div>
                                    </div>
                                    : <></>
                            }
                            <div>
                                <div className="entry-row-header">Type</div>
                                <div
                                    className="entry-row-text">{business.businessStructure}</div>
                            </div>
                            <div>
                                <div className="entry-row-header">EIN/CAIN</div>
                                <div className="entry-row-text">{business.ein}</div>
                            </div>
                            <div>
                                <div className="entry-row-header">Payment Method to Customs
                                </div>
                                <div className="entry-row-text"></div>
                            </div>
                            <div>
                                <div className="entry-row-header">Point of Contact</div>
                                <div className="entry-row-text"></div>
                            </div>
                            <div>
                                <div className="entry-row-header">Partners</div>
                                <div className="entry-row-text"></div>
                            </div>
                            <div>
                                <div className="entry-row-header">Bond Info</div>
                                <div className="entry-row-text"></div>
                            </div>
                        </div>
                        <div className="entry-business-info-row">
                            <div>
                                <div className="entry-business-info-row-section-header">
                                    Services Overview
                                </div>
                                <div className="entry-business-info-row">
                                    <div>
                                        <div className="entry-row-header">Bonds</div>
                                        <div className="entry-row-text"></div>
                                    </div>
                                    <div>
                                        <div className="entry-row-header">ISF</div>
                                        <div className="entry-row-text"></div>
                                    </div>
                                    <div>
                                        <div className="entry-row-header">Customs Clearance
                                        </div>
                                        <div className="entry-row-text"></div>
                                    </div>
                                    <div>
                                        <div className="entry-row-header">Classifications</div>
                                        <div className="entry-row-text"></div>
                                    </div>
                                    <div>
                                        <div className="entry-row-header">Disbursement</div>
                                        <div className="entry-row-text"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ImportalCard>
            <Card className="my-shipments-card">
                <CardContent>
                    <div className="my-shipments-header">Compliance</div>
                    <div className="entry-info-container">
                        <div className="entry-business-info-container">
                            <div className="entry-business-info-row">
                                <div>
                                    <div className="entry-row-header">Product Library</div>
                                    <div className="entry-row-text">Yes</div>
                                </div>
                                <div>
                                    <div className="entry-row-header">Completed SOP?</div>
                                    <div className="entry-row-text">No</div>
                                </div>
                                <div>
                                    <div className="entry-row-header">EIN/CAIN</div>
                                    <div className="entry-row-text">{business.ein}</div>
                                </div>
                                <div>
                                    <div className="entry-row-header">Payment Method to Customs
                                    </div>
                                    <div className="entry-row-text"></div>
                                </div>
                                <div>
                                    <div className="entry-row-header">Point of Contact</div>
                                    <div className="entry-row-text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card className="my-shipments-card">
                <CardContent>
                    <div className="entry-card-header-container">
                        <div className="my-shipments-header">Billing</div>
                    </div>
                </CardContent>
            </Card>
        </div>
    }

    const isCustomsOnboarded = (): boolean => {
        return customsOnboarding?.step === CustomsOnboardingSteps.COMPLETE
    }

    const refreshReports = async () => {
        try {
            const { data: reports } = await api.getReportsForBusiness((match?.params.businessId)!);
            setReports(reports);
        } catch (err) {
            console.error('error getting reports for business');
            console.error(err);
        }
    }

    return (
        <>
            <Provider value={{
                plan,
                refreshPlan,
                planMetadata,
                refreshPlanMetadata,
                users,
                refreshUsers,
                business,
                refreshBusiness,
                entries,
                links,
                refreshLinks,
                customsOnboarding,
                refreshCustomsOnboarding,
                isCustomsOnboarded: isCustomsOnboarded(),
                reports,
                refreshReports
            }}>
                <div className='product-library-header'>
                    <div className="business-header-text">
                        <Typography
                            sx={{ color: theme.palette.primary.main, fontSize: "26px" }}
                        >
                            {business?.name || ""}
                        </Typography>
                        <div className="universal-subheader">
                            View all relevant business and customs information for this customer
                        </div>
                    </div>
                    <div className="title-question-body">
                        <div className="business-customs-checkbox-container">
                            Customs Onboarded?
                            <Checkbox checked={isCustomsOnboarded()} onChange={handleCustomsOnboardedCheckboxClick} />
                        </div>
                    </div>
                </div>

                {business ?
                    <>
                        <Box sx={{ width: '100%' }}>
                            <ImportalTabs tabsConfig={[
                                { title: "Summary", element: summaryTab(business) },
                                {
                                    title: "Shipments", element: <ImportalCard title="Shipments" subtitle={<>{business.name} has {shipments.length} active shipments in-transit.</>}>
                                        <div className="shipments-table-container">
                                            <ShipmentsTable rows={shipments} isLoading={false}></ShipmentsTable>
                                        </div>
                                    </ImportalCard>
                                },
                                {
                                    title: "Products", element: <ImportalCard title={"Products"}>
                                        <div className="no-results-container"><i>This feature is coming soon...</i></div>
                                    </ImportalCard>
                                },
                                {
                                    title: "Documents", element: <><Card className="my-shipments-card">
                                        <CardContent>
                                            <ManageDocuments filter={businessDocumentsFilter}
                                                useDeleteConfirmationModal={true} fontSize={"24px"}
                                                documents={documents} setDocuments={setDocuments}
                                                onDocumentsUpdated={saveDocumentsToBusiness}
                                                parentName={'business'} />
                                        </CardContent>
                                    </Card></>
                                },
                                {
                                    title: "Reporting", element: <BusinessReportingTab />
                                },
                                {
                                    title: "HTS Classifications", element: <ClassificationRequestsTab />
                                },
                                { title: "Admin", element: <><BusinessAdminTab /></> },

                            ]} />

                        </Box>
                    </>
                    :
                    <span>Loading...</span>
                }
                <DataImportLoader
                    loadingState={{ loadingStatus }}
                    loadingText={"Getting business info..."}
                    successText={"Successfully retrieved business info"}
                    errorText={"Error getting business info"}
                />
            </Provider>
        </>
    );
}


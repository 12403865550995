import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import "./ProductCodeAutocomplete.css";
import {useTheme} from "@mui/material";
import ReactHtmlParser from 'react-html-parser';
import {debounce} from 'lodash'
import {Code} from "common/interfaces/code";


interface Props {
    selectedCode?: Code,
    onCodeSelected: (code: Code | null) => void,
    size?: "small" | "medium"
}

export default function ProductCodeAutocomplete({selectedCode, onCodeSelected, size}: Props) {
    const theme = useTheme();
    const [currentHtsCodes, setCurrentHtsCodes] = useState<Code[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);

    const [loadingText, setLoadingText] = useState("")
    const [userTyped, setUserTyped] = useState(false)

    useEffect(() => {
        if (inputValue !== "") {
            setCurrentHtsCodes([]);
            setUserTyped(true)
            debouncedGetHtsCodesFinal.current(inputValue);
        } else {
            setLoading(false)
            setLoadingText("")
            setCurrentHtsCodes([]);
        }
    }, [inputValue]);


    const getParentClassificationText = (option: Code) => {
        return option.htsSentence
    };

    const renderResults = (results) => {
        const renderedContent = results.map((content, index) => {
            if (content.includes('<i>') && !content.includes('<u>')) {
                // Check if the content contains <i> tags and render with italics
                return <i key={index}>{ReactHtmlParser(content)}</i>;
            } else if (content.includes('<u>') && !content.includes('<i>')) {
                return <u key={index}>{ReactHtmlParser(content)}</u>;
            } else if (content.includes('<u>') && content.includes('<i>')) {
                return <i><u key={index}>{ReactHtmlParser(content)}</u></i>;
            } else {
                // Render regular text as is
                return <span key={index}>{content}</span>;
            }
        });

        return renderedContent;
    };

    const renderHTML = (inputString) => {
        if (inputString !== undefined) {
            const startIndex = '<i>';
            const endIndex = '</i>';

            const results: any = [];
            let currentIndex = 0;
            while (currentIndex < inputString.length) {
                const iStartIndex = inputString.indexOf(startIndex, currentIndex);
                if (iStartIndex === -1) {
                    break;
                }

                const iEndIndex = inputString.indexOf(endIndex, iStartIndex);
                if (iEndIndex === -1) {
                    break;
                }

                results.push(inputString.substring(currentIndex, iStartIndex)); // Text before <i>
                results.push(inputString.substring(iStartIndex, iEndIndex + endIndex.length)); // Include <i> and </i> tags with content

                currentIndex = iEndIndex + endIndex.length;
            }

            // Add the remaining text (if any) after the last </i> tag
            if (currentIndex < inputString.length) {
                results.push(inputString.substring(currentIndex));
            }
            return renderResults(results);
        }
    };


    const getHtsCodesFinal = async (searchTerm) => {
        if (!searchTerm) return

        setUserTyped(false) // this tracks whether or not the user's search has actually been submitted yet
        setLoadingText("Generating embeddings for your search...")
        const cancelTimer1 = setTimeout(() => {
            setLoadingText("Getting HTS codes related to your search...")
        }, 3000)
        const cancelTimer2 = setTimeout(() => {
            setLoadingText("Double checking everything...")
        }, 8000)


        setLoading(true);
        let search = {
            input: {
                query: searchTerm
            }
        };
        const {data} = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/hts`,
            search
        );
        setLoading(false);

        clearTimeout(cancelTimer1);
        clearTimeout(cancelTimer2);
        setLoadingText("")
        setCurrentHtsCodes(data);

    };

    const debouncedGetHtsCodesFinal = useRef(debounce(getHtsCodesFinal, 750));


    const handleHtsChange = (event: any, newValue: Code | null) => {
        onCodeSelected(newValue); // Trigger the external event handler
    };
    const renderLoadingIndicator = (loading) => {
        if (loading) {
            return (
                <div className="loading-indicator-container">
                    <CircularProgress
                        sx={{color: theme.palette.secondary.main}}
                        size={35}
                    />
                </div>
            );
        } else {
            return <div className="loading-indicator-container-empty"></div>
        }
    };

    const renderOption = (loading, props, option) => {
        const {key, ...otherProps} = props // this solve a react error about 'spreading' a props object in jsx
        return (
            <Box
                key={option.htsno}
                className="dropdown-option-container"
                component="li"
                sx={{"& > img": {mr: 2, flexShrink: 0}}}
                {...otherProps}
            >
                <div className="option-code">
                    <div>
                        <b>Code</b>
                    </div>
                    <div>{option.htsno}</div>
                </div>
                <div className="option-parent">
                    <div><b>Parent Classifications</b></div>
                    <div>{renderHTML(getParentClassificationText(option))}</div>
                </div>
                <div className="option-name">
                    <div>
                        <b>Name</b>
                    </div>
                    <div>{renderHTML(option.description)}</div>
                </div>
            </Box>
        );
    };

    const getNoOptionsText = () => {
        if (userTyped) {
            return "Waiting to Search..."
        } else if (loading) {
            return <span className="loading-text">{loadingText}</span>
        } else {
            return "No Matching HTS Codes"
        }
    }

    return (
        <div className="autocomplete-container">
            <Autocomplete
                id="code-select"
                noOptionsText={getNoOptionsText()}
                sx={{width: 'inherit', borderRadius: '16px'}}
                options={currentHtsCodes}
                filterOptions={(options) => options}
                disablePortal={true}
                size={size}
                value={selectedCode || null}
                isOptionEqualToValue={(option, value) => {
                    return option.htsno === value.htsno
                }}
                onChange={handleHtsChange}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                autoHighlight
                getOptionLabel={(option: any) =>
                    `${option.htsno} - ${option.description}`
                }
                renderOption={(props, option: any) =>
                    renderOption(loading, props, option)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Feel free to search by code, description or name"
                        sx={{
                            '& .MuiInputBase-root': {
                                backgroundColor: '#FFFFFF!important', // Set the background color to white
                                borderRadius: '16px',
                            }
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "off"
                        }}
                    />
                )}
                clearOnBlur={false}
            />
            {renderLoadingIndicator(loading)}
        </div>
    );
}
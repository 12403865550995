import React from "react";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import "./VMCRSuppressionInfo.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ManifestContext from "../manifest-modal/ManifestProvider";

export default function VMCRSuppressionInfo({handleNext, handleBack}) {

    const {currentManifestRequest, setCurrentManifestRequest} = React.useContext(ManifestContext);

    const handleManifestTypeChange = (e) => {
        setCurrentManifestRequest({
            ...currentManifestRequest,
            manifestType: e.target.value,
        })
    }

    const handleEntityNameChange = (e) => {
        setCurrentManifestRequest({
            ...currentManifestRequest,
            entityName: e.target.value
        })
    }

    const isNextDisabled = (): boolean => {
        return !(currentManifestRequest?.manifestType && currentManifestRequest?.entityName)
    }

    return (
        <div className="card-container">
            <div>
                <div className="importing-card-container">
                    <div className="vmcr-description-title">Suppression Information</div>
                    <div className="vmcr-business-info-container">
                        <div className="vmcr-business-info-row" style={{gap: '32px'}}>
                            <div style={{width: '50%', height: '42px'}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Manifest Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={currentManifestRequest?.manifestType || ""}
                                        onChange={handleManifestTypeChange}
                                        label="Manifest Type"
                                    >
                                        <MenuItem value="Import and Export">Import and Export</MenuItem>
                                        <MenuItem value="Import">Import</MenuItem>
                                        <MenuItem value="Export">Export</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{width: '50%'}}>
                                <TextField
                                    fullWidth
                                    id="cargo-value"
                                    value={currentManifestRequest?.entityName || ""}
                                    onChange={handleEntityNameChange}
                                    label="Entity Name"
                                    inputProps={{maxLength: 35}}
                                    name="cargo-value"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="next-button-container">
                        <Button
                            type="submit"
                            fullWidth
                            sx={{width: '160px!important'}}
                            className="next-button-green"
                            endIcon={<KeyboardArrowRightIcon/>}
                            onClick={handleNext}
                            variant="contained"
                            disabled={isNextDisabled()}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import {Button, Card, CardContent, Typography} from "@mui/material";
import "./ComplianceHDFSearch.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {ComplianceSummaryContext} from "common/interfaces/complianceSummary";
import {renderHDFSSubtext} from "@/utils/complianceSummaryRendering";

export interface Props {
    complianceSummary: ComplianceSummaryContext,
    onNext: () => void,
    onBack: () => void
}

export default function ComplianceHDFSearch({complianceSummary, onNext, onBack}: Props) {

    return (
        <div className="card-container">
            <Card sx={{minWidth: 275}}>
                <CardContent>
                    <div className="header-question">
                        <Typography>
                            Hidden Duties & Fees Search
                        </Typography>
                    </div>
                    <div className="unlading-descriptive-text">
                        Hidden Duties and Fees Search is a review that we do to see if your cargo may qualify for any
                        hidden duties and fees related to country or HTS code. We'll highlight them briefly below but
                        give you a full breakdown on the summary page.
                    </div>

                    <div className="pga-circle-container">
                        <div>
                            {renderHDFSSubtext(complianceSummary.hiddenDutiesAndFeesOutput)}
                        </div>
                    </div>
                    <div className="next-button-container">
                        <Button
                            fullWidth
                            className="back-button-text"
                            startIcon={<KeyboardArrowLeftIcon/>}
                            variant="text"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            onClick={onNext}
                            fullWidth
                            variant="contained"
                            className="next-button-green"
                            endIcon={<KeyboardArrowRightIcon/>}
                        >
                            Next
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './DeleteConfirmationModal.css';

export interface DeleteConfirmationModalProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  title: string;
  itemName?: string;
}

export default function DeleteConfirmationModal(props: DeleteConfirmationModalProps) {
  const { onClose, open, title, itemName } = props;

  const handleClose = (confirm: boolean) => {
    onClose(confirm);
  };

  return (
    <div className="delete-confirmation-dialog-container">
      <Dialog
        maxWidth="sm"
        onClose={() => handleClose(false)}
        open={open}
      >
        <div>
          <div>
            <div className="header-question">
              <Typography>
                {title}
              </Typography>
            </div>
            <IconButton
              onClick={() => handleClose(false)}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          </div>
          <div className="pga-description-subheader">
            Are you sure you want to delete this {itemName ? itemName : "item"}?
          </div>
          <div className="next-button-container">
                        <Button
                            fullWidth
                            className="back-button-text"
                            variant="text"
                            onClick={() => handleClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className="next-button-green"
                            onClick={() => handleClose(true)}
                        >
                            Delete
                        </Button>
                    </div>
        </div>
      </Dialog>
    </div>
  );
}

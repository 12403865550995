import React, { useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    MenuItem,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import ProductTable from './ProductTable';
import UploadProductsDataModal from './UploadProductsDataModal';
import ActiveJobsTable, { ActiveJob } from './ActiveJobsTable';
import AddOrEditAProductModal from './AddOrEditAProductModal';
import { Product, ProductAction } from 'common/interfaces/product';
import { DataImport, DataImportStatus } from 'common/interfaces/dataimport';
import ConfirmModal from './ConfirmModal';
import './ProductLibrary.css';
import EditProductFieldsModal from './EditProductFieldsModal';
import { ImportalTableHandles } from '@/shared-components/ImportalTable/ImportalTable';
import Menu from '@mui/material/Menu';
import ExportProductDataModal from '@/pages/product-library/ExportProductDataModal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Settings from '@mui/icons-material/Settings';
import EditTableSettingsModal from '@/pages/product-library/EditTableSettingsModal';
import {
    DataExportProductCSV,
    DataExportStatus,
} from 'common/interfaces/dataExport';
import ImportalCard from '@/shared-components/ImportalCard';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import Dropzone from 'react-dropzone';

export default function ProductLibrary() {
    const api = useAPI();
    const theme = useTheme();

    const [products, setProducts] = useState<Product[]>([]);
    const [addOrEditAProductOpen, setAddOrEditAProductOpen] = useState(false);
    const [uploadProductsOpen, setUploadProductsOpen] = useState(false);
    const [exportProductDataOpen, setExportProductDataOpen] = useState(false);
    const [productLists, setProductLists] = useState<File[]>([]);

    const [activeJobs, setActiveJobs] = useState<ActiveJob[]>([]);

    const [dataImportForViewingInModal, setDataImportForViewingInModal] =
        useState<DataImport<any> | undefined>();
    const [productIDForViewingInModal, setProductIDForViewingInModal] = useState<
        string | undefined
    >();

    const [productIdForDeleteModal, setProductIdForDeleteModal] = useState<
        string | undefined
    >();
    const [showingDeleteConfirmation, setShowingDeleteConfirmation] =
        useState(false);

    const [editProductFieldsOpen, setEditProductFieldsOpen] = useState(false);
    const [editTableSettingsOpen, setEditTableSettingsOpen] = useState(false);

    const [productFieldsConfig, setProductFieldsConfig] = useState(undefined);

    // TODO: add the type for the importalTable so it has the method i want
    const importalTableRef = React.createRef<ImportalTableHandles>();

    const getProducts = () => {
        api
            .getProducts()
            .then(({ data }) => {
                setProducts(data);
            })
            .catch((err) => {
                console.error('error getting all products for user');
            });
    };
    useEffect(() => {
        getProducts();
    }, [api]);

    const handleFilesDropped = async (files: File[]) => {
        if (
            !(
                dataImportForViewingInModal &&
                dataImportForViewingInModal?.status === DataImportStatus.DRAFT
            )
        ) {
            const { data: dataImport } = await api.createNewDataImport();
            setDataImportForViewingInModal(dataImport);
        }
        console.log(files);
        setProductLists(files);
        setUploadProductsOpen(true);
    }

    const refreshProductFieldsConfig = () => {
        api
            .getProductFields()
            .then(({ data: productFieldsConfig }) => {
                setProductFieldsConfig(productFieldsConfig);
            })
            .catch((err) => {
                console.error('error getting product fields config', err);
            });
    };
    useEffect(refreshProductFieldsConfig, [api]);

    const checkForActiveJobs = async () => {
        setActiveJobs([]);
        let jobsToMonitor: ActiveJob[] = [];
        do {
            try {
                const { data: productUploads } = await api.getActiveProductUploads();

                jobsToMonitor = productUploads.map((productUpload) => ({
                    type: 'Products Upload',
                    status: productUpload.status,
                }));
            } catch (err) {
                console.error('error getting active product uploads', err);
            }

            try {
                const { data: calculationsInProgress } =
                    await api.getActiveCalculations();

                const activeCalculationJobs = calculationsInProgress.map(
                    (calculation) => ({
                        type: 'Products Compliance Calculation',
                        status: 'PROCESSING',
                    })
                );
                jobsToMonitor = [...jobsToMonitor, ...activeCalculationJobs];
            } catch (err) {
                console.error(
                    'error getting active product compliance summary calculations',
                    err
                );
            }
            setActiveJobs(jobsToMonitor);

            await new Promise((resolve, reject) => setTimeout(resolve, 3000));
        } while (
            jobsToMonitor &&
            jobsToMonitor.length > 0 &&
            jobsToMonitor.some(
                (job) =>
                    job.status === 'PROCESSING' ||
                    job.status === DataImportStatus.SUBMITTED_PENDING
            )
        );

        getProducts();
    };
    useEffect(() => {
        checkForActiveJobs();

        if (
            activeJobs &&
            activeJobs.length > 0 &&
            activeJobs.some((job) => job.status === 'PROCESSING')
        ) {
            setTimeout(checkForActiveJobs, 4000);
        } else {
            getProducts();
        }
    }, [api]);

    const onClickUploadProductData = async () => {
        if (
            !(
                dataImportForViewingInModal &&
                dataImportForViewingInModal?.status === DataImportStatus.DRAFT
            )
        ) {
            const { data: dataImport } = await api.createNewDataImport();
            setDataImportForViewingInModal(dataImport);
        }
        setUploadProductsOpen(true);
    };

    const productUploadSubmitted = (dataImport: DataImport<any>) => {
        api
            .submitDataImport(dataImport._id.toString())
            .then((data) => {
                checkForActiveJobs();
            })
            .catch((err) => {
                console.error('error submitting data import for processing');
                console.error(err)
            });
    };

    const onClickAddProduct = async () => {
        setProductIDForViewingInModal(undefined);
        setAddOrEditAProductOpen(true);
    };

    const onClickEditProduct = (productID: string) => {
        setProductIDForViewingInModal(productID);
        setAddOrEditAProductOpen(true);
    };

    const onProductCreatedModifiedOrDeleted = (
        productId: string,
        action: ProductAction
    ) => {
        switch (action) {
            case ProductAction.CREATED:
                api
                    .getProductByID(productId)
                    .then(({ data: newProduct }) => {
                        setProducts([...products, newProduct]);
                    })
                    .catch((err) => {
                        console.error(
                            'error getting product to update table after creation'
                        );
                        console.error(err);
                    });

                break;
            case ProductAction.MODIFIED:
                api
                    .getProductByID(productId)
                    .then(({ data: updatedProduct }) => {
                        const index = products.findIndex(
                            (product: Product) => product._id!.toString() === productId
                        );
                        if (index !== -1) {
                            let newProducts = [...products];
                            newProducts[index] = updatedProduct; // Splice in the new product at the same index
                            setProducts(newProducts);
                        }
                    })
                    .catch((err) => {
                        console.error(
                            'error getting product to update table after modification'
                        );
                        console.error(err);
                    });

                break;
            case ProductAction.DELETED:
                // TODO: interesting corner case with deleting.
                // if you are on a paginating page, and the product that was deleted is the only thing on that page
                // i.e. last page with one item,
                // then you would want to mess with the paginating controls while deleting the product
                // i.e. move the user to the last page with contents since the products list will shrink

                setProducts((prevProducts) =>
                    prevProducts.filter(
                        (product) => product._id!.toString() !== productId
                    )
                );
                break;
            default:
                console.log('no op');
        }
    };

    const onClickDeleteProduct = (productID: string) => {
        setProductIdForDeleteModal(productID);
        setShowingDeleteConfirmation(true);
    };

    const onConfirmDeleteProduct = () => {
        if (!productIdForDeleteModal) return;
        api
            .deleteProduct(productIdForDeleteModal)
            .then(() => {
                setShowingDeleteConfirmation(false);
                onProductCreatedModifiedOrDeleted(
                    productIdForDeleteModal,
                    ProductAction.DELETED
                );
            })
            .catch((err) => {
                console.error('error deleting product');
                console.error(err);
            });
    };

    const onDataExportSubmitted = async (exportName: string) => {
        if (importalTableRef.current) {
            try {
                const { data: createdProductDataExport } =
                    await api.createProductExport({
                        exportName: exportName,
                        tableState: importalTableRef.current.getTableConfig(),
                    });

                // @ts-ignore
                let dataExport: DataExportProductCSV = {};
                while (dataExport.status !== DataExportStatus.COMPLETED) {
                    await new Promise((resolve, reject) => {
                        setTimeout(resolve, 1000);
                    });
                    const { data } = await api.getDataExport(
                        createdProductDataExport._id.toString()
                    );
                    if (data.status === DataExportStatus.ERROR) {
                        throw new Error('Data Export Failed During Processing');
                    }
                    dataExport = data;
                }

                return dataExport;
            } catch (err) {
                console.error('error creating product data export');
                console.error(err);
                throw err;
            }
        } else {
            console.error('no ref for ImportalTable');
        }
    };

    const onClickCalculateComplianceSummaries = () => {
        api
            .calculateComplianceSummaries()
            .then(({ }) => {
                checkForActiveJobs();
                getProducts();
            })
            .catch((err) => {
                console.error('error calculating compliance summaries for products');
                console.error(err);
            });
    };

    // Menu Component boiler plate
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className='product-library-header'>
                <div className='business-header-text'>
                    <Typography
                        sx={{ color: theme.palette.primary.main, fontSize: '26px' }}
                    >
                        Product Library
                    </Typography>
                    <div className='universal-subheader'>
                        View your products and their corresponding compliance summary.
                    </div>
                </div>
                <div id={'action-menu'} className='right-button-container'>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem
                            onClick={() => {
                                setExportProductDataOpen(true);
                                handleMenuClose();
                            }}
                        >
                            Export Products
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onClickCalculateComplianceSummaries();
                                handleMenuClose();
                            }}
                        >
                            Recalculate Compliance Summaries
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setEditProductFieldsOpen(true);
                                handleMenuClose();
                            }}
                        >
                            Configure Columns
                        </MenuItem>
                    </Menu>
                </div>
            </div>

            <AddOrEditAProductModal
                open={addOrEditAProductOpen}
                productID={productIDForViewingInModal}
                productFieldsConfig={productFieldsConfig}
                onProductCreatedOrModified={onProductCreatedModifiedOrDeleted}
                onClose={() => setAddOrEditAProductOpen(false)}
            />

            <UploadProductsDataModal
                open={uploadProductsOpen}
                onClose={() => setUploadProductsOpen(false)}
                onSubmit={productUploadSubmitted}
                dataImport={dataImportForViewingInModal}
                productFieldsConfig={productFieldsConfig}
                files={productLists}
            />

            <ExportProductDataModal
                open={exportProductDataOpen}
                onClose={() => setExportProductDataOpen(false)}
                onDataExportSubmitted={onDataExportSubmitted}
            />

            <EditProductFieldsModal
                open={editProductFieldsOpen}
                onClose={() => setEditProductFieldsOpen(false)}
                onFieldsEdited={refreshProductFieldsConfig}
            />

            <EditTableSettingsModal
                open={editTableSettingsOpen}
                onClose={() => setEditTableSettingsOpen(false)}
                onTableSettingsEdited={() => {

                }}
            />

            <ConfirmModal
                open={showingDeleteConfirmation}
                onClose={() => {
                    setShowingDeleteConfirmation(false);
                }}
                onConfirm={onConfirmDeleteProduct}
                message='Are you sure you want to delete this product?'
                confirmButtonText={'Delete Product'}
                cancelButtonText={'Cancel'}
            />

            {activeJobs && activeJobs.length ? (
                <ImportalCard title="Active Jobs" style={{ marginBottom: '32px' }}>
                    <ActiveJobsTable activeJobs={activeJobs} />
                </ImportalCard>
            ) : null}

            {products.length === 0 ? (
                <Dropzone onDrop={(acceptedFiles) => handleFilesDropped(acceptedFiles)} noClick>
                    {({ getRootProps, getInputProps }) => (
                        <>

                            <input {...getInputProps()} />
                            <div {...getRootProps()}>
                                <ImportalCard title={'Upload Your Products'}
                                    topRightActionButton={<Tooltip title="Configure Columns">
                                        <IconButton
                                        onClick={() => setEditProductFieldsOpen(true)}
                                        >
                                            <Settings></Settings>
                                        </IconButton>
                                    </Tooltip>}
                                >
                                    <div className="products-list-upload-container">
                                        <div>
                                            <div>Looks like you don't have any products yet. Drag and drop your product list anywhere on the card and we'll do the rest.</div>
                                        </div>

                                    </div>
                                </ImportalCard>
                            </div>
                        </>
                    )}
                </Dropzone>
            ) : (
                <ProductTable
                    ref={importalTableRef}
                    products={products}
                    embeddedStyling={false}
                    productFieldsConfig={productFieldsConfig}
                    onClickEditProduct={onClickEditProduct}
                    onClickDeleteProduct={onClickDeleteProduct}
                    onClickSettings={() => {
                        setEditTableSettingsOpen(true);
                    }}
                    productActionsButton={<ImportalPrimaryButton
                        style={{ width: '120px', borderRadius: '16px', fontSize: '14px' }}
                        onClick={(e) => handleMenuClick(e as React.MouseEvent<HTMLElement>)}
                        text="Actions"
                        endIcon={<ArrowDropDownIcon />}
                    />}
                />
            )}
        </>
    );
}

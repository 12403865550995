import React, {useEffect, useState} from "react";
import {Button, Typography, useTheme} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import './Businesses.css';
import BusinessesTable from "./BusinessesTable";
import AddOrEditBusinessModal from "./AddOrEditBusinessModal";
import BrokerCustomsOnboarding from "@/components/customs-onboarding/BrokerCustomsOnboarding";
import {useAPI} from "@/api/APIContext";
import {Business, BusinessAction, BusinessWithDocuments} from 'common/interfaces/business'
import BrokerCustomsOnboardingModal from "@/components/customs-onboarding-modal/BrokerCustomsOnboardingModal";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import BusinessPage from "@/broker-app/pages/businesses/BusinessPage";

export default function Businesses() {
    const [businesses, setBusinesses] = useState<BusinessWithDocuments[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [businessModalOpen, setBusinessModalOpen] = React.useState(false);
    const [businessIDForViewingInModal, setBusinessIDForViewingInModal] = useState<string | undefined>();
    const [customsOnboardingModalOpen, setCustomsOnboardingModalOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const theme = useTheme();
    const api = useAPI();

    const onClickAddBusiness = () => {
        setBusinessIDForViewingInModal(undefined)
        setBusinessModalOpen(true);
    }

    const onClickEditBusiness = (business: BusinessWithDocuments) => {
        setBusinessIDForViewingInModal(business._id?.toString())
        setBusinessModalOpen(true);
    }

    const onClickEditCustomsOnboarding = () => {
        setCustomsOnboardingModalOpen(true);
    }


    const handleCloseCustomsOnboardingModal = () => {
        setCustomsOnboardingModalOpen(false)
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const createParam = searchParams.get('create');
        if (createParam === 'true' && location.pathname === '/broker/dashboard/businesses') {
            setBusinessModalOpen(true);
        } else {
            setBusinessModalOpen(false);
        }
    }, [location]);

    useEffect(() => {
        setIsLoading(true);
        api.getAllBusinesses()
            .then(({data}) => {
                setBusinesses(data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.error(error);
                setIsLoading(false);
            });
    }, []);


    const onBusinessCreatedModifiedOrDeleted = (businessId: string, action: BusinessAction) => {

        switch (action) {

            case BusinessAction.CREATED:
                navigate(`/broker/dashboard/businesses/${businessId}`);
                break;
            case BusinessAction.MODIFIED:

                api.getBusinessById(businessId)
                    .then(({data: updatedBusiness}) => {

                        const index = businesses.findIndex((business: BusinessWithDocuments) => business._id?.toString() === businessId);
                        if (index !== -1) {
                            let newBusinesses = [...businesses];
                            newBusinesses[index] = updatedBusiness; // Splice in the new product at the same index
                            setBusinesses(newBusinesses);
                        }

                    })
                    .catch(err => {
                        console.error('error getting business by id to update table after modification')
                        console.error(err)
                    })

                break
            case BusinessAction.DELETED:

                // TODO: interesting corner case with deleting.
                // if you are on a paginating page, and the business that was deleted is the only thing on that page
                // i.e. last page with one item,
                // then you would want to mess with the paginating controls while deleting the product
                // i.e. move the user to the last page with contents since the products list will shrink

                setBusinesses(prevBusinesses => prevBusinesses.filter(business => business._id?.toString() !== businessId));
                break
            default:
                console.log('no op')

        }
    }

    return (
        <Routes>
            <Route index element={
                <>
                            <div className="businesses-main-page-header">
                <div className="business-header-text">
                    <Typography sx={{color: theme.palette.primary.main, fontSize: "26px"}}>
                        Businesses
                    </Typography>
                    <div className="universal-subheader">
                        Manage all of our customer's business information in one place.
                    </div>
                </div>
                {/* <div className="business-add-button-container">
                    <Button
                        type="submit"
                        fullWidth
                        onClick={onClickAddBusiness}
                        variant="contained"
                        className="business-add-button-green"
                        endIcon={<AddIcon/>}
                    >
                        Create
                    </Button>
                </div> */}
            </div>
            <div className="shipments-table-container">
                <BusinessesTable
                    rows={businesses}
                    onClickEditBusiness={onClickEditBusiness}
                    onClickEditCustomsOnboarding={onClickEditCustomsOnboarding}
                    isLoading={isLoading}
                    onClickCreateBusiness={onClickAddBusiness}
                    />
            </div>
            {customsOnboardingModalOpen && (
                        <BrokerCustomsOnboardingModal
                            open={customsOnboardingModalOpen}
                            handleClose={handleCloseCustomsOnboardingModal}
                        />
                    )}
            <AddOrEditBusinessModal
                businessId={businessIDForViewingInModal}
                onBusinessCreatedOrModified={onBusinessCreatedModifiedOrDeleted}
                open={businessModalOpen}
                onClose={() => setBusinessModalOpen(false)}
            />
                </>
            }
            />
        <Route path='/*' element={<BusinessPage/>}/>
        </Routes>
    );
}

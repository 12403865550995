import {useMemo} from "react";
import * as d3 from 'd3';

interface Props {
    axisLabel: string
    height: number,
    range: number[]
}

export const NumericYAxis = ({
                                 axisLabel,
                                 height = 1,
                                 range = [10, 290],
                             }: Props) => {
    const ticks = useMemo(() => {
        const yScale = d3.scaleLinear()
            .domain(range)
            .range([0, height]); // Reverse range for Y axis (SVG coordinate system)


        const pixelsPerTick = 40;
        const numberOfTicksTarget = Math.max(
            1,
            Math.floor(
                height / pixelsPerTick
            )
        );

        return yScale.ticks(numberOfTicksTarget)
            .map(value => ({
                value,
                yOffset: yScale(value)
            }));
    }, [
        range.join("-"), height
    ]);

    return (
        <>
            <g transform={`translate(0, 0)`}>
                <path

                    d={[
                        "M", 6, 0,
                        "H", 0,
                        "V", height,
                        "H", 6,
                    ].join(" ")}
                    fill="none"
                    stroke="currentColor"
                />
                {ticks.map(({value, yOffset}) => (
                    <g
                        key={value}
                        transform={`translate(0, ${height - yOffset})`}
                    >
                        <line
                            x2="-6"
                            stroke="currentColor"
                        />
                        <text
                            key={value}
                            style={{
                                fontSize: "10px",
                                textAnchor: "end",
                                transform: "translateX(-10px) translateY(4px)" // Adjusted for better alignment
                            }}>
                            {value}
                        </text>
                    </g>
                ))}
            </g>
            <text transform={`translate(-70, ${height / 2})`}>{axisLabel}</text>
        </>
    )
}

import React from "react";
import {Button} from "@mui/material";
import "./VMCRReview.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ManifestContext from "../manifest-modal/ManifestProvider";
import {useActiveUser} from "@/custom-hooks/user/UserProvider";

export default function VMCRReview({currentBusiness, handleNext, handleBack, isLastStep}) {

    const activeUser = useActiveUser();
    const {currentManifestRequest, setCurrentManifestRequest} = React.useContext(ManifestContext);

    return (
        <div className="card-container">
            <div>
                <div className="importing-card-container">
                    <div className="vmcr-description-title">Review Your Submission</div>
                    <div className="vmcr-business-info-container">
                        <div className="review-header-row">
                            <div className="review-header-row-text">Business Information</div>
                        </div>
                        <div className="vmcr-business-info-row">
                            <div>
                                <div className="vmcr-row-header">Requestor Name</div>
                                <div
                                    className="vmcr-row-text">{activeUser.user?.firstName} {activeUser.user?.lastName}</div>
                            </div>
                            <div>
                                <div className="vmcr-row-header">Title</div>
                                <div className="vmcr-row-text">{currentBusiness.signeeTitle}</div>
                            </div>
                        </div>
                        <div className="vmcr-business-info-row">
                            <div>
                                <div className="vmcr-row-header">Business Name</div>
                                <div className="vmcr-row-text">{currentBusiness.name}</div>
                            </div>
                            <div>
                                <div className="vmcr-row-header">Business EIN</div>
                                <div className="vmcr-row-text">{currentBusiness.ein}</div>
                            </div>
                            <div>
                                <div className="vmcr-row-header">Business Address</div>
                                <div className="vmcr-row-text">{currentBusiness.address}</div>
                            </div>
                        </div>
                        <div className="review-header-row">
                            <div className="review-header-row-text">Suppression Information</div>
                        </div>
                        <div className="vmcr-business-info-row">
                            <div>
                                <div className="vmcr-row-header">Manifest Type</div>
                                <div className="vmcr-row-text">{currentManifestRequest.manifestType}</div>
                            </div>
                            <div>
                                <div className="vmcr-row-header">Entity Name</div>
                                <div className="vmcr-row-text">{currentManifestRequest.entityName}
                                </div>
                            </div>
                            <div>
                                <div className="vmcr-row-header">Name Variations</div>
                                <div className="vmcr-row-text">
                                    {currentManifestRequest.nameVariations?.map((nameVariation, index) => (
                                        <React.Fragment key={index}>
                                            {nameVariation}
                                            {index !== currentManifestRequest.nameVariations!.length - 1 && ', '}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="next-button-container">
                        <Button
                            fullWidth
                            className="back-button-text"
                            variant="text"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            sx={{width: '160px!important'}}
                            className="next-button-green"
                            endIcon={<KeyboardArrowRightIcon/>}
                            onClick={handleNext}
                            variant="contained"
                        >
                            { isLastStep ? "Submit" : "Looks good"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import {Button, Card, IconButton} from "@mui/material";
import React, {cloneElement} from "react";
import './CardWithIconMessageAndCallToAction.css'
import CloseIcon from "@mui/icons-material/Close";


export interface Props {

    closable: boolean,
    handleClose?: () => void,
    headerText: string,
    messageBody: string,
    backgroundColor: string,
    textColor: string,
    actionButtonText: string,
    onClickActionButton?: () => void,
    cardIcon?: React.JSX.Element
}

export function CardWithIconMessageAndCallToAction({
                                                       closable,
                                                       handleClose,
                                                       headerText,
                                                       messageBody,
                                                       textColor,
                                                       backgroundColor,
                                                       cardIcon,
                                                       actionButtonText,
                                                       onClickActionButton
                                                   }: Props) {


    return <Card
        className = {'card-with-icon-message-and-cta-container'}
        sx={{
            background: `${backgroundColor} !important`,
            position: 'relative',
            color: textColor,
            opacity: 0.9
        }}>

        {cardIcon &&
            <div className={'icon-container'}>
                {cloneElement(cardIcon, {style: {fontSize: '48px'}})}
            </div>
        }

        <div className="card-with-icon-message-and-cta-text-container">
            <span className={'header'}>{headerText}</span>
            <span className={'message'}>{messageBody}</span>
        </div>

        { (onClickActionButton && actionButtonText) &&
            <Button
                className={'action-button'}
                onClick={onClickActionButton}
                sx={{
                    color: textColor,
                    fontSize: '14px',
                    textTransform: 'none',
                    borderColor: 'white',
                    backgroundColor: backgroundColor
                }} variant="text">
                {actionButtonText}
            </Button>
        }

        {closable &&
            <IconButton
                onClick={handleClose}
                aria-label="close"
                className={'close-button'}
                sx={{
                    color: textColor,
                }}
                style={{
                    position: 'absolute',
                    right: 4,
                    top: 4,
                }}
            >
                <CloseIcon sx={{fontSize: 'inherit'}}/>
            </IconButton>
        }

    </Card>
}
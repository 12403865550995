import React, { forwardRef, useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useTheme
} from '@mui/material';
import { Decimal } from "decimal.js";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CreateIcon from "@mui/icons-material/Create";
import { useAPI } from "@/api/APIContext";
import { Product, ProductComplianceSummary } from "common/interfaces/product";
import DeleteIcon from "@mui/icons-material/Delete";
import { FitnessCenter, Numbers, Paid, Pin, Public, Settings } from "@mui/icons-material";
import { ProductFieldsConfig } from "common/interfaces/productfields";
import {
    CardWithIconMessageAndCallToAction
} from "@/shared-components/CardWithIconMessageAndCallToAction/CardWithIconMessageAndCallToAction";
import ImportalTable, { ImportalTableHandles } from "@/shared-components/ImportalTable/ImportalTable";
import './ProductTable.css'
import { useActiveUser } from "@/custom-hooks/user/UserProvider";
import { UserSettingName } from "common/interfaces/user";
import InfoAlert from '@/components/info-alert/InfoAlert';

interface ProductTableProps {
    products: Product[];
    embeddedStyling?: boolean;
    productFieldsConfig?: ProductFieldsConfig,
    onClickEditProduct: (string) => void,
    onClickDeleteProduct: (string) => void,
    onClickSettings: () => void,
    productActionsButton: React.JSX.Element
}

const ProductTable = forwardRef<ImportalTableHandles, ProductTableProps>(({
    products,
    embeddedStyling = false,
    onClickEditProduct,
    onClickDeleteProduct,
    onClickSettings,
    productFieldsConfig,
    productActionsButton
}, ref) => {


    return (
        <ImportalTable
            ref={ref}
            data={products}
            embeddedStyling={embeddedStyling}
            rowGenerator={
                (product, index) =>
                    <ProductRow key={index} product={product}
                        productFieldsConfig={productFieldsConfig}
                        onClickEditProduct={onClickEditProduct}
                        onClickDeleteProduct={onClickDeleteProduct}
                        expandElement={<ProductRowExpand
                            key={index}
                            onClickEditProduct={onClickEditProduct}
                            product={product} />} />
            }
            columnConfig={productFieldsConfig?.fields.filter(field => field.showInUI) || []}
            topRightButton={productActionsButton}
            topRightElement={
                <Tooltip title="Product Field Settings">
                    <IconButton
                        id='settings-icon-button'
                        onClick={onClickSettings}
                    >
                        <Settings></Settings>
                    </IconButton>
                </Tooltip>
            }
            subKeyForData='properties'
        />

    )
});

function ProductRow({ product, expandElement, onClickEditProduct, onClickDeleteProduct, productFieldsConfig }) {

    const activeUser = useActiveUser();
    const [open, setOpen] = useState(false);
    const [isHovered, setHovered] = useState(false); // <= Add new state for hover

    useEffect(() => {
        setOpen(false);
    }, [product._id]);

    return (
        <React.Fragment key={product._id}>
            <TableRow
                className={activeUser.getUserSetting(UserSettingName.PRODUCT_LIBRARY_DENSE_MODE) ? 'product-table-row-dense' : undefined}
                key={product._id}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <TableCell className={'importal-table-cell'}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                {
                    productFieldsConfig?.fields
                        .filter(fieldConfig => fieldConfig.showInUI)
                        .map(fieldConfig => <TableCell
                            className={'importal-table-cell'}>{product.properties[fieldConfig.name]}</TableCell>)

                }

                <TableCell className={'importal-table-cell'}>
                    <div style={{ display: "flex" }}>
                        <IconButton
                            style={{ opacity: isHovered ? '1.0' : '0.0' }}
                            id='edit-from-row'
                            onClick={() => onClickEditProduct(product._id)}
                        >
                            <CreateIcon />
                        </IconButton>
                        <IconButton
                            style={{ opacity: isHovered ? '1.0' : '0.0' }}
                            id='delete-from-row'
                            onClick={() => onClickDeleteProduct(product._id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow key={product.sku}>
                <TableCell
                    key={product.sku}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={2 + (productFieldsConfig?.fields.length || 0)
                        //want to make this the full width. 2 is the dropdown cell, plus the settings cell
                    }>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            {expandElement}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>


        </React.Fragment>
    )
}

function ProductRowExpand({ product, onClickEditProduct }) {

    const theme = useTheme();
    const api = useAPI();

    const [missingOrInvalidHTSCode, setMissingOrInvalidHTSCode] = useState<boolean>(false);
    const [missingOrInvalidCountryOfOrigin, setMissingOrInvalidCountryOfOrigin] = useState<boolean>(false);
    const [missingOrInvalidPricingData, setMissingOrInvalidPricingData] = useState<boolean>(false);
    const [missingOrInvalidWeightData, setMissingOrInvalidWeightData] = useState<boolean>(false);
    const [missingOrInvalidQuantityData, setMissingOrInvalidQuantityData] = useState<boolean>(false);

    const [productComplianceSummary, setProductComplianceSummary] = useState<ProductComplianceSummary>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined)


    const evaluateMissingData = (productComplianceSummary: ProductComplianceSummary): boolean => {

        setMissingOrInvalidHTSCode(!productComplianceSummary.hasHTSNumber);
        setMissingOrInvalidCountryOfOrigin(!productComplianceSummary.hasCountryOfOrigin);
        setMissingOrInvalidPricingData(!productComplianceSummary.hasPriceInfo);

        if (productComplianceSummary.errors && productComplianceSummary.errors.length > 0) {
            for (const error of productComplianceSummary.errors) {
                if (typeof error === 'object' && error['code'] === 'MISSING_WEIGHT_INFO') {
                    setMissingOrInvalidWeightData(true);
                }
            }
        }

        if (productComplianceSummary.errors && productComplianceSummary.errors.length > 0) {
            for (const error of productComplianceSummary.errors) {
                if (typeof error === 'object' && error['code'] === 'MISSING_QUANTITY_INFO') {
                    setMissingOrInvalidQuantityData(true);
                }
            }
        }

        if (productComplianceSummary.errors && productComplianceSummary.errors.length > 0) {
            for (const error of productComplianceSummary.errors) {
                if (typeof error === 'object' && error['code'] === 'INVALID_HTSCODE') {
                    setMissingOrInvalidHTSCode(true);
                }
            }
        }

        return !!(productComplianceSummary.hasHTSNumber && productComplianceSummary.hasCountryOfOrigin);
    }

    useEffect(() => {
        setLoading(true);
        api.getProductComplianceSummary(product._id)
            .then(({ data }) => {

                if (evaluateMissingData(data)) {
                    setProductComplianceSummary(data);
                }

                setLoading(false);

            })
            .catch((error) => {
                console.error('Error fetching product compliance summary:', error);
                setLoading(false);
                setError(error)
            });
    }, [api, product, product._id]);

    const moneyToFixed = (money: string) => {
        return new Decimal(money).toFixed(2)
    }

    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px'
        }}>

            <br />

            {/* if missing or invalid HTSCode, show a thing here*/}
            {missingOrInvalidHTSCode &&
                <CardWithIconMessageAndCallToAction
                    textColor={'white'}
                    backgroundColor={theme.palette.common.chillBlue}
                    closable={false}
                    onClickActionButton={() => onClickEditProduct(product._id)}
                    cardIcon={<Pin />}
                    headerText={'Missing or Invalid HTS Code'}
                    messageBody={"This product's HTS Code is missing or invalid."}
                    actionButtonText={"Edit Product"}
                />
            }

            {/* if missing or invalid countryOfOrigin , show a thing here */}
            {missingOrInvalidCountryOfOrigin &&
                <CardWithIconMessageAndCallToAction
                    textColor={'white'}
                    backgroundColor={theme.palette.common.chillBlue}
                    closable={false}
                    onClickActionButton={() => onClickEditProduct(product._id)}
                    cardIcon={<Public />}
                    headerText={'Missing Country of Origin'}
                    messageBody={"This product's Country of Origin is missing or invalid."}
                    actionButtonText={"Edit Product"}
                />
            }

            {/* if missing or invalid pricing Info , show a thing here */}
            {missingOrInvalidPricingData &&
                <CardWithIconMessageAndCallToAction
                    textColor={'white'}
                    backgroundColor={theme.palette.common.chillBlue}
                    closable={false}
                    onClickActionButton={() => onClickEditProduct(product._id)}
                    cardIcon={<Paid />}
                    headerText={'Missing Pricing Data'}
                    messageBody={"This product's pricing data (Unit Cost & Unit Cost Currency) is missing or invalid."}
                    actionButtonText={"Edit Product"}
                />
            }

            {/* if missing or invalid weight Info , show a thing here, the only way to know this though would be to get a response with an error */}
            {missingOrInvalidWeightData &&
                <CardWithIconMessageAndCallToAction
                    textColor={'white'}
                    backgroundColor={theme.palette.common.chillBlue}
                    closable={false}
                    onClickActionButton={() => onClickEditProduct(product._id)}
                    cardIcon={<FitnessCenter />}
                    headerText={'Missing Weight Data'}
                    messageBody={"This product's weight data (Unit Weight & Unit Weight Units) is missing or invalid."}
                    actionButtonText={"Edit Product"}
                />
            }

            {missingOrInvalidQuantityData &&
                <CardWithIconMessageAndCallToAction
                    textColor={'white'}
                    backgroundColor={theme.palette.common.chillBlue}
                    closable={false}
                    onClickActionButton={() => onClickEditProduct(product._id)}
                    cardIcon={<Numbers />}
                    headerText={'Missing Quantity Data'}
                    messageBody={"This product's quantity data (Unit of Measure for Quantity & Quantity per Product) is missing or invalid."}
                    actionButtonText={"Edit Product"}
                />
            }

            {error &&
                <Box style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span>Error Loading Product Compliance Summary</span>
                </Box>
            }

            {loading ?
                <Box style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress id='preview-loading-indicator' color='primary' />
                </Box>
                :
                <>{productComplianceSummary && <div>

                    {(!missingOrInvalidCountryOfOrigin && !missingOrInvalidHTSCode) &&
                        <>
                            <div className="product-expanded-header">Compliance Summary</div>
                            <div className="product-expanded-subheader">
                                <strong>{product.properties['SKU']}</strong> has 
                                {productComplianceSummary.applicableDutiesAndFees?.map((applicableDuty, index, array) => {
                                    const isLast = index === array.length - 1;
                                    return (
                                        <span style={{marginLeft: '3px'}} key={index}>
                                            {applicableDuty.description} of {applicableDuty.rateString}
                                            {isLast ? '.' : ','}
                                        </span>
                                    );
                                })}
                            </div>
                            {(productComplianceSummary.dutyMitigationOutput && Object.keys(productComplianceSummary.dutyMitigationOutput).length > 0) &&
                                <>
                                    <h3>Duty Exclusions</h3>
                                    <ul>
                                        {(productComplianceSummary.dutyMitigationOutput && productComplianceSummary.dutyMitigationOutput.freeTradeExclusion) &&
                                            <>
                                                <li>Free Trade Exclusion</li>
                                                <ul>
                                                    <li>{productComplianceSummary.dutyMitigationOutput.freeTradeExclusion.Description}</li>
                                                    <li>Countries: {productComplianceSummary.dutyMitigationOutput.freeTradeExclusion.Countries}</li>
                                                    <li>{productComplianceSummary.dutyMitigationOutput.freeTradeExclusion["Regulation Source"]}</li>
                                                </ul>
                                            </>
                                        }
                                    </ul>
                                </>
                            }
                        </>
                    }

                    {!!(productComplianceSummary.pricingBreakdown && productComplianceSummary.pricingBreakdown.length > 0) &&
                        <><div className="product-expanded-section-header">Duty Calculation</div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Description</strong></TableCell>
                                            <TableCell><strong>Rate</strong></TableCell>
                                            <TableCell><strong>Amount</strong></TableCell>
                                            <TableCell><strong>Explanation</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productComplianceSummary.pricingBreakdown?.map((duty, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{duty.description}</TableCell>
                                                <TableCell>{duty.rate}</TableCell>
                                                <TableCell>${moneyToFixed(duty.amount.toString())}</TableCell>
                                                <TableCell>{duty.calculationExplained}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer></>
                    }

                </div>
                }</>
            }

        </Box>

    )
}


export default ProductTable;
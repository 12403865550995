import {useMemo} from "react";
import * as d3 from 'd3';

interface Props {
    axisLabel: string,
    categories: string[],
    width: number
}

export const CategoricalXAxis = ({
                                     axisLabel,
                                     categories = [],
                                     width,
                                 }: Props) => {

    const ticks = useMemo(() => {
        const xScale = d3.scaleBand()

            .range([0, width])
            .domain(categories)
            .padding(0.1);

        return categories.map(category => ({
            category,
            xOffset: xScale(category)! + xScale.bandwidth() / 2 // Center the tick
        }));
    }, [categories, width]);

    return (
        <>
            <path
                d={[
                    "M", 0, 6,
                    "v", -6,
                    "H", width,
                    "v", 6,
                ].join(" ")}
                fill="none"
                stroke="currentColor"
            />
            {ticks.map(({category, xOffset}) => (
                <g
                    key={category}
                    transform={`translate(${xOffset}, 0)`}
                >
                    <line
                        y2="6"
                        stroke="currentColor"
                    />
                    <text
                        style={{
                            fontSize: "10px",
                            textAnchor: "middle",
                            transform: "translateY(20px)"
                        }}>
                        {category}
                    </text>
                </g>
            ))}

                <text
                    transform={`translate(${width / 2}, 40)`}
                    style={{textAnchor: "middle"}}
                >
                    {axisLabel}
                </text>


        </>
    )
}

import dayjs from 'dayjs';
import {FeatureName, UsagePlanMetadata} from 'common/interfaces/plan';
import moment from 'moment';

export const activePlanUtils = (planMetadata?: UsagePlanMetadata) => {
    return planMetadata?.planUsage.find(feature => feature.featureName === FeatureName.SEARCHES)?.usage || 0;
}

export const planIsExhaustedForFeature = (featureName: FeatureName, planMetadata?: UsagePlanMetadata): boolean => {
    const feature = planMetadata?.planUsage.find(f => f.featureName === featureName);
    if (feature && feature.limit) {
        return feature.usage >= feature.limit
    }
    return false
}

export const formatMMMDDYYYYDate = (dateString: string) => moment(dateString).format('MMMM Do, YYYY');
export const mmmddyyyyDate = (dateString: string) => dayjs(dateString).format('MMM DD YYYY');
export const mmmddyyHmmDate = (dateString: string) => dayjs(dateString).format('MMM DD YY h:mm A');
import React, {useContext, useEffect, useState} from "react";
import {Button, Fab, IconButton, useTheme} from "@mui/material";
import "./FullOnboarding.css";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import BackdropLoader from "../snackbar-loader/SnackbarLoader";
import CloseIcon from "@mui/icons-material/Close";
import BusinessIcon from '@mui/icons-material/Business';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Person3Icon from '@mui/icons-material/Person3';
import BusinessInfo from "../BusinessInfo/BusinessInfo";
import BusinessCustomsInfo from "../business-customs-info/BusinessCustomsInfo";
import AuthorizedSigner from "../authorized-signer/AuthorizedSigner";
import DocumentsAndSignature from "../documents-and-signature/DocumentsAndSignature";
import {useAPI} from "@/api/APIContext";
import {useActiveBusiness} from "@/custom-hooks/business/BusinessProvider";
import {CustomsOnboarding, CustomsOnboardingSteps} from "common/interfaces/customsOnboarding";
import {useActiveCustomsOnboarding} from "@/custom-hooks/customs-onboarding/CustomsOnboardingProvider";
import {ImportalStepper} from "@/shared-components/ImportalStepper/ImportalStepper";
import {AppContext} from "@/App";

const steps = [
    "Business Info",
    "Authorized Signer",
    "Photo ID & Signature",
    "Customs Info"
];

interface FullOnboardingPops {
    isShowContactUs?: boolean;
    handleClose?: any
}

export default function FullOnboarding({
                                           isShowContactUs = true,
                                           handleClose
                                       }: FullOnboardingPops) {

    const {setOpenContactUs} = useContext(AppContext);

    const theme = useTheme();
    const api = useAPI();
    const activeCustomsOnboarding = useActiveCustomsOnboarding();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [completed, setCompleted] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [customsOnboardingInfo, setCustomsOnboardingInfo] = useState<CustomsOnboarding>();
    const activeBusiness = useActiveBusiness();


    useEffect(() => {
        // only make call to get customs onboarding if we have business and the customs onboarding hasn't loaded!
        if (activeBusiness.business?._id && !customsOnboardingInfo) {
            getCustomsOnboardingInfoForBusiness(activeBusiness.business?._id.toString());
        }
    }, [activeBusiness.business]);


    const mapCustomsOnboardingSteps = (customsOnboardingInfo) => {
        switch (customsOnboardingInfo.step) {
            case CustomsOnboardingSteps.BUSINESS_INFO:
                setActiveStep(0);
                break;
            case CustomsOnboardingSteps.AUTHORIZED_SIGNER:
                setCompleted({
                    0: true
                })
                setActiveStep(1);
                break;
            case CustomsOnboardingSteps.PHOTO_ID:
                setCompleted({
                    0: true,
                    1: true
                })
                setActiveStep(2);
                break;
            case CustomsOnboardingSteps.SIGN_POA:
                setCompleted({
                    0: true,
                    1: true
                })
                setActiveStep(2);
                break;
            case CustomsOnboardingSteps.CUSTOMS_INFO:
                setCompleted({
                    0: true,
                    1: true,
                    2: true
                })
                setActiveStep(3);
                break;
            default:
                break;
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    function handleNext() {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    }

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const updateCustomsOnboarding = (businessId: string, customsOnboarding: any) => {
        api.updateCustomsOnboarding(businessId, customsOnboarding)
            .then((response) => {
                setCustomsOnboardingInfo(response.data);
                activeCustomsOnboarding.refreshCustomsOnboarding();
            })
            .catch((error) => {
                console.error('error updating customs onboarding for business')
                console.error(error);
            });
    }

    const getCustomsOnboardingInfoForBusiness = (businessId: string) => {
        api.getCustomsOnboardingForBusiness(businessId)
            .then((response) => {
                mapCustomsOnboardingSteps(response.data);
                setCustomsOnboardingInfo(response.data);
            })
            .catch((error) => {
                console.error('error getting customs onboarding for business')
                console.error(error);
            })
    }

    const renderStepComponentOnboarding = (activeStep) => {
        switch (activeStep) {
            case 0:
                return (
                    <BusinessInfo
                        handleEvent={handleComplete}
                        handleLoading={setIsLoading}
                        updateCustomsOnboarding={updateCustomsOnboarding}
                        customsOnboarding={customsOnboardingInfo}
                    />
                );
            case 1:
                return (
                    <AuthorizedSigner
                        handleEvent={handleComplete}
                        handleBack={handleBack}
                        handleClose={handleClose}
                        handleLoading={setIsLoading}
                        updateCustomsOnboarding={updateCustomsOnboarding}
                        customsOnboarding={customsOnboardingInfo}
                    />
                );
            case 2:
                return (
                    <DocumentsAndSignature
                        handleEvent={handleComplete}
                        handleBack={handleBack}
                        handleLoading={setIsLoading}
                        updateCustomsOnboarding={updateCustomsOnboarding}
                        customsOnboarding={customsOnboardingInfo}
                    />
                );
            case 3:
                return (
                    <BusinessCustomsInfo
                        handleEvent={handleComplete}
                        handleBack={handleBack}
                        handleLoading={setIsLoading}
                        updateCustomsOnboarding={updateCustomsOnboarding}
                        customsOnboarding={customsOnboardingInfo}
                    />
                );
        }
    };


    return (
        <div className="onboarding-container">
            <div>
                <IconButton
                    onClick={handleClose}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                >
                    <CloseIcon sx={{fontSize: '24px'}}/>
                </IconButton>
                <div className="my-shipments-header">Customs Onboarding</div>
                <div className="my-shipments-subheader" style={{marginBottom: '20px'}}>Fill out these four quick steps
                    and we'll get to clearing your customs business.
                </div>
                <ImportalStepper
                    steps={[
                        {icon: <BusinessIcon/>, label: "Business Info"},
                        {icon: <Person3Icon/>, label: "Authorized Signer"},
                        {icon: <HistoryEduIcon/>, label: "Photo ID & Signature"},
                        {icon: <AssuredWorkloadIcon/>, label: "Customs Info"}

                    ]}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    completed={completed}
                />
                {(activeStep !== 0 || isLoading) && (
                    <BackdropLoader isLoading={isLoading}/>
                )}
                {allStepsCompleted() ? (
                    <>
                        <div className="customs-onboarding-confirmation">
                            <div className="confirmation-title">You're all set!</div>
                            <div className="confirmation-subtitle">Thanks for customs onboarding with us. Our brokers
                                will reach out with next steps.
                            </div>
                        </div>
                        <div className="next-button-container">
                            <Button
                                type="submit"
                                color="info"
                                size="medium"
                                onClick={handleClose}
                                variant="contained"
                                className="next-button-green"
                            >
                                Close
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="step-component-bi-container">
                        {renderStepComponentOnboarding(activeStep)}
                    </div>
                )}
            </div>
            <div className="need-help">
                {isShowContactUs && <Fab
                    onClick={() => {
                        setOpenContactUs(true)
                    }}
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                        textTransform: "none",
                    }}
                    variant="extended"
                >
                    <ContactSupportIcon sx={{mr: 1}}/>
                    Need help? Reach out whenever
                </Fab>}
            </div>
        </div>
    );
}

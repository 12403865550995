import React, {useEffect} from "react";
import './EsignSuccess.css'
import {useActiveBusiness} from "@/custom-hooks/business/BusinessProvider";
import {BusinessWithDocuments} from "common/interfaces/business";
import {DocumentType} from "common/interfaces/document";
import * as ls from 'local-storage'

const EsignSuccess = () => {


    const activeBusiness = useActiveBusiness();


    const longPollForPOASigned = async () => {
        // when this loads (in an iframe btw -- we know that the user has completed their POA document
        // now we will long poll useActiveBusiness w/ RefreshBusiness until we see the document[]
        // that is that signedPOA on the business
        // only then will we set a ls.set() that will be long polled over on the actual app to enable next button

        const business = activeBusiness.business
        do {
            await new Promise(resolve => setTimeout(resolve, 3000)) // wait 3 seconds
            activeBusiness.refreshBusiness()
        } while (!businessHasSignedPOA(business))

        // when loop breaks, we are good!
        const businessId = activeBusiness.business!._id!.toString()
        ls.set(`poa-signed-for-business-${businessId}`, true)
    }

    useEffect(() => {

        const businessId = activeBusiness.business?._id?.toString()
        if(!businessId) return

        ls.set(`poa-signed-for-business-${businessId}`, true)

        // TODO(cage): figure out some way to actually check that business has recieved document
        // longPollForPOASigned()

    }, [activeBusiness.business]);


    const businessHasSignedPOA = (business?: BusinessWithDocuments): boolean => {
        if (!business) return false
        return business.documents.some(document => document.type === DocumentType.POA)
    }

    return (
        <div className="success-container">
            <div>
                <div>
                    <div className="my-shipments-header">
                        Thank you for signing the Importal Power of Attorney
                    </div>
                    <div className="my-shipments-subheader">
                        You will receive the finalized document shortly via email.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EsignSuccess;

import ReportChart from "@/pages/reports/ReportChart";
import ReportTable from "@/pages/reports/ReportTable";
import React, {useEffect, useState} from "react";
import {Chip, TextField, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import {Report, ReportChartType, ReportState} from "common/interfaces/reports";
import {useAPI} from "@/api/APIContext";
import {useBetaFeatures} from "@/custom-hooks/beta-feature/BetaFeatureContext";


export interface Props {
    report: Report,
    onAdvancedClicked: () => void
}

export default function ReportDraft({report, onAdvancedClicked}: Props) {

    const theme = useTheme();
    const api = useAPI();
    const betaFeatures = useBetaFeatures();

    const [reportName, setReportName] = useState<string>(report.name)
    const [reportData, setReportData] = useState<any[]>([])


    useEffect(() => {
        api.getReportData(report._id.toString())
            .then(({data: reportData}) => {
                setReportData(reportData)
            })
            .catch(err => {
                console.error('error getting report data')
                console.error(err)
            })
    }, [report]);


    const handleReportNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReportName(event.target.value);


        api.updateReport(report._id.toString(), {
            ...report,
            name: event.target.value,
            state: ReportState.COMPLETED
        })
            .then(({data}) => {


            })
            .catch(err => {
                console.error('error updating report')
                console.error(err)
            })

    }

    return <>

        <div style={{display: "flex", justifyContent: 'space-between', paddingBottom: theme.spacing(2)}}>
            <div style={{display: "flex", alignItems: 'center', gap: theme.spacing(2)}}>
                <TextField
                    variant="filled"
                    InputProps={{
                        style: {
                            paddingTop: '5px',
                            fontWeight: 'bold',
                            fontSize: '1.5em',  // Added font size
                            minWidth: '100px',  // Added minimum width
                            width: `${reportName.length * 0.75}em`, // Width grows with more characters in report name

                        },
                        disableUnderline: true
                    }}

                    InputLabelProps={{
                        style: {
                            paddingTop: '5px',
                            fontWeight: 'bold',
                        },
                    }}
                    inputProps={{
                        style: {
                            paddingTop: '4px',
                            borderBottom: 'none',
                        },
                    }}
                    id={'report-draft-name-field'}
                    size={'small'}
                    value={reportName}
                    onChange={handleReportNameChange}/>
                <Chip label={report.state}/>
            </div>

            <div>
                {betaFeatures.includes('reporting-advanced') &&
                    <IconButton>
                        <SettingsIcon onClick={onAdvancedClicked}></SettingsIcon>
                    </IconButton>
                }
            </div>
        </div>

        {(report.reportConfig?.chartConfig?.chartType) && (report.reportConfig?.chartConfig.chartType !== ReportChartType.NO_CHART) &&
            <ReportChart chartConfig={report.reportConfig?.chartConfig} data={reportData}>
            </ReportChart>
        }

        <ReportTable data={reportData} columnConfig={report.reportConfig?.columns}>
        </ReportTable>


    </>

}
import React, {useEffect, useState} from "react";
import {useAPI} from "@/api/APIContext";
import {Classification, ClassificationStatus, ClassificationStatusToShortText} from "common/interfaces/classification";
import {User} from "common/interfaces/user";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {ViewPhotos} from "@/shared-components/ViewPhotos/ViewPhotos";

interface Props {
    classificationRequestId: string,
    onClassificationChanged?: () => void
}

export function BrokerViewClassification({classificationRequestId, onClassificationChanged}: Props) {



    return <></>
}
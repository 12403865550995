import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {useAPI} from "@/api/APIContext";
import * as ls from 'local-storage';
import {datadogRum} from '@datadog/browser-rum';
import {FeatureName, UnknownPlan, UsagePlanAny, UsagePlanMetadata} from 'common/interfaces/plan'
import {useActiveUser} from "@/custom-hooks/user/UserProvider";
import {
    isFreePlan,
    isNormalProPlan,
    isVMCRPlan,
    isUserPlanAdmin,
    hasAvailableVMCR,
    hasPlanAdmins, hasAvailableSearches
} from 'common/utilities/planUtility';

interface ActivePlanContextProps {
    children: ReactNode;
}

export type IActivePlanContext = {
    plan: UsagePlanAny;
    metadata?: UsagePlanMetadata,
    refreshPlan: () => void
    isFreePlan: boolean,
    isVMCRPlan: boolean,
    isNormalProPlan: boolean,
    isUserPlanAdmin?: boolean,
    hasAvailableVMCR: boolean,
    hasAvailableSearches: boolean,
    hasPlanAdmin: boolean,
    isPlanCanceled: boolean,
}
const ActivePlanContext = createContext<IActivePlanContext>({
    plan: UnknownPlan,
    refreshPlan: () => {},
    isFreePlan: true,
    isVMCRPlan: false,
    isNormalProPlan: false,
    isUserPlanAdmin: undefined,
    hasAvailableVMCR: false,
    hasAvailableSearches: true,
    hasPlanAdmin: false,
    isPlanCanceled: false,
});

export const ActivePlanProvider: React.FC<ActivePlanContextProps> = ({children}) => {

    const activeUser = useActiveUser();
    const api = useAPI();

    const [activePlan, setActivePlan] = useState<UsagePlanAny>(UnknownPlan)
    const [activePlanMetadata, setActivePlanMetadata] = useState<UsagePlanMetadata | undefined>(undefined)

    const updateSearchCountFromAPI = useCallback((searchCount: number) => {

        setActivePlanMetadata(prev => {
            if (prev) {
                const newPlanUsage = prev.planUsage.map(item =>
                    item.featureName === FeatureName.SEARCHES
                        ? {...item, usage: searchCount}
                        : item
                );
                return {...prev, planUsage: newPlanUsage}
            }
            return prev;
        });

    }, []);

    useEffect(() => {
        api.addSearchCountListener(updateSearchCountFromAPI)
    }, [api]);

    const getPlanAndMetadata = useCallback(async () => {
        try {
            const {data: plan} = await api.getActivePlan();
            datadogRum.setUserProperty('plan', plan)
            setActivePlan(plan)
            ls.set('active-plan', plan)

            const {data: metadata} = await api.getActivePlanMetadata();
            setActivePlanMetadata(metadata)

        } catch (err) {
            console.error('error getting plan for user', err)
        }

    }, [api]);

    const isPlanCanceled = () => {
        return activePlan.status === "canceled"
    }

    useEffect(() => {

        const activePlan = ls.get('active-plan')
        if (activePlan) {
            // setActivePlan(activePlan)
        }

        if (activeUser.user) {
            getPlanAndMetadata()
        }

    }, [activeUser.user, getPlanAndMetadata]);

    const refreshPlan = () => {
        getPlanAndMetadata()
    }

    return <ActivePlanContext.Provider
        value={{
            plan: activePlan,
            metadata: activePlanMetadata,
            refreshPlan,
            isFreePlan: isFreePlan(activePlan),
            isVMCRPlan: isVMCRPlan(activePlan),
            isNormalProPlan: isNormalProPlan(activePlan),
            isUserPlanAdmin: isUserPlanAdmin(activeUser.user, activePlanMetadata),
            hasAvailableVMCR: hasAvailableVMCR(activePlanMetadata),
            hasAvailableSearches: hasAvailableSearches(activePlanMetadata),
            hasPlanAdmin: hasPlanAdmins(activePlanMetadata),
            isPlanCanceled: isPlanCanceled(),
        }}>{children}</ActivePlanContext.Provider>

};


export const useActivePlan = (): IActivePlanContext => {
    const activePlan = useContext(ActivePlanContext);
    if (!activePlan) {
        throw new Error('useActivePlan must be used within an ActivePlanProvider');
    }
    return activePlan;
};
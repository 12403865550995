import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CircularProgress, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './PasswordReset.css';
import * as ls from "local-storage";

export default function PasswordReset() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken]: any = useState("");
  const [userId, setUserId]: any = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userToSubmit = {
      password : data.get("new-password"),
      confirmPassword : data.get("confirm-password"),
      resetToken: token
    };
    if (userToSubmit.password === userToSubmit.confirmPassword) {
        updateUserPassword(userToSubmit);
    } else {
        setErrorText("Double check that, looks like those passwords don't match.")
    }
  };

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setToken(params.get('token'));
  })

  const updateUserPassword = (user) => {
    handleLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`, user)
      .then(function (response) {
        handleLoading(false);
        navigate("/login");
      })
      .catch(function (error) {
        handleLoading(false);
        console.error(error);
        setErrorText(error.response.data.error.message);
      });
  };

  return (
    <div>
      <Container
        sx={{ display: "flex", width: "100%", minWidth: '650px', justifyContent: "space-between" }}
      >
        <Container>
          <img src="importal-2.png" height="100" width="160" alt="logo" />
        </Container>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography>
            <Link
              sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
              href="https://importal.com"
              variant="body2"
            >
              Return to importal.com
            </Link>
          </Typography>
        </Container>
      </Container>
      <div className="forgot-password-card-container">
        <Card sx={{padding: '32px', minWidth: '550px'}}>
          <CssBaseline />
          <Box>
            <div className="welcome-header-text">
              <Typography >
                Update your Importal password
              </Typography>
            </div>
            <div className="my-shipments-subheader">Enter your new password below, and make sure both fields match.</div>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="new-password"
                    label="New Password"
                    type="password"
                    name="new-password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="confirm-password"
                    label="Confirm Password"
                    type="password"
                    name="confirm-password"
                    autoComplete="confirm-password"
                  />
                </Grid>
              </Grid>
              <Grid className="error-text">{errorText}</Grid>
              <div>
                <div className="forgot-password-button-container">
                  <Button
                    type="submit"
                    fullWidth
                    sx={{width: '110px!important'}}
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="success" size={20} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        </Card>
      </div>
    </div>
  );
}

import {List, ListItem} from "@mui/material";
import React from "react";


interface Props {
    columns?: Array<string>,
    data: any[],
    renderListItem: (datum: any, index: number) => React.JSX.Element
}

export default function ImportalSimpleTable({columns, data, renderListItem}: Props) {
    return <>

        {(columns && columns.length) &&

            <ListItem className={'importal-list-item'} style={{gridTemplateColumns: '1fr '.repeat(columns?.length || 1).trim()}}>
                {
                    columns.map((column, index) => {
                        return <strong key={`${column}-${index}`}>{column}</strong>
                    })
                }
            </ListItem>

        }
        <List className={'importal-list'} >

            {(data && data?.length > 0) ? data?.map((datum, index) => (

                <ListItem
                    key={`${index}-${datum}`}
                    className={'importal-list-item'}
                    style={{gridTemplateColumns: '1fr '.repeat(columns?.length || 1).trim()}}
                >
                    {renderListItem(datum, index)}
                </ListItem>

            )) : (
                <ListItem style={{justifyContent: 'center'}}>
                    <div>No data</div>
                </ListItem>
            )}
        </List>
    </>
}
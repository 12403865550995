import React, {createContext, ReactNode, useContext, useState} from 'react';
import DataImportLoader, {LoadingState, LoadingStatus} from "@/components/data-import-loader/DataImportLoader";

interface LoaderContextProps {
    children: ReactNode;
}

export type ILoaderContext = {
    setLoadingState: (LoadingState) => void
}
const LoaderContext = createContext<ILoaderContext>({
    setLoadingState: (LoadingState) => {},
});

export const LoaderProvider: React.FC<LoaderContextProps> = ({children}) => {

    const [loadingState, setLoadingState] = useState<LoadingState>({loadingStatus: LoadingStatus.NOT_LOADING})

    const setLoadingStateExternal = (loadingState: LoadingState) => {
        setLoadingState(loadingState)
    }


    return <LoaderContext.Provider value={{setLoadingState: setLoadingStateExternal}}>
        {children}
        <div
            id={'loader-container-div'}
            style={{
            bottom: 0,
            zIndex: 99,
            position: "fixed"
        }} ><DataImportLoader
            loadingState={loadingState}
            loadingText={'Loading'}
            successText={'Woohoo!'}
            errorText={'Uh oh!'}
        /></div>
    </LoaderContext.Provider>

};

export const useLoader = (): ILoaderContext => {
    return useContext(LoaderContext);
};

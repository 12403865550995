import React, {useEffect, useState} from "react";
import {useAPI} from "@/api/APIContext";
import {Classification, ClassificationPopulated, ClassificationStatus, ClassificationStatusToShortText, convertPopulatedToClassification} from "common/interfaces/classification";
import {User} from "common/interfaces/user";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {ViewPhotos} from "@/shared-components/ViewPhotos/ViewPhotos";

interface Props {
    classificationRequestId: string,
    onClassificationChanged?: () => void
}

export function ConsumerViewClassification({classificationRequestId, onClassificationChanged}: Props) {

    const api = useAPI();
    const [loading, setLoading] = useState(false)

    const [classification, setClassification] = useState<ClassificationPopulated | undefined>(undefined);


    const validateClassification = (classification): boolean => {
        return true
    }


    useEffect(() => {
        setLoading(true)
        api.getClassificationById(classificationRequestId)
            .then(({data}) => {
                setClassification(data)
            })
            .catch(err => {
                console.error('error getting classification in consumer UI')
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })

    }, [classificationRequestId])


    const updateClassificationRequest = () => {
        setLoading(true)
        const classificationToUpdate: Classification = convertPopulatedToClassification(classification as ClassificationPopulated);
        api.updateClassificationWithId(classificationRequestId, classificationToUpdate!)
            .then(({data}) => {
                setClassification(data)
                onClassificationChanged?.()
            })
            .catch((err) => {
                console.error('error updating classification in broker UI')
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updateClassificationField = (fieldName: string, newValue: any) => {
        const updatedClassification = {...classification}
        updatedClassification[fieldName] = newValue
        setClassification(updatedClassification as ClassificationPopulated)
    }


    const updateMessageField = (newValue: string) => {
        setClassification({
            ...classification as ClassificationPopulated,
            messages: [...classification!.messages, {
                user: 'Consumer:',
                message: newValue,
                timestamp: new Date().toISOString()
            }]
        })
    }

    return <>
        {loading &&
            <Box style={{
                height: '400px',
                flexGrow: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress size={60} color='primary'/>
            </Box>
        }
        {(!loading && classification) &&
            <>
                <br/>
                <Divider/>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '16px'}}>
                    <div>
                        <h3>Description</h3>
                        <TextField
                            style={{width: '100%'}}
                            size={'small'}
                            label="Description"
                            value={classification.description || ""}
                            onChange={(e) => updateClassificationField('description', e.target.value)}
                        />
                    </div>


                    <div>
                        <h3>Material Composition</h3>
                        <TextField
                            style={{width: '100%'}}
                            size={'small'}
                            label="Material Composition"
                            value={classification.materialComposition || ""}
                            onChange={(e) => updateClassificationField('materialComposition', e.target.value)}
                        />
                    </div>

                    <div>
                        <h3>Intended Use</h3>
                        <TextField
                            style={{width: '100%'}}
                            size={'small'}
                            label="Intended Use"
                            value={classification.intendedUse || ""}
                            onChange={(e) => updateClassificationField('intendedUse', e.target.value)}
                        />
                    </div>

                    <div>
                        <h3>Submitted By</h3>
                        <Typography>{classification.user.toString()}</Typography>
                    </div>


                    <div
                        style={{gridColumnStart: '1', gridColumnEnd: '5'}}
                    >
                        <h3>Photos</h3>
                        {(classification.documents.length > 0) ?
                            <ViewPhotos documentIds={classification.documents.map(doc => doc._id)}/> :
                            <>No Photos</>
                        }
                    </div>


                    <div style={{gridColumnStart: '2', gridColumnEnd: '3'}}>
                        <h3>Status</h3>
                        <TextField
                            style={{width: '100%'}}
                            label="Status"
                            select
                            size={'small'}
                            value={classification.status || "None"}
                            onChange={(e) => updateClassificationField('status', e.target.value)}
                        >
                            {Object.values(ClassificationStatus).map((status) => (
                                <MenuItem key={status}
                                          value={status}>{ClassificationStatusToShortText[status]}</MenuItem>
                            ))}
                        </TextField>
                    </div>

                    <div style={{gridColumnStart: '3', gridColumnEnd: '4'}}>
                        <h3>HTS Code</h3>
                        <TextField
                            style={{width: '100%'}}
                            size={'small'}
                            label="Final HTS Code"
                            value={classification.finalHTSCode || ""}
                            onChange={(e) => updateClassificationField('finalHTSCode', e.target.value)}
                        />
                    </div>

                    <div style={{gridColumnStart: '1', gridColumnEnd: '5'}}>
                        <h3>Messages</h3>
                        <List>
                            {classification.messages.map((message, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={message.user} secondary={message.message}/>
                                </ListItem>
                            ))}
                            <ListItem>
                                <TextField
                                    label="New Message"
                                    fullWidth
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            // @ts-ignore
                                            updateMessageField(e.target.value);
                                        }
                                    }}
                                />
                            </ListItem>
                        </List>
                    </div>


                </div>

                <div style={{display: 'flex', justifyContent: 'right'}}>

                    <Button
                        disabled={!validateClassification(classification)}
                        color="primary"
                        variant="contained"
                        onClick={updateClassificationRequest}
                    >
                        Save
                    </Button>
                </div>
            </>
        }


    </>
}
import React, { RefObject, useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import AddNewCard from '@/pages/settings/AddNewCard';
import { useNavigate } from 'react-router';
import cardLogo from '../card-logo/CardLogo';
import { CircularProgress, Typography } from '@mui/material';
import { useAPI } from '@/api/APIContext';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

type SavedCardListProps = {
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setActiveStep?: (value: string) => void;
  upgradeRef?: RefObject<HTMLButtonElement>;
  plan: string;
  setDiscount?: (value: number) => void;
};

const SavedCardList = ({ open, setOpen, plan, setActiveStep, upgradeRef, setDiscount }: SavedCardListProps) => {
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedCard, setSelectedCard] = useState<any>();
  const [addCard, setAddCard] = useState(false);
  const [couponCode, setCouponCode] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const api = useAPI();
  const activePlan = useActivePlan();

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const getCardDetails = useCallback(() => {
    handleLoading(true);
    if (upgradeRef?.current) {
      upgradeRef.current.style.display = 'block';
    }
    api
      .getSavedCardsForPlan()
      .then(function ({ data }) {
        if (data.length === 0) {
          setAddCard(true);
          if (upgradeRef?.current) {
            upgradeRef.current.style.display = 'none';
          }
        }
        setCardDetails(data);
        const defaultCard = data.filter((item: any) => item?.isDefault && item.card.last4)[0];
        setSelectedCard(defaultCard);
        handleLoading(false);
      })
      .catch(function (error) {
        handleLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getCardDetails();
  }, [getCardDetails]);

  const handleClose = () => {
    console.log('close');
  };

  const onPayClick = useCallback(async () => {
    if (upgradeRef?.current) {
      upgradeRef.current.disabled = true;
    }
    handleLoading(true);

    api
      .createPurchase({
        plan,
        coupon: couponCode ? couponCode : undefined,
      })
      .then(function (response) {
        handleLoading(false);

        // setHasActiveSubscription(true)
        if (setActiveStep) {
          setActiveStep('success');
        }
        navigate('/dashboard');
        setOpen(false);
      })
      .catch(function (error) {
        // if(setActiveStep){
        // setActiveStep("failcarddetails")}
        handleLoading(false);
        console.error(error);
      });
  }, [couponCode]);

  useEffect(() => {
    if (upgradeRef?.current) {
      upgradeRef.current.onclick = () => {
        onPayClick();
      };
    }
  }, [onPayClick, upgradeRef]);

  const onDefaultClick = async (card: any) => {
    api.setActivePlanDefaultCard({ paymentMethodId: card.id }).catch(function (error) {
      console.error(error);
    });
  };

  const handleCardChange = (item: any) => {
    setSelectedCard(item);
    onDefaultClick(item);
  };

  return (
    <div className='cardWrapper'>
      <DialogTitle>
        <Typography variant='h5' sx={{ margin: 0, fontWeight: '700' }}>
          {upgradeRef?.current ? ' ' : plan}
        </Typography>
        <IconButton
          aria-label='close'
          onClick={() => setOpen(() => !open)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {!addCard && isLoading ? (
          <div
            style={{
              display: 'flex',
              height: '200px',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color='inherit' size={20} />
          </div>
        ) : cardDetails.length && !addCard ? (
          cardDetails.map((item: any, index: number) => {
            return (
              <div className='card-main-wrapper' key={index}>
                <div className='card-content'>
                  <div className='img-icon'>{cardLogo(item.card.brand)}</div>
                  <div className='card-element'>
                    <p style={{ color: '#525256', fontWeight: '600' }}>{item.billing_details.name}</p>
                    <div>
                      <span className='asterick'>**** **** **** </span>
                      <span
                        style={{ color: 'grey' }}
                      >{`${item?.card?.last4} (${item.card.exp_month}/${item.card.exp_year})`}</span>
                    </div>
                  </div>
                </div>
                <div className='radio-btn'>
                  <Radio checked={selectedCard?.id === item?.id} onChange={() => handleCardChange(item)} />
                </div>
              </div>
            );
          })
        ) : (
          addCard && (
            <div>
              <Elements stripe={stripePromise}>
                <AddNewCard
                  handleLoading={handleLoading}
                  getCardDetails={getCardDetails}
                  cardDetails={cardDetails}
                  setNewCard={setAddCard}
                  isLoading={isLoading}
                  handleClose={handleClose}
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  setDiscount={setDiscount}
                />
              </Elements>
            </div>
          )
        )}
      </DialogContent>
      {!addCard && (
        <DialogActions className='card-bottom-pay'>
          {!upgradeRef?.current && (
            <>
              <Button color='info' className='btn-custom' variant='contained' onClick={() => setAddCard(true)}>
                Add Card
              </Button>
              <Button color='info' className='btn-custom' variant='contained' onClick={onPayClick} disabled={isLoading}>
                Pay
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </div>
  );
};

export default SavedCardList;

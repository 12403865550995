import React, { useEffect, useState } from "react";
import './CalChat.css';
import { Card, IconButton, CardContent, Typography, useTheme, Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import { useAPI } from "@/api/APIContext";

export default function CalChat() {
    const theme = useTheme();
    const api = useAPI();
    const [userInput, setUserInput] = useState("");
    const [currentChatId, setCurrentChatId] = useState("");
    const [currentChat, setCurrentChat]: any = useState([]);
    const [allUserChats, setAllUserChats]: any = useState([]);
    const [thisWeeksChats, setThisWeeksChats]: any = useState([]);
    const [lastWeeksChats, setLastWeeksChats]: any = useState([]);
    const [earlierChats, setEarlierChats]: any = useState([]);

    const handleUserInput = (e) => {
        setUserInput(e.target.value);
    }

    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
          sendUserMessage()
        }
    };

    const handleChatClick = (chatId: string) => {
        api.getAllChatMessages(chatId).then((response) => {
            setCurrentChat(response.data);
        })
        setCurrentChatId(chatId);
    }

    const categorizeChatsByDate = (chats) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        // Find the most recent Sunday
        const mostRecentSunday = new Date(today);
        mostRecentSunday.setDate(today.getDate() - today.getDay());
    
        // Find the Sunday before the most recent Sunday
        const previousSunday = new Date(mostRecentSunday);
        previousSunday.setDate(mostRecentSunday.getDate() - 7);
    
        const thisWeekUserChats: any = [];
        const lastWeekUserChats: any = [];
        const earlierUserChats: any = [];
    
        chats.forEach((chat) => {
            const createdAtDate = new Date(chat.createdAt);
            createdAtDate.setHours(0, 0, 0, 0);
    
            if (createdAtDate >= mostRecentSunday) {
                thisWeekUserChats.push(chat);
            } else if (createdAtDate >= previousSunday) {
                lastWeekUserChats.push(chat);
            } else {
                earlierUserChats.push(chat);
            }
        });
    
        setThisWeeksChats(thisWeekUserChats);
        setLastWeeksChats(lastWeekUserChats);
        setEarlierChats(earlierUserChats);
    };

    const sortByCreatedAt = (arr) => {
        return arr.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }

    useEffect(() => {
        api.getUserChats().then((response) => {
            setAllUserChats(response.data);
            const sortedChats = sortByCreatedAt(response.data);
            if (sortedChats.length > 0) {
                handleChatClick(sortedChats[0]._id);
            } else {
                handleCreateNewChat();
            }
            categorizeChatsByDate(sortedChats);
        });
    }, [])

    const sendUserMessage = () => {
        const tentativeUserMessage = {
            content: userInput,
            author: "USER"
        }
        const tentativeCalMessage = {
            content: "Compliance greatness loading...",
            author: "CAL"
        }
        setCurrentChat([
            ...currentChat,
            tentativeUserMessage,
            tentativeCalMessage
        ])
        api.addMessageToChat(currentChatId, userInput).then((response: any) => {
            api.getAllChatMessages(currentChatId).then((response) => {
                setCurrentChat(response.data);
            })
        })
        setUserInput("");
    }

    const handleCreateNewChat = () => {
        api.createNewChat().then((response: any) => {
            setCurrentChatId(response.data._id);
            setCurrentChat([]);
        })
    }

    const renderNewChatScreen = () => {
        return (
            <>
                <div className="new-chat-logo-container">
                    <img height="90" width="90" src="../cal-icon.png" />
                </div>
                <div className="new-chat-text-container">
                    How can I help you in your compliance journey?
                </div>
            </>
        )
    }

    const renderCurrentChat = (): any => {
        let chatToReturn: any = []
        currentChat.forEach(message => {
            switch (message.author) {
                case "CAL":
                    chatToReturn.push(
                        <>
                            <div className="cal-content-container">
                                <div className="cal-logo-container">
                                    <img height="42" width="42" src="../cal-icon.png" />
                                </div>
                                <div className="cal-message-container">
                                    <div className="cal-name">Cal</div>
                                    <div className="cal-message">{message.content}</div>
                                </div>
                            </div>
                        </>
                    );
                    break;
                case "USER":
                    chatToReturn.push(
                        <>
                            <div className="user-content-container">
                                <div className="user-logo-container">
                                    <Avatar alt="Bryce Oldham" src="/static/images/avatar/1.jpg" />
                                </div>
                                <div className="user-message-container">
                                    <div className="user-name">You</div>
                                    <div className="user-message">{message.content}</div>
                                </div>
                            </div>
                        </>
                    )
                    break;
            }
        });
        return chatToReturn;
    }

    return (
        <div className="main-page-header">
            <div className="cal-header">
                <div className="cal-header-container">
                    <div>
                        <Typography sx={{color: theme.palette.primary.main, fontSize: '26px'}}>Chat with Cal</Typography>
                    </div>
                    <div className="universal-subheader">
                        Cal is our resident AI Compliance expert. He'll walk you through any of your compliance questions.
                    </div>
                </div>
                <div className="add-chat-container">
                    <div className="add-chat-button-container">
                        <Button
                            startIcon={<AddIcon/>}
                            className='add-chat-button-green'
                            onClick={handleCreateNewChat}
                        >
                            New Chat
                        </Button>
                    </div>
                </div>
            </div>
            <Card sx={{height: '100%'}}>
                <CardContent>
                    <div className="chat-card-container">
                        <div className="chat-history-container">
                            <div className="chat-history-header">Your Previous Chats</div>
                            <div className="chat-history-content">
                                <div className="this-week">
                                    <div className="this-week-header">This Week</div>
                                    {thisWeeksChats.length > 0 ? thisWeeksChats.map((chat) => (
                                        <div className="this-week-content">
                                            <Button className="chat-history-button" onClick={() => handleChatClick(chat._id)}  variant="text">{chat._id}</Button>
                                        </div>
                                    )) : <div className="no-chats">No chats this week</div> }
                                </div>
                                <div className="last-week">
                                    <div className="last-week-header">Last Week</div>
                                    {lastWeeksChats.length > 0 ? lastWeeksChats.map((chat) => (
                                        <div className="this-week-content">
                                            <Button className="chat-history-button" variant="text">{chat._id}</Button>
                                        </div>
                                    )) : (<div className="no-chats">No chats last week</div>)}
                                </div>
                                <div className="earlier">
                                    <div className="earlier-header">Earlier</div>
                                    {earlierChats.length > 0 ? earlierChats.map((chat) => (
                                        <div className="this-week-content">
                                            <Button className="chat-history-button" onClick={() => handleChatClick(chat._id)} variant="text">{chat._id}</Button>
                                        </div>
                                    )): (<div className="no-chats">No earlier chats</div>)}
                                </div>
                            </div>
                        </div>
                        <div className="current-chat-container">
                            <div className="current-chat-content">
                                {currentChat.length > 0 ? renderCurrentChat() : renderNewChatScreen()}
                            </div>
                            <div className="current-chat-input">
                                <TextField value={userInput} onKeyDown={handleEnterPress} onChange={handleUserInput} fullWidth variant="outlined" />
                                <IconButton onClick={sendUserMessage}><SendIcon /></IconButton>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    
        );
};
import {useCallback} from "react";

import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {useActiveUser} from "@/custom-hooks/user/UserProvider";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 560,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "32px",
    borderRadius: "4px"
};

const PaymentSuccess = ({setActiveStep, closeHandler}) => {

    const activeUser = useActiveUser();

    const handleSuccessClick = useCallback(() => {
        activeUser.refreshUser()
        setActiveStep("");
        closeHandler();
    }, []);


    return (
        <Box sx={{...style, maxHeight: 400}}>
            <div className="header-question">
                <Typography>
                    Your plan has been updated!
                </Typography>
            </div>
            <div className="unlading-descriptive-text">
                Feel free to hit close to head back to the app.
            </div>

            <Box className="next-button-container">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSuccessClick}
                    className="next-button-green"
                >
                    Close
                </Button>
            </Box>
        </Box>
    );
};

export default PaymentSuccess;

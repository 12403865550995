import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement="bottom-end"
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "charcoal",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "charcoal",
    fontSize: "13px",
    maxWidth: "375px"
  },
}));

export default LightTooltip;

import {Code} from "common/interfaces/code";
import {useState} from "react";
import HTSCode from "@/shared-components/compliance-tools/HTSCode";
import { isWeightBasedDuty } from "common/utilities/quantityDataRequiredForDuty";
import QuantityInfo, {QuantityTotals} from "@/shared-components/compliance-tools/QuantityTotals";
import WeightInfo from "@/shared-components/compliance-tools/WeightInfo";
import {getUnitOfMeasureForCode, quantityDataRequiredForDuty} from "common/utilities/quantityDataRequiredForDuty";


interface Props {
    htsCode?: Code,
    setHTSCode: (htsCode?: Code) => void,
    totalWeight?: string,
    onTotalWeightSelected?: (totalWeight?: string) => void
    quantityInfo?: QuantityTotals,
    onQuantityInfoSelected?: (quantityInfo?: QuantityTotals) => void
    onNext: () => void,
    onBack?: () => void,
}

export default function FirstStepContainer({
                                            htsCode,
                                               setHTSCode,
                                               totalWeight,
                                               onTotalWeightSelected,
                                               quantityInfo,
                                               onQuantityInfoSelected,
                                               onNext,
                                               onBack
                                           }: Props) {

    const [step, setStep] = useState(0)

    const handleChildNext = () => {
        if (step === (getSteps(htsCode).length - 1)) {
            onNext()
        }
        setStep(step + 1)
    }

    const handleChildBack = () => {
        setStep(step - 1)
    }

    const onHTSCodeSelected = (code: Code) => {
        setHTSCode(code)
    }

    const getSteps = (code?: Code): React.JSX.Element[] => {

        const steps = [
            <HTSCode htsCode={htsCode} setHTSCode={onHTSCodeSelected} onNext={handleChildNext}/>
        ]

        if (code && isWeightBasedDuty(code)) {
            steps.push(<WeightInfo totalWeight={totalWeight || ""}
                                   onTotalWeightSelected={(totalWeight?: string) => {
                                       onTotalWeightSelected?.(totalWeight)
                                   }}
                                   onNext={handleChildNext}
                                   onBack={handleChildBack}/>)
        }

        if (code && quantityDataRequiredForDuty(code)) {
            steps.push(<QuantityInfo
                quantityInfo={quantityInfo!}
                onQuantityInfoSelected={(quantityInfo?: QuantityTotals) => {
                    onQuantityInfoSelected?.(quantityInfo)
                }}
                onNext={handleChildNext}
                onBack={handleChildBack}/>)
        }
        return steps
    }

    return getSteps(htsCode)[step]

}
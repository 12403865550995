import React, {useContext} from "react";
import {Button, Card, CardContent, Typography, useTheme} from "@mui/material";
import "./DutyCalculatorSummary.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {AppContext} from "@/App";
import {CurrencyUtility} from 'common/utilities/currencyUtility';
import {ComplianceSummaryContext, CurrencyCode, DutyCalculationOutput} from "common/interfaces/complianceSummary";
import {Code} from "common/interfaces/code";
import {getCountryNameFromISOCode} from "common/utilities/countryIsoCodes";
import {UnitOfMeasureForQuantityAllowedValues} from "common/interfaces/productfields";
import {useActivePlan} from "@/custom-hooks/plan/PlanProvider";

export interface Props {
    htsCode?: Code,
    complianceSummary: ComplianceSummaryContext,
    handleReset: () => void,
    handleEvent: () => void
}

export default function DutyCalculatorSummary({htsCode, complianceSummary, handleReset, handleEvent}: Props) {

    const activePlan = useActivePlan();
    const theme = useTheme();
    const {handleOpenPlansModal} = useContext(AppContext);

    const renderAmounts = (calculation: DutyCalculationOutput) => {

        let rowsToPush: any = [];
        calculation.appliedDuties.forEach((duty, index) => {
            rowsToPush.push(
                <div key={index} className={`animate delay-${index + 1}`}>
                    {CurrencyUtility.formatCurrency(parseFloat(duty.amount.toString()), CurrencyCode.USD)}
                </div>
            )
        })
        //@ts-ignore
        if (calculation.countervailingDuties) {
            rowsToPush.push(
                <div className="animate delay-4">
                    <div className="box blur">
                        $XXX.XX
                    </div>
                </div>
            )
        }
        //@ts-ignore
        if (calculation.antiDumpingDuties) {
            rowsToPush.push(
                <div className="animate delay-5">
                    <div className="box blur">
                        $XXX.XX
                    </div>
                </div>
            )
        }
        return rowsToPush;
    };

    const renderRatesContainer = (calculation: DutyCalculationOutput) => {
        let rowsToPush: any = [];
        calculation.appliedDuties.forEach((duty, index) => {
            rowsToPush.push(
                <div key={index} className={`animate delay-${index + 1}`}>
                    {duty.rateString}
                </div>
            )
        })
        //@ts-ignore
        if (calculation.countervailingDuties) {
            rowsToPush.push(
                <div className="animate delay-4">
                    <div className="box blur">
                        X%
                    </div>
                </div>
            )
        }
        //@ts-ignore
        if (calculation.antiDumpingDuties) {
            rowsToPush.push(
                <div className="animate delay-5">
                    <div className="box blur">
                        X%
                    </div>
                </div>
            )
        }
        return rowsToPush;
    };


    const renderFeeTypes = (calculation: DutyCalculationOutput) => {
        let rowsToPush: any = [];
        calculation.appliedDuties.forEach((duty, index) => {
            rowsToPush.push(
                <div key={index} className={`animate delay-${index + 1}`}>
                    {duty.description}
                </div>
            )
        })
        //@ts-ignore
        if (calculation.countervailingDuties) {
            rowsToPush.push(<div className="animate delay-4">Countervailing Duty</div>);
        }
        //@ts-ignore
        if (calculation.antiDumpingDuties) {
            rowsToPush.push(<div className="animate delay-5">Anti-dumping Duty</div>);
        }
        return rowsToPush;
    };

    return (
        <Card><CardContent>

            <div className="header-question">
                <Typography>
                    Estimated Duties and Fees
                </Typography>
            </div>
            <div className="summary-primary-header">
                <div>
                    <div style={{display: 'flex', gap: '40px'}}
                         className="my-shipments-subheader compliance-summary-subheader">
                        <div className="header-question">
                            <div style={{fontWeight: '600'}} className="value-fees">Your Cargo Value</div>
                            <div
                                className="value-fees-dc">{complianceSummary.input.totals?.totalValue ? CurrencyUtility.formatCurrency(parseFloat(complianceSummary.input.totals?.totalValue.toString()), CurrencyCode.USD) : ""}</div>
                        </div>
                        <div className="header-question">
                            <div style={{fontWeight: '600'}} className="value-fees">Country of Origin</div>
                            <div
                                className="value-fees-dc">{getCountryNameFromISOCode(complianceSummary.input.countryOfOrigin)} </div>
                        </div>
                        <div className="header-question">
                            <div style={{fontWeight: '600'}} className="value-fees">HTS Code</div>
                            <div className="value-fees-dc">{complianceSummary.input.htsno} </div>
                        </div>
                        <div className="header-question">
                            <div style={{fontWeight: '600'}} className="value-fees">Description</div>
                            <div className="value-fees-dc">{htsCode?.htsSentence} </div>
                        </div>
                        {complianceSummary.input.totals?.totalWeight &&
                            <div className="header-question">
                                <div style={{fontWeight: '600'}} className="value-fees">Weight</div>
                                <div
                                    className="value-fees-dc">{complianceSummary.input.totals?.totalWeight.toString()} kgs
                                </div>
                            </div>
                        }
                        {(complianceSummary.input.totals?.totalUnits && complianceSummary.input.totals?.totalUnitsMeasureOfQuantity !== UnitOfMeasureForQuantityAllowedValues.Individual) &&
                            <div className="header-question">
                                <div style={{fontWeight: '600'}} className="value-fees">Quantity</div>
                                <div
                                    className="value-fees-dc">{complianceSummary.input.totals?.totalUnits.toString()} {complianceSummary.input.totals.totalUnitsMeasureOfQuantity}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="calculator-container-wrapper">
                <div className="calculator-container">
                    <div className="top-and-left-container">
                        <div className="top-text-dc">
                            <div className="fee-name-container">
                                <div className="dc-column-header">Fee Type</div>
                                {renderFeeTypes(complianceSummary.dutyCalculationOutput!)}
                            </div>
                            <div className="calculation-text-container">
                                <div className="dc-column-header">Rate</div>
                                {renderRatesContainer(complianceSummary.dutyCalculationOutput!)}
                            </div>
                            <div className="duties-fees-container">
                                <div className="dc-column-header">Amount</div>
                                {renderAmounts(complianceSummary.dutyCalculationOutput!)}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-text">
                        <div className="total-owed">
                            Total:{" "}
                            <span className="animate delay-5 total-number">
                {CurrencyUtility.formatCurrency(parseFloat(complianceSummary.dutyCalculationOutput!.totalDuties.toString()), CurrencyCode.USD)}
              </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="calculate-another">
                <Button
                    variant="text"
                    onClick={handleReset}
                    sx={{
                        mt: 2,
                        width: "220px",
                        color: theme.palette.primary.main,
                        maxWidth: "400px",
                    }}
                >
                    Calculate another estimate?
                </Button>
            </div>
            {activePlan.isFreePlan &&
                <div className="next-button-container">
                    <Button
                        type="submit"
                        fullWidth
                        onClick={handleOpenPlansModal}
                        variant="contained"
                        sx={{textTransform: 'none'}}
                        className="next-button-dc"
                        endIcon={<KeyboardArrowRightIcon/>}
                    >
                        Check out our plans
                    </Button>
                </div>
            }
        </CardContent></Card>
    );
}

import { Alert, CircularProgress, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

const SnackbarLoader = ({ isLoading }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  useEffect(() => {
    isLoading ? setIsSuccess(false) : setIsSuccess(true);
  }, [isLoading]);
  return (
    <>
      <Snackbar
        open={isLoading}
        autoHideDuration={100000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          icon={<CircularProgress color="inherit" size={20} />}
          variant="filled"
          sx={{ width: "100%", backgroundColor: '#c0dcc2!important', color: '#525256!important' }}
        >
          Compliance greatness loading...
        </Alert>
      </Snackbar>
      {isSuccess && (
        <Snackbar
          open={isSuccess}
          autoHideDuration={3000}
          onClose={() => setIsSuccess(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
            Locked and loaded!
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarLoader;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CircularProgress, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './ResetEmailConfirmation.css';
import * as ls from "local-storage";

export default function ResetEmailConfirmation() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUpAccount = () => {
    navigate("/signup");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userToSubmit = {
      email: data.get("email"),
      password: data.get("password"),
    };
    loginUser(userToSubmit);
  };

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  const loginUser = (user) => {
    handleLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, user)
      .then(function (response) {
        const user = determineUsage(response.data);
        ls.set("user", user);
        ls.set("token", response.data.token);
        handleLoading(false);
        navigate("/dashboard");
      })
      .catch(function (error) {
        handleLoading(false);
        console.error(error);
        setErrorText(error.response.data.error.message);
      });
  };

  const determineUsage = (user) => {
    // Check if user and user.role properties exist, and set defaults if they don't
    if (!user || !user.role) {
      return user;
    }
  
    // Initialize reachedLimit to false
    if (!user.reachedLimit) {
      user.reachedLimit = false;
    }
  
    // Check if dailySearchCount exists and the user's role is "guest"
    if (user.role === "guest" && user.dailySearchCount != null && user.dailySearchCount > 2) {
      user.reachedLimit = true;
    }
  
    return user;
  };

  return (
    <div>
      <Container
        sx={{ display: "flex", width: "100%", minWidth: '650px', justifyContent: "space-between" }}
      >
        <Container>
          <img src="importal-2.png" height="100" width="160" alt="logo" />
        </Container>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography>
            <Link
              sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
              href="https://importal.com"
              variant="body2"
            >
              Return to importal.com
            </Link>
          </Typography>
        </Container>
      </Container>
      <div className="forgot-password-card-container">
        <Card sx={{padding: '32px', minWidth: '550px'}}>
          <CssBaseline />
          <Box>
            <div className="welcome-header-text">
              <Typography >
                Awesome! We just sent you an email. 
              </Typography>
            </div>
            <div className="my-shipments-subheader">Keep a look out, it will contain a link to update your password.</div>
          </Box>
        </Card>
      </div>
    </div>
  );
}
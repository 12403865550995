import { Box, CircularProgress, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "@/api/APIContext";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { UploadFile } from "@mui/icons-material";
import UploadProductsPreview from "./UploadProductsPreview";
import { DataImport } from "common/interfaces/dataimport";
import { ProductFieldsConfig } from "common/interfaces/productfields";
import Dropzone from "react-dropzone";
import ImportalPrimaryButton from "@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton";
import SyncIcon from '@mui/icons-material/Sync';
import FloatingTooltip from "@/shared-components/FloatingTooltip/FloatingTooltip";
import LightTooltip from "@/components/tooltip-component/TooltipComponent";

interface Props {
    open: boolean,
    onClose: () => void,
    onSubmit: (dataImport: DataImport<any>) => void,
    dataImport: DataImport<any> | undefined,
    productFieldsConfig: ProductFieldsConfig | undefined,
    files: File[]
}

export default function UploadProductsDataModal({ open, onClose, onSubmit, dataImport, productFieldsConfig, files }: Props) {
    const theme = useTheme()
    const api = useAPI()
    console.log(files);
    const [filesToUpload, setFilesToUpload] = useState<File[] | null>(null);

    const [previewLoading, setPreviewLoading] = useState(false)
    const [previewResults, setPreviewResults] = useState(null)
    const [previewError, setPreviewError] = useState<any>(null)

    const handleFileSelected = (files: File[]) => {
        if (!files) {
            return
        }
        setFilesToUpload(files)
        handleDoDataUploadPreview(files)
    };

    useEffect(() => {
        if (files && files.length > 0) {
            handleFileSelected(files)
        }
    }, [files])

    const handleClearFileSelection = useCallback(() => {
        setPreviewResults(null)
        setFilesToUpload(null);
    }, []);

    const handleDoDataUploadPreviewDropped = useCallback(async (files: File[]) => {

        setPreviewError(null)
        setPreviewLoading(true)
        try {
            const { data: uploadFiles } = await api.uploadFilesDataImport(dataImport!._id.toString(), files)
            const { data: preview } = await api.previewDataImport(dataImport!._id.toString())
            setPreviewResults(preview)
        } catch (err) {
            console.error('error previewing product import', err)
            setPreviewError(err)
        } finally {
            setPreviewLoading(false)
        }
    }, [api, dataImport]);

    const handleDoDataUploadPreview = useCallback(async (files: File[]) => {

        setPreviewError(null)
        setPreviewLoading(true)
        try {
            const { data: uploadFiles } = await api.uploadFilesDataImport(dataImport!._id.toString(), files)
            const { data: preview } = await api.previewDataImport(dataImport!._id.toString())
            setPreviewResults(preview)
        } catch (err) {
            console.error('error previewing product import', err)
            setPreviewError(err)
        } finally {
            setPreviewLoading(false)
        }
    }, [api, dataImport]);


    const onUploadProductsSubmit = () => {
        //reset
        setFilesToUpload(null)
        setPreviewResults(null)
        setPreviewError(null)

        if (dataImport) {
            onSubmit(dataImport)
            onClose()
        }
    }

    const isPreviewButtonDisabled = (): boolean => {
        return (!filesToUpload || previewLoading)
    }

    return <Dialog
        maxWidth='md'
        fullWidth={true}
        open={open}
        onClose={onClose}>
        <DialogContent>
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: 'grey',
                }}
                onClick={onClose}>
                <CloseIcon sx={{ fontSize: '24px' }} />
            </IconButton>
            <div className="my-shipments-header">Mapping Preview</div>
            <div className="my-shipments-subheader">Everything look right? Hit the retry mapping button in case things look off.</div>

            {/* <Dropzone onDrop={acceptedFiles => handleFileSelected(acceptedFiles)}>
            </Dropzone> */}

            <div style={{ textAlign: 'center' }}>
                {previewLoading ? (
                    <Box
                        style={{ height: '325px' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center" // Center vertically
                    >
                        <CircularProgress id='preview-loading-indicator' color='primary' />
                    </Box>
                ) : (
                    previewResults && (
                        <div style={{ textAlign: 'left', marginTop: '16px', padding: '12px' }}>
                            <UploadProductsPreview
                                products={previewResults}
                                retryMappingButton={
                                    <FloatingTooltip title="Retry smart mapping">
                                        <IconButton
                                            disabled={isPreviewButtonDisabled()}
                                            size='small'
                                            onClick={() => handleDoDataUploadPreview(filesToUpload!)}
                                        >
                                            <SyncIcon sx={{ fontSize: '32px', color: theme.palette.primary.main }} />
                                        </IconButton>
                                    </FloatingTooltip>
                                }
                                productFields={productFieldsConfig?.fields.filter(field => !field.isOutputColumn && field.showInUI)}
                            />
                        </div>
                    )
                )}
            </div>

        </DialogContent>

        <DialogActions sx={{ marginTop: '16px', justifyContent: 'flex-end' }}>
            <ImportalPrimaryButton
                disabled={!previewResults}
                onClick={onUploadProductsSubmit}
                text="Looks good"
                style={{ width: '140px' }}
            />
        </DialogActions>

    </Dialog>
}
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import BrokerCustomsOnboarding from "../customs-onboarding/BrokerCustomsOnboarding";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "16px",
};

const BrokerCustomsOnboardingModal = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BrokerCustomsOnboarding handleClose={handleClose}></BrokerCustomsOnboarding>
        </Box>
      </Modal>
    </div>
  );
};
export default BrokerCustomsOnboardingModal;

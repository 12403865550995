import React, {useState} from "react";
import {Box, Button, Typography, useTheme} from "@mui/material";
import FreightForwarderContact from "../contact/FreightForwarderContact";
import './ISFDetails.css';
import {ISFDetail} from "common/interfaces/shipment";
import {ISFType} from "common/interfaces/isf";


interface Props {
    isfDetails?: ISFDetail,
    onISFDetailsSelected: (isfDetails?: ISFDetail) => void,
    onNext: () => void,
    onBack: () => void
}

export default function ISFDetails({
                                       isfDetails,
                                       onISFDetailsSelected,
                                       onNext,
                                       onBack
                                   }: Props) {
    const theme = useTheme();

    const [freightOpen, setFreightOpen] = useState(false);

    const handleBroker = () => {
        setFreightOpen(false);
        onISFDetailsSelected({party: ISFType.CUSTOM_BROKER})
        onNext()
    };

    const handleFreight = () => {
        setFreightOpen(true);
        onISFDetailsSelected({party: ISFType.FREIGHT_FORWARDER})
    };

    return (
        <>
            {freightOpen ? (
                <FreightForwarderContact
                    freightForwarderEmail={isfDetails?.email}
                    onFreightForwarderEmailSet={(email?: string) => {
                        onISFDetailsSelected({...isfDetails, party: ISFType.FREIGHT_FORWARDER, email })
                    }}
                    onNext={onNext}
                />
            ) : (
                <Box>
                    <div className="header-question">
                        <Typography>
                            Importer Security Filing (ISF) Details
                        </Typography>
                    </div>
                    <div className="unlading-descriptive-text">
                        Who’s filing ISF for this shipment?
                    </div>
                    <div className="next-button-container">
                        <Button
                            sx={{minWidth: '200px'}}
                            className="back-button-text"
                            onClick={handleFreight}
                            variant="text"
                        >
                            Freight Forwarder
                        </Button>
                        <Button
                            fullWidth
                            onClick={handleBroker}
                            className="next-button-green"
                            variant="contained"
                        >
                            Importal
                        </Button>
                    </div>
                </Box>
            )}
        </>
    );
}

import * as React from 'react';
import {Button, Card, IconButton} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import './CallToActionSnackbar.css';
import {AppContext} from "@/App";

export default function CallToActionSnackbar({handleClose}) {

    const generateRandomPositiveWholeNumber = (max) => {
        if (max <= 0) {
            throw new Error("Max value must be greater than 0");
        }
        return Math.floor(Math.random() * max); // Add 1 to avoid generating 0
    }

    const [index, setIndex] = React.useState(generateRandomPositiveWholeNumber(4));

    const {openPlansModal, setOpenPlansModal} = React.useContext(AppContext);


    function closeHandler() {
        setOpenPlansModal(false);
    }

    const handlePricingClick = (e) => {
        e.preventDefault();
        setOpenPlansModal(true);
    };
    const [messages, setMessages]: any = React.useState(
        [
            {
                headerMessage: "Sourcing your products from China?",
                message: "You could be saving an average of 25% on duties by sourcing them from another country."
            },
            {
                headerMessage: "Did you know?",
                message: "Your product may be eligible for an exclusion from duty based on HTS Code and country. Compliance One will check for that as a part of the summary."
            },
            {
                headerMessage: "Did you know?",
                message: "It takes the average importer 90 minutes to calculate duties on their own. Importal users do it in 7 minutes with Compliance One."
            },
            {
                headerMessage: "Did you know?",
                message: "You could be saving duties and fees by claiming USMCA (The United States-Mexico-Canada Agreement)?"
            },
            {
                headerMessage: "Did you know?",
                message: "The World Trade Organization (WTO) estimates that the average anti-dumping duty rate is between 5% and 15% in addition to other product duties? Compliance One will check for that as a part of the summary."
            }
        ]
    );

    return (
        <>
            <Card className="call-to-action-snackbar-container">
                <IconButton
                    onClick={handleClose}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon sx={{fontSize: '20px'}}/>
                </IconButton>
                <h3>{messages[index].headerMessage}</h3>
                <div>{messages[index].message}</div>
                <div className="upgrade-container">
                    <Button onClick={handlePricingClick} sx={{
                        color: 'white',
                        fontSize: '16px',
                        textTransform: 'none',
                        borderColor: 'white',
                        backgroundColor: '#77A8F1'
                    }} variant="text" className="upgrade-link" href="#">Upgrade for unlimited access</Button>
                </div>
            </Card>
        </>
    );
}
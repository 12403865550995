import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {IconButton,} from "@mui/material";
import "./CancelSubscription.css";
import {useFormik} from "formik";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import {useAPI} from "@/api/APIContext";
import {useActivePlan} from "@/custom-hooks/plan/PlanProvider";
import {useNavigate} from "react-router-dom";

const validationSchema = yup.object({
    reason: yup.string().trim().required("Select any one reason!"),
});

const CancelSubscription = ({openDialogue, open, handleLoading}) => {

    const api = useAPI();
    const activePlan = useActivePlan();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            reason: "individual canceled",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleLoading(true);
            api.cancelPurchase(values)
                .then(function (response) {
                    activePlan.refreshPlan()
                    handleLoading(false);
                })
                .catch(function (error) {
                    handleLoading(false);
                });
            openDialogue();
        },
    });

    return (
        <Dialog className="subscription-wrapper" open={open} onClose={openDialogue}>
            <DialogTitle>Cancel my subscription</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={openDialogue}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <div className="unlading-descriptive-text">We're sad to see you go. If you have any feedback on ways
                        we can improve our app or business, please reach out
                        to us at founders@goimportal.com.
                    </div>
                </DialogContent>
                <div className="next-button-container">
                    <Button
                        type="submit"
                        sx={{width: '200px!important'}}
                        className="next-button-green"
                        variant="contained"
                    >
                        Cancel subscription
                    </Button>
                </div>
            </form>
        </Dialog>
    );
};

export default CancelSubscription;

import React, { Consumer } from "react";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {Tooltip} from "@mui/material";
import "./ConsumerClassificationsTable.css"
import ImportalTable from "@/shared-components/ImportalTable/ImportalTable";
import {ColumnConfig} from 'common/interfaces/importalTable';
import {
    Classification,
    ClassificationStatusToLongText,
    ClassificationStatusToShortText
} from "common/interfaces/classification";
import {useLocation, useNavigate} from "react-router-dom";
import { renderClassificationStatus } from "@/utils/classificationUtils";
import ImportalPrimaryButton from "@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton";
import { Add } from "@mui/icons-material";


interface RowProps {
    row: Classification,
}

const Row = (props: RowProps) => {
    const {row} = props;

    const navigate = useNavigate();
    const location = useLocation();

    const onClassificationLinkClicked = (classification: Classification) => {
        navigate(`/dashboard/hts-classifications/${classification._id}`);
    }

    return (
        <React.Fragment>
            <TableRow
                sx={{"& > *": {borderBottom: "unset"}}}
                className="cellBorder"
            >
                <TableCell
                    component="th"
                    scope="row"
                >
                    {/*<Link sx={{textDecoration: 'none', cursor: 'pointer'}} component="a" onClick={(e) => {*/}
                    {/*    e.preventDefault();*/}
                    {/*    onClassificationLinkClicked(row);*/}
                    {/*}}>*/}
                    <Typography> {row.referenceNumber} </Typography>
                    {/*</Link>*/}

                </TableCell>
                <TableCell>
                    <Tooltip title={`${row.description}`}>
                        <Typography>
                            {row.description ? row.description : "-"}
                        </Typography>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Typography>{row.materialComposition ? row.materialComposition : "-"}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{row.intendedUse ? row.intendedUse : "-"}</Typography>
                </TableCell>
                <TableCell>
                    {row.finalHTSCode || '--'}
                </TableCell>
                <TableCell>
                    <Tooltip title={`${ClassificationStatusToLongText[row.status]}`}>
                        {renderClassificationStatus(row.status)}
                    </Tooltip>
                </TableCell>
            </TableRow>


        </React.Fragment>
    );
}

interface ConsumerClassificationsTableProps {
    rows: Classification[],
    isLoading: boolean,
    requestClassificationButton?: React.JSX.Element;
}

const ConsumerClassificationsTable = ({rows, isLoading, requestClassificationButton}: ConsumerClassificationsTableProps) => {

    const classificationsTableColumns: Array<ColumnConfig> = [
        {
            displayName: 'Reference #',
            name: 'referenceNumber',
            dataType: 'string',
            sortable: true,
            filterable: false
        },
        {
            displayName: 'Description',
            name: 'description',
            dataType: 'string',
            sortable: true,
            filterable: false
        },
        {
            displayName: 'Material Composition',
            name: 'materialComposition',
            dataType: 'string',
            sortable: true,
            filterable: false
        },
        {
            displayName: 'Intended Use',
            name: 'intendedUse',
            dataType: 'string',
            sortable: true,
            filterable: false
        },
        {
            displayName: 'HTS Code',
            name: 'finalHTSCode',
            dataType: 'string',
            sortable: false,
            filterable: false
        },
        {
            displayName: 'Status',
            name: 'status',
            dataType: 'string',
            sortable: false,
            filterable: false
        },

    ]

    return (

        <ImportalTable
            data={rows}
            rowGenerator={(row: Classification, index) => <Row key={`row-${row.referenceNumber}-${index}`}
                                                               row={row}/>}
            columnConfig={classificationsTableColumns}
            loading={isLoading}
            hasRowExpand={false}
            topRightButton={requestClassificationButton}
        />

    );
}
export default ConsumerClassificationsTable
